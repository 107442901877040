import * as React from 'react';
import Loader from '../components/common/Loader';
import {
    Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import DownloadPage from '../components/download/downloads';
import WalletPage from '../components/wallet/wallet';
import Pickups from '../components/pickup/pickup-view/pickup';
import ConsignmentPage from '../components/consignments/consignments';
import VirtualInventory from '../components/virtual-inventory/virtual-inventory';
import AddressBook from '../components/address-book/address-book';
import SenderManagement from '../components/sender-management/sender-management';
import AnalyticsPage from '../components/analytics/analytics';
import APIPlaygroundPage from '../components/api-playground/api-playground';
import USER_MANAGEMENT from '../components/user-management/user-management';
import {
    getConsignmentDetailsRoute,
    getConsignmentsRoute,
    getDefaultLandingRoute,
    getPluginOrdersRoute,
    getDownloadsRoute,
    getExcelUploadRoute,
    getHeaderMappingRoute,
    getPieceHeaderMappingRoute,
    getPickupRoute,
    getPrintHistoryRoute,
    getProductMappingRoute,
    getSettingsChannelRoute,
    getSettingsAccountRoute,
    getVirtualInventoryRoute,
    getWalletLedgerRoute,
    getWeightMismatchRoute,
    getAsnExcelUploadRoute,
    getAdvanceShippingNoteRoute,
    getAddressBookRoute,
    getCustomerActivationRoute,
    getAnalyticsRoute,
    getRtoDashboardRoute,
    getRemittanceReportsRoute,
    getForgotPasswordRoute,
    getInvoiceReportsRoute,
    getAdditionalServicesRoute,
    getAPIPlaygroundRoute,
    getBlockedLedgerRoute,
    getRateCalculatorRoute,
    getPincodeServiceabilityRoute,
    getUserManagement,
    getSenderManagementRoute,
} from './routing-helper';
import { loadInitialData } from '../actions/load-initial-data';
import { bindActionCreators } from 'redux';
import { ReduxStore } from '../reducers/redux.types';
import { HocOptions } from '../components/common/generic-hoc.types';
import { Buckets } from '../library/Types';
import { Master } from '../types/master-data-types';
import GenericHoc from '../components/common/generic-hoc';
import RTODashboard from 'components/rto-dashboard/rto-dashboard';
// import RemittanceReport from 'components/remittance-report/remittance-report';
import { setFilters, applyFilters } from '../actions/generic-action';
import CreatePassword from 'components/sign-in/create-password';
import ResetPassword from 'components/sign-in/reset-password';
import { trackPageView } from 'library/google-analytics-helper';
import PluginOrders from 'components/consignments/plugin-orders';
import ExcelUpload from 'components/consignments/excel-upload';
import PrintHistory from 'components/consignments/print-history';
import { RouteDetails, Routes, RoutesInfo } from 'library/constants';
import InvoiceReport from 'components/remittance-report/invoice-report';
import RemittanceReport from 'components/remittance-report/remittance-report';
import HeaderMapping from 'components/settings-product/header-mapping';
import ProductMapping from 'components/settings-product/product-mapping';
import ChannelIntegration from 'components/settings-product/channel-integration';
import AccountSettings from 'components/settings-product/account-settings';
import RateCalculator from 'components/pincode-service/rate-calculator';
import PincodeService from 'components/pincode-service/service-availability';
import { storeRoutesInfoState } from 'actions/miscellaneous-actions';
import SellerDetails from 'components/seller-details/seller-details';

// Adding lazy loading for other organization specific pages as DTDC don't need them
const AdvanceShippingNotePage = React.lazy(() => import('../components/advanceShippingNote/advanceShippingNote'));
const AdditionalServices = React.lazy(() => import('components/additional-services/additional-services'));

// import DefaultUrl from './default-page';
// interface RouteInfo {
//     isAllowed: boolean;
//     path: string;
// }

interface IProps {
    loadinitial: () => void;
    master: Master;
    fetchingMaster: boolean;
    asnFilters?: Record<string, any>;
    setfilters: (bucketId: Buckets.ASN, filters: any) => void;
    applyfilters: (bucketId: Buckets.ASN) => void;
    setStoreRoutesInfoState: (data: any) => void;
}

const AutheticatedRoutes = (props: IProps) => {
    const {
        master,
        loadinitial,
        fetchingMaster,
        asnFilters,
        setfilters,
        applyfilters,
        setStoreRoutesInfoState,
    } = props;

    const location = useLocation();
    const is_international_customer = master?.config?.is_international_customer || false;

    const handleBucketChangeAsn = () => {
        const isParentCustomer = JSON.parse(window.localStorage.getItem('parentId') as string);
        const newFilters = {
            ...asnFilters,
            bucketSelected: isParentCustomer ? 'customer_portal_asn_assigned' : 'customer_portal_asn_uploaded',
        };
        setfilters(Buckets.ASN, newFilters);
        applyfilters(Buckets.ASN);
    };

    React.useEffect(() => {
        if (!master.masterDataLoaded) {
            loadinitial();
        }
        handleBucketChangeAsn();
    }, []);

    React.useEffect(() => {
        trackPageView(location.pathname);
    }, [location.pathname]);

    const routesPermission = React.useMemo(() => {
        if (!master.masterDataLoaded) {
            return {};
        }
        const routesDetails: Record<string, RouteDetails> = {};
        // using memo to avoid re-rendering of routes condition check computation
        Object.keys(RoutesInfo).forEach((routeKey) => {
            const key = routeKey as keyof typeof Routes;
            if (!RoutesInfo[key]) {
                return;
            }
            routesDetails[Routes[key]] = {
                title: RoutesInfo[key].title,
                isProtected: RoutesInfo[key].isProtected,
                isVisible: typeof RoutesInfo[key].isVisible === 'function'
                    ? RoutesInfo[key].isVisible(master)
                    : RoutesInfo[key].isVisible,
            };
        });
        return routesDetails;
    }, [master.masterDataLoaded]);

    React.useEffect(() => {
        setStoreRoutesInfoState(routesPermission);
    }, [routesPermission]);

    if (fetchingMaster || !master.masterDataLoaded) {
        return <Loader zIndex={100} />;
    }

    return (
        <React.Suspense fallback={<Loader zIndex={5} />}>
            <Switch>
                <Route
                    exact
                    path={getConsignmentsRoute()}
                    render={() => <ConsignmentPage />}
                />
                <Route
                    path={getConsignmentDetailsRoute()}
                    render={() => <ConsignmentPage />}
                />
                <Route
                    exact
                    path={getPluginOrdersRoute()}
                    render={() => <PluginOrders />}
                />
                <Route
                    exact
                    path={getExcelUploadRoute()}
                    render={() => <ExcelUpload />}
                />
                <Route
                    exact
                    path={getPrintHistoryRoute()}
                    render={() => <PrintHistory />}
                />
                <Route
                    exact
                    path={getAdvanceShippingNoteRoute()}
                    render={() => <AdvanceShippingNotePage />}
                />
                <Route
                    exact
                    path={getAsnExcelUploadRoute()}
                    render={() => <AdvanceShippingNotePage />}
                />
                <Route
                    path={getVirtualInventoryRoute()}
                    render={() => <VirtualInventory />}
                />
                <Route exact path={getPickupRoute()} render={() => <Pickups />} />
                <Route
                    exact
                    path={getAddressBookRoute()}
                    render={() => <AddressBook />}
                />
                <Route
                    exact
                    path={getRtoDashboardRoute()}
                    render={() => <RTODashboard />}
                />
                <Route
                    exact
                    path={getDownloadsRoute()}
                    render={() => <DownloadPage />}
                />
                <Route
                    exact
                    path={getWalletLedgerRoute()}
                    render={() => <WalletPage key={Routes.WALLET_LEDGER} />}
                />
                <Route
                    exact
                    path={getWeightMismatchRoute()}
                    render={() => <WalletPage key={Routes.WALLET_WEIGHT_MISMATCH} />}
                />
                <Route
                    exact
                    path={getBlockedLedgerRoute()}
                    render={() => <WalletPage key={Routes.BLOCKED_LEDGER} />}
                />
                <Route
                    exact
                    path={getRemittanceReportsRoute()}
                    render={() => <RemittanceReport />}
                />
                <Route
                    exact
                    path={getInvoiceReportsRoute()}
                    render={() => <InvoiceReport />}
                />
                <Route
                    exact
                    path={getHeaderMappingRoute()}
                    render={() => <HeaderMapping bannerHeight={0} key={getHeaderMappingRoute()} />}
                />
                <Route
                    exact
                    path={getPieceHeaderMappingRoute()}
                    // render={() => <HeaderMappingPage bannerHeight={bannerHeight} pieceHeaders />}
                    render={() => <HeaderMapping bannerHeight={0} pieceHeaders key={getPieceHeaderMappingRoute()} />}
                />
                <Route
                    exact
                    path={getProductMappingRoute()}
                    render={() => <ProductMapping />}
                />
                <Route
                    exact
                    path={getSettingsChannelRoute()}
                    render={() => <ChannelIntegration />}
                />
                <Route
                    exact
                    path={getSettingsAccountRoute()}
                    render={() => <AccountSettings />}
                />
                <Route
                    exact
                    path={getCustomerActivationRoute()}
                    render={() => <CreatePassword />}
                />
                <Route
                    exact
                    path={getForgotPasswordRoute()}
                    render={() => <ResetPassword />}
                />
                <Route path={getAnalyticsRoute()} render={() => <AnalyticsPage />} />
                <Route
                    exact
                    path={getAdditionalServicesRoute()}
                    render={() => <AdditionalServices />}
                />
                <Route
                    path={getAPIPlaygroundRoute()}
                    render={() => <APIPlaygroundPage />}
                />
                <Route
                    path={getRateCalculatorRoute()}
                    render={() => <RateCalculator />}
                />
                <Route
                    path={getPincodeServiceabilityRoute()}
                    render={() => <PincodeService />}
                />
                <Route
                    path={getUserManagement()}
                    render={() => <USER_MANAGEMENT />}
                />
                <Route
                    exact
                    path={getSenderManagementRoute()}
                    render={() => (
                        is_international_customer
                            ? <SenderManagement />
                            : <Redirect to={getConsignmentsRoute()} />
                    )}
                />
                <Route
                    path={getSenderManagementRoute()}
                    render={() => (
                        is_international_customer
                            ? <SellerDetails />
                            : <Redirect to={getConsignmentsRoute()} />
                    )}
                />
                <Route
                    path="/"
                    render={() => <Redirect to={getDefaultLandingRoute()} />}
                />
            </Switch>
        </React.Suspense>
    );
};

const mapStateToProps = (store: ReduxStore) => ({
    master: store.master,
    fetchingMaster: store.master.fetchingMaster,
    asnFilters: store.generic.ASN.filters,
});

const mapDispatchToProps = (dispatch: any) => (bindActionCreators({
    loadinitial: loadInitialData,
    setfilters: setFilters,
    applyfilters: applyFilters,
    setStoreRoutesInfoState: storeRoutesInfoState,
}, dispatch));

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};
const Authenticated = GenericHoc(hocConfig)(AutheticatedRoutes);
export default Authenticated;
