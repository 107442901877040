/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const AirPlanSvg = (props: any) => (
    <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.729221 0.207514C0.54979 0.370662 0.468104 0.593483 0.511391 0.822018L0.593771 1.28099C0.675457 1.73362 0.921218 2.1564 1.28567 2.47191L3.12396 4.0653L1.48046 7.47427C1.3499 7.74724 1.41413 8.06211 1.64593 8.27414L2.29244 8.86198C2.32176 8.88801 2.36156 8.90134 2.40205 8.89817C2.44324 8.89499 2.47955 8.87595 2.50329 8.84548L4.99858 5.63838L7.47849 7.71677L7.14058 9.07084C7.10567 9.20478 7.15244 9.35015 7.25996 9.44792L7.82619 9.96339C7.85691 9.99069 7.89949 10.004 7.94139 9.99894C7.98397 9.99386 8.02168 9.97164 8.04332 9.93863L9.30632 8.00561L11.4323 6.85723C11.4686 6.83755 11.493 6.80327 11.4986 6.76454C11.4986 6.75947 11.5 6.75439 11.5 6.74931C11.5 6.7163 11.4853 6.68392 11.4595 6.66043L10.8926 6.14496C10.7837 6.04656 10.6294 6.00594 10.4793 6.03641L8.98935 6.34366L6.70352 4.08942L10.2307 1.82059C10.2642 1.799 10.2852 1.76536 10.2887 1.72854C10.2949 1.69553 10.2775 1.65553 10.2489 1.62887L9.60165 1.04103C9.36846 0.829636 9.02356 0.769963 8.72265 0.889943L4.97343 2.38494L3.22101 0.712829C2.87262 0.38082 2.40694 0.157364 1.90984 0.0843602L1.40505 0.00945169C1.1551 -0.0273677 0.908653 0.0443663 0.729221 0.207514Z"
            fill="currentColor"
        />
    </svg>
);

const AirPlaneIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return AirPlanSvg({ ...innerProps, ...props });
        }}
    />
);

export default AirPlaneIcon;
