import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { StylesProps } from '../../theme/jss-types';
import {
    ProfileOutlined,
    LogoutOutlined,
    LoginOutlined,
    UserOutlined,
    EnvironmentOutlined,
    GlobalOutlined,
} from '@ant-design/icons';
import {
    Button, Divider, Dropdown, Input, Menu, message, Select,
} from 'antd';
import PincodeService from '../pincode-service/service-availability';
import TrackConsignment from '../track-consignment/track-consignment';
import authHelper from '../../auth/auth-helper';
// import PicodeAvailablityIcon from '../../assets/pincode-availability';
import { HocOptions } from './generic-hoc.types';
import GenericHoc from './generic-hoc';
import { navbarStyles } from './navbar.styles';
import { RouteComponentProps } from 'react-router';
import { getSettingsAccountRoute } from 'routing/routing-helper';
import { ReduxStore } from 'reducers/redux.types';
import { Master } from 'types/master-data-types';
import Helper from 'library/Helper';
import { useTranslation } from 'react-i18next';
import RateCalculator from 'components/pincode-service/rate-calculator';
import RateIcon from 'assets/rate-svg';
import { isSuperUserEmployee } from 'auth/local-storage';
import ChangeCustomer from './change-customer';
import QuickActionIcon from 'assets/quick-action';
import { languageCodeMapping, RouteDetails, Routes } from 'library/constants';
import EmptyIcon from 'assets/empty-space-icon';
import classNames from 'classnames';
import TargetIcon from 'assets/target-icon';
import WalletIcon from 'assets/wallet';
import { fetchCncData } from 'network/wallet.api';
import { storeCNCDataState } from 'actions/miscellaneous-actions';
import { bindActionCreators } from 'redux';

interface NavbarProps extends StylesProps<ReturnType<typeof navbarStyles>>, RouteComponentProps {
    pageTitle: string;
    master: Master;
    i18n: any;
    routesInfo: Record<string, RouteDetails>;
    cncData: Record<string, any>;
    setCNCData: (data: any) => void;
}

const Navbar = (props: NavbarProps) => {
    const {
        pageTitle,
        classes,
        history,
        master,
        i18n,
        routesInfo,
        cncData,
        setCNCData,
    } = props;
    const { t } = useTranslation();
    const [pincodeDrawer, setPincodeDrawer] = React.useState(false);
    const [showChangeCustomer, setChangeCustomerModal] = React.useState(false);
    const [trackVisible, setTrackVisible] = React.useState<string>();
    const [trackQuery, setTrackQuery] = React.useState<string>();
    const [rateVisible, setRateVisible] = React.useState<boolean>(false);
    const [trackType, setTrackType] = React.useState<string>('Consignment Number');
    const currentRoute = Helper.getCurrentRoute();
    const showWalletBalance = master?.Customer?.cnc_flag || false;

    const oldBaseURL = master?.config?.customer_portal_config?.old_cp_base_url;

    const show_rate_calculator = master?.config?.customer_portal_config?.show_rate_calculator;
    const show_old_portal_link = master?.config?.customer_portal_config?.show_old_portal_link;

    const customerProperties = master?.properties;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const isLTLCustomer = master?.Customer?.customer_category === 'ltl';
    let showCnTracking = true;
    let showPincodeServiceability = true;
    if (customerProperties && enable_customer_config) {
        showCnTracking = customerProperties?.cn_tracking;
        showPincodeServiceability = customerProperties?.pincode_serviceability;
    }

    const { config } = master;
    const { customer_portal_ops_config, available_language_list } = config;
    let languageList: any = [];
    const isLanguageChangeAllowed = customer_portal_ops_config?.language_config?.config_value?.is_language_change_allowed
        || false;
    if (customer_portal_ops_config?.language_config?.config_value) {
        const allowed_language = customer_portal_ops_config.language_config
            ?.config_value?.allowed_language_list_customer_portal || [];
        if (Array.isArray(available_language_list) && Array.isArray(allowed_language)
                && available_language_list.length > 0 && allowed_language.length > 0) {
            languageList = available_language_list
                .filter((item: any) => allowed_language.includes(item.language_name));
        }
    }

    const {
        logoUrl,
    } = authHelper.getUsernameAndLogo();

    const isSuperUser = isSuperUserEmployee();

    const loadCNCData = async () => {
        if (!showWalletBalance) {
            return;
        }
        const response = await fetchCncData();
        if (!response?.isSuccess) {
            message.error('Unable to fetch wallet balance');
        } else {
            setCNCData(response?.data || {});
        }
    };

    React.useEffect(() => {
        loadCNCData();
    }, [showWalletBalance]);


    const handleLanguageChange = (val: any) => {
        i18n.changeLanguage(val, (err: any) => {
            if (err) {
                message.error(err);
                return;
            }
            message.success('Language changed successfully');
            window.location.reload();
        });
    };

    const menu = (
        <Menu>
            <Menu.Item
                key="profile"
                onClick={() => history.push(getSettingsAccountRoute())}
                icon={<ProfileOutlined />}
            >
                {t('profile')}
            </Menu.Item>
            {show_old_portal_link && (
                <Menu.Item
                    key="old_cp"
                    onClick={() => Helper.redirectToOldPortal(oldBaseURL)}
                    icon={<LoginOutlined />}
                >
                    {t('legacy_customer_portal')}
                </Menu.Item>
            )}
            {isSuperUser && (
                <Menu.Item
                    key="change_customer"
                    onClick={() => setChangeCustomerModal(true)}
                    icon={<UserOutlined />}
                >
                    {t('change_customer')}
                </Menu.Item>
            )}
            {
                isLanguageChangeAllowed
                && (
                    <Menu.Item
                        key="language"
                        icon={<GlobalOutlined />}
                        onClick={(e) => e.domEvent.stopPropagation()}
                    >
                        <Select
                            onChange={handleLanguageChange}
                            value={`${t('Language')}: ${languageCodeMapping[i18n.language].prettyName}`}
                            defaultValue={languageCodeMapping[i18n.language].prettyName}
                            style={{ width: '150px' }}
                            className={classes.changeLanguageDropdown}
                            options={languageList?.map((item: any) => {
                                return {
                                    value: item.key,
                                    label: item.language_name,
                                };
                            }) || []}
                            onClick={(e) => e.stopPropagation()}
                            suffixIcon={<EmptyIcon />}
                        />
                    </Menu.Item>
                )
            }
            <Menu.Item
                key="logout"
                icon={<LogoutOutlined />}
                onClick={() => authHelper.logoutUser()}
            >
                {t('logout')}
            </Menu.Item>
        </Menu>
    );

    const renderProfile = () => {
        return (
            <div className={classes.circle}>
                <div className={classes.profile}>
                    <UserOutlined />
                </div>
            </div>
        );
    };

    const renderDropdown = () => {
        return (
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button
                    type="link"
                    className={classNames(classes.username, 'logout-button')}
                >
                    {/* <div className={classes.name}>{username}</div> */}
                    {renderProfile()}
                </Button>
            </Dropdown>
        );
    };

    const quickMenu = (
        <Menu>
            { showPincodeServiceability && !master?.config?.customer_portal_consignment_config?.is_international_client
            && (
                <Menu.Item
                    onClick={() => {
                        if (currentRoute !== Routes.PINCODE_SERVICEABILITY) {
                            setPincodeDrawer(true);
                        }
                    }}
                    icon={<EnvironmentOutlined />}
                    className="pincode-serviceability"
                    key="pincode_serviceability"
                >
                    {t('check_pincode_serviceability')}
                </Menu.Item>
            )}
            {show_rate_calculator && (
                <Menu.Item
                    onClick={() => {
                        if (currentRoute !== Routes.RATE_CALCULATOR) {
                            setRateVisible(!rateVisible);
                        }
                    }}
                    icon={<RateIcon />}
                    className="calculate-rates"
                    key="rate_calculator"
                >
                    {t('Rate Calculator')}
                </Menu.Item>
            )}
        </Menu>
    );

    const renderQuickAction = () => {
        if ((!showPincodeServiceability
            || master?.config?.customer_portal_consignment_config?.is_international_client)
            && !show_rate_calculator) {
            return null;
        }
        return (
            <Dropdown
                overlay={quickMenu}
                trigger={['click']}
                className={classes.quickAction}
            >
                <Button
                    icon={<QuickActionIcon />}
                    className="quick-action"
                >
                    Quick Actions
                </Button>
            </Dropdown>
        );
    };

    const renderSelect = () => {
        return (
            <Select
                className={classes.trackDropDown}
                defaultValue={trackType}
                onChange={(value: string) => setTrackType(value)}
                options={[
                    {
                        value: 'Consignment Number',
                        label: 'Consignment Number',
                    },
                    {
                        value: 'Customer Reference No',
                        label: 'Customer Reference No',
                    },
                ]}
            />
        );
    };

    const renderTrackInput = () => {
        return (
            <Input
                className={classNames(classes.trackInput, 'consignment-tracking')}
                onPressEnter={() => setTrackVisible(trackQuery)}
                type="search"
                value={trackVisible as string}
                onChange={(e: any) => setTrackQuery(e.target.value)}
                placeholder={
                    (trackType && trackType === 'Customer Reference No')
                        ? t('track_customer_reference_number')
                        : t('track_consignment')
                }
                prefix={<TargetIcon onClick={() => setTrackVisible(trackQuery)} />}
                addonBefore={renderSelect()}
            />
        );
    };

    const renderInput = () => {
        if (showCnTracking) {
            return (
                <div>
                    {renderTrackInput()}
                </div>
            );
        }
        return null;
    };

    const handleCloseForm = () => {
        setPincodeDrawer(false);
        setTrackVisible(undefined);
    };

    const renderTrack = () => {
        if (!trackVisible) {
            return null;
        }
        return (
            <TrackConsignment
                referenceNumber={trackVisible as string}
                onClose={handleCloseForm}
                trackType={trackType as string}
            />
        );
    };

    const renderPincodeDrawer = () => {
        if (!pincodeDrawer) {
            return null;
        }
        return (
            <PincodeService
                onClose={handleCloseForm}
                shouldRenderInDrawer
            />
        );
    };

    const renderRateCalulator = () => {
        if (!rateVisible) {
            return null;
        }
        return (
            <RateCalculator
                shouldRenderInDrawer
                onClose={() => setRateVisible(false)}
                isLTLCustomer={isLTLCustomer}
            />
        );
    };


    const renderChangeCustomer = () => {
        if (!showChangeCustomer) {
            return null;
        }
        return (
            <ChangeCustomer
                onClose={() => setChangeCustomerModal(false)}
            />
        );
    };

    const renderWalletBalance = () => {
        if (!showWalletBalance) {
            return null;
        }
        // only updating balance on page load and when user visit wallet page
        return (
            <Button
                className={classNames(classes.walletBalance, 'wallet-balance')}
                icon={<WalletIcon />}
            >
                {cncData?.wallet_balance || 0}
                &nbsp;INR
            </Button>
        );
    };

    return (
        <div className={classes.mainDiv}>
            <DocumentTitle title={pageTitle} />
            <div className={classes.leftBox}>
                <div className={classes.logoBox}>
                    <img
                        alt="logo-url"
                        src={logoUrl as string}
                        style={{
                            height: '100%',
                        }}
                    />
                </div>
                &nbsp;
                <Divider type="vertical" className={classes.titleBar} />
                <div className={classes.pageTitle}>
                    {routesInfo[currentRoute]?.title}
                </div>
            </div>
            <div className={classes.rightBox}>
                {renderInput()}
                {renderQuickAction()}
                {renderWalletBalance()}
                {renderDropdown()}
            </div>
            {renderPincodeDrawer()}
            {renderTrack()}
            {renderRateCalulator()}
            {renderChangeCustomer()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master, miscellaneousState } = state;
    return {
        master,
        routesInfo: miscellaneousState?.ROUTES_INFO || {},
        cncData: miscellaneousState?.CNC_INFO || {},
    };
};

const mapDispatchToProps = (dispatch: any) => (bindActionCreators({
    setCNCData: storeCNCDataState,
}, dispatch));

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: navbarStyles,
    },
    connectRouter: true,
};

export default GenericHoc(hocConfig)(Navbar);
