import Cross from '../../assets/cross';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import Loader from '../common/Loader';
import { bulkEdit, downloadSampleAddress, bulkSaveAddress } from '../../network/pickup.api';
import * as React from 'react';
import { UploadChangeParam } from 'antd/lib/upload';
import withStyles from 'react-jss';
import Helper from '../../library/Helper';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { excelUploadStyles } from './excel-upload.styles';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

interface ExcelUploadAddressProps extends StylesProps<ReturnType<typeof excelUploadStyles> >{
    onClose: () => void;
    isEdit: boolean;
    uiTheme: ThemeType;
}

const ExcelUploadAddress = (props: ExcelUploadAddressProps) => {
    const {
        classes,
        onClose,
        isEdit,
        uiTheme,
    } = props;


    const { t } = useTranslation();

    const [addressArray, setAddressArray] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const mapping: Record<string, string> = {
        'Address Code *': 'addressCode',
        'Address Purpose *': 'addType',
        'Address Id *': 'addressId',
        'Name *': 'name',
        'Pincode *': 'pincode',
        'Phone Number': 'phone',
        'Alternate Phone': 'alternatePhone',
        'Address Line 1 *': 'addressLine1',
        'Address Line 2': 'addressLine2',
        'City *': 'cityName',
        State: 'stateName',
        'Country *': 'countryName',
        'Location Id': 'locationId',
        Latitude: 'latitude',
        Longitude: 'longitude',
        'Company Name': 'companyName',
        'Address Category': 'addressCategory',
    };

    const handleUpdate = async () => {
        setLoading(true);
        const result = await bulkEdit(addressArray);

        if (result.data.success) {
            message.success('Addresses Updated Successfully');
        } else {
            message.error('Unable to update the addresses');
        }

        setLoading(false);
    };

    const handleCreate = async () => {
        setLoading(true);
        const result = await bulkSaveAddress(addressArray);

        if (result.data.success) {
            message.success('Addresses Saved Successfully');
        } else {
            message.error('Unable to save the addresses');
        }

        setLoading(false);
    };

    const renderButton = (theme: ThemeType) => {
        let btnClass = classes.newButton;
        if (addressArray.length) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                loading={false}
                className={btnClass}
                disabled={!addressArray.length}
                style={{
                    border: `1px solid ${!addressArray?.length ? '#EDEDED'
                        : theme.primaryColor}`,
                }}
                onClick={isEdit ? handleUpdate : handleCreate}
            >
                {t('submit')}
            </Button>
        );
    };

    const renderHeader = (theme: ThemeType) => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {isEdit ? t('bulk_edit_addresses') : t('bulk_upload_addresses')}
                    </span>
                </div>
                {renderButton(theme)}
            </div>
        );
    };

    const getAddressArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = mapping[keyUpdate];
                returnObj[keyUpdate] = item[key];
            });
            returnObj.isSubAccount = true; // for xl identification
            return returnObj;
        });
        return newData;
    };

    const transformData = (data: any[]) => {
        const newData: any = {};
        newData.addressArray = getAddressArray(data[0]);
        return newData;
    };

    const handleParsedData = async (data: any[]) => {
        if (!data || !data.length) return;
        const slicedData = transformData(data);
        setAddressArray(slicedData.addressArray);
    };

    const onFileRecieved = async (file: any) => {
        const addressArr: any[] = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
        handleParsedData(addressArr);
    };

    const handleUploadChange = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            onFileRecieved(info.file.originFileObj);
        }
    };

    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };

    const downloadSample = async () => {
        const fileBuffer = await downloadSampleAddress('');

        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'bulk_business_address_sample.xlsx',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }
    };

    const renderUpload = () => {
        return (
            <div
                className={classes.uploadBox}
            >
                <Dragger
                    multiple={false}
                    accept=".xlsx, .xls, .csv"
                    name="file"
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                >
                    <p className={classes.uploadText}>
                        <UploadOutlined className={classes.closeIcon} />
                        {t(`${!isEdit ? 'add' : 'update'}_address_text`)}
                    </p>
                    <p className={classes.uploadHint}>
                        {t('drag_and_drop_text')}
                        or
                        <Button type="link">Browse</Button>
                        to choose file
                    </p>
                </Dragger>
            </div>
        );
    };

    const renderDownload = () => {
        return (
            <Button
                type="link"
                className={classes.downloadBtn}
                onClick={downloadSample}
            >
                <DownloadOutlined />
                {t('download_sample_file')}
            </Button>
        );
    };

    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader(uiTheme)}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderUpload()}
            {renderDownload()}
            {loading && <Loader zIndex={10} />}
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default withStyles(excelUploadStyles)(GenericHoc(hocConfig)(ExcelUploadAddress));
