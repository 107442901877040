import { ResponseType } from 'axios';
import { logoutCustomer } from 'network/common.api';
import { applicationType, useOnlyForLocalTestingOrgId } from '../library/globals';
import { KeyValue } from '../library/Types';

const SANDBOX_FRONTEND_URLS = [
    'democustomerportalreactsandbox.shipsy.io',
    'democustomerportalreactsandbox1.shipsy.io',
    'democustomerportalreactsandbox2.shipsy.io',
    'democustomerportalreactsandbox3.shipsy.io',
    'democustomerportalreactsandbox4.shipsy.io',
    'democustomerportalreactsandbox5.shipsy.io',
    'democustomerportalreactsandbox6.shipsy.io',
    'democustomerportalreactsandbox7.shipsy.io',
    'democustomerportalreactsandbox8.shipsy.io',
    'democustomerportalreactsandbox9.shipsy.io',
    'democustomerportalreactsandbox10.shipsy.io',
    'democustomerportalreactsandbox11.shipsy.io',
    'democustomerportalreactsandbox12.shipsy.io',
];

const isAuthenticated = () => {
    const storage = window.localStorage;
    const token = storage.getItem('authToken');
    const userId = storage.getItem('userId');
    const organisationID = storage.getItem('organisationId');
    return (
        token && userId && organisationID
    );
};

const getOrganisationId = () => {
    const { hostname } = window.location;
    const storage = window.localStorage;
    let organisationId = useOnlyForLocalTestingOrgId;
    if (hostname.indexOf('.') > 0) {
        organisationId = hostname.substring(0, hostname.indexOf('.'));
    }
    return storage.getItem('organisationId') || organisationId;
};

const getOrgPrettyName = () => {
    const { hostname } = window.location;
    let organisationPrettyName = '';
    if (
        hostname.indexOf('.') > 0
        && SANDBOX_FRONTEND_URLS
        && SANDBOX_FRONTEND_URLS.length
        && SANDBOX_FRONTEND_URLS.includes(hostname.substring(hostname.indexOf('.') + 1))
    ) {
        // https://wellness-<env_prefix>.demodashboardsandbox.shipsy.io
        const subdomain = hostname.substring(0, hostname.indexOf('.'));
        const envPrefixIndex = subdomain.indexOf('-');
        organisationPrettyName = subdomain.substring(0, envPrefixIndex);
    } else if (hostname.indexOf('.') > 0) {
        organisationPrettyName = hostname.substring(0, hostname.indexOf('.'));
    } else {
        organisationPrettyName = 'dtdc';
    }

    return {
        'organisation-pretty-name': organisationPrettyName,
        'organisation-url': hostname,
    };
};

const getAuthenticationHeaders = () => {
    const storage = window.localStorage;
    const organisationId = getOrganisationId();
    const token = storage.getItem('authToken');
    const userId = storage.getItem('userId');
    const organisationID = storage.getItem('organisationId') || organisationId;
    const orgHeaders = getOrgPrettyName();
    const subAccountId = storage.getItem('subAccountId');
    const customerUserId = storage.getItem('customerUserId');
    return {
        'user-id': userId,
        'organisation-id': organisationID,
        'access-token': token,
        'application-type': applicationType,
        'customer-user-id': customerUserId,
        ...(subAccountId ? { 'sub-account-id': subAccountId } : {}),
        ...(token ? {} : orgHeaders),
    };
};

const getChildAuthenticationHeaders = () => {
    const storage = window.localStorage;
    const organisationId = getOrganisationId();
    const token = storage.getItem('childAccessToken');
    const userId = storage.getItem('childCustomerId');
    const subAccountId = storage.getItem('subAccountId');
    const organisationID = storage.getItem('organisationId') || organisationId;
    const orgHeaders = getOrgPrettyName();
    const customerUserId = storage.getItem('childCustomerUserId');
    return {
        'user-id': userId,
        'organisation-id': organisationID,
        'access-token': token,
        'application-type': applicationType,
        'customer-user-id': customerUserId,
        ...(subAccountId ? { 'sub-account-id': subAccountId } : {}),
        ...(token ? {} : orgHeaders),
    };
};

const getChildClientInfo = () => {
    const storage = window.localStorage;
    const id = storage.getItem('userId');
    const code = storage.getItem('userCode');
    return {
        id,
        code,
    };
};

const getErrorMessage = (err: any) => {
    let errorMessage = '';
    if (!err.response) {
        errorMessage = 'Could not connect. Please check your internet connection';
    } else {
        const responseData = err.response.data;
        if (responseData && responseData.error) {
            errorMessage = responseData.error.message;
        } else {
            errorMessage = `Something went wrong\n${err.message}`;
        }
    }
    return errorMessage;
};

const getErrorMessageForBlob = async (err: any) => {
    let errorMessage = '';
    if (!err.response) {
        errorMessage = 'Could not connect. Please check your internet connection';
    } else {
        const responseData = await new Response(err.response.data).json();
        if (responseData && responseData.error) {
            errorMessage = responseData.error.message;
        } else {
            errorMessage = `Something went wrong\n${err.message}`;
        }
    }
    return errorMessage;
};

const getErrorForBlob = async (err: any) => {
    let error: any = {};
    if (!err.response) {
        error.message = 'Could not connect. Please check your internet connection';
    } else {
        const responseData = await new Response(err.response.data).json();
        if (responseData && responseData.error) {
            error = responseData.error;
        } else {
            error.message = `Something went wrong\n${err.message}`;
        }
    }
    return error;
};

const generateQueryString = (paramsArray: KeyValue[]) => {
    const queryString = paramsArray.map(({ key, val }) => {
        if (val || val === 0) {
            return `${key}=${encodeURIComponent(val)}`;
        }
        return null;
    }).filter((elem: any) => elem !== null).join('&');
    return queryString;
};

const generateQueryStringFromObject = (obj: any) => {
    if (!obj) {
        return '';
    }
    const keys = Object.keys(obj);
    const x = keys.map((key) => {
        return { key, val: obj[key] };
    });
    return generateQueryString(x);
};

const generateObjectFromList = (key: string, list: any) => {
    const generatedList: any = [];
    if (list) {
        list.forEach((element: any) => {
            generatedList.push({ key, val: element });
        });
    }
    return generatedList;
};

const getFileExtension = (file: any) => {
    return file.name.split('.').pop();
};

const dataHeaders = {
    JPG: 'image/*',
    JPEG: 'image/*',
    PNG: 'image/*',
    PDF: 'application/*',
    DOCX: 'application/*',
    XLSX: 'application/*',
    CSV: 'text/csv',
    XLS: 'application/*',
    DOC: 'application/*',
};

const logoutUser = async () => {
    // Remove auth storage
    await logoutCustomer();
    const storage = window.localStorage;
    storage.removeItem('userId');
    storage.removeItem('authToken');
    storage.removeItem('childCustomerId');
    storage.removeItem('childAccessToken');
    storage.removeItem('isChildCustomer');
    storage.removeItem('childCustomerCode');
    storage.removeItem('type');
    storage.removeItem('redirectionTime');
    storage.removeItem('userCode');
    storage.removeItem('username');
    storage.removeItem('employeeId');
    storage.removeItem('employeeCode');
    storage.removeItem('employeeAuthToken');
    storage.removeItem('defaultLandingUrl');
    storage.removeItem('customerUserId');
    window.location.href = '/login';
};

const getErrorMessageCommon = (err: any, responseType?: ResponseType) => {
    if (responseType === 'blob') {
        return getErrorMessageForBlob(err);
    }
    return getErrorMessage(err);
};

const getUsernameAndLogo = () => {
    const storage = window.localStorage;
    const username = storage.getItem('username');
    const logoUrl = storage.getItem('logoUrl');
    return {
        username,
        logoUrl,
    };
};

export default {
    getErrorMessageCommon,
    getAuthenticationHeaders,
    logoutUser,
    getErrorMessage,
    dataHeaders,
    getFileExtension,
    generateObjectFromList,
    generateQueryStringFromObject,
    generateQueryString,
    getErrorForBlob,
    getErrorMessageForBlob,
    isAuthenticated,
    getUsernameAndLogo,
    getChildClientInfo,
    getOrganisationId,
    getChildAuthenticationHeaders,
};
