import { Dispatch } from 'redux';
import { fetchMaster } from '../network/common.api';
import {
    SET_MASTER_DATA,
    SET_FETCHING_DATA,
    SET_UI_THEME,
    SET_MISCELLANEOUS_DATA,
} from '../reducers/redux-constants';
import { get } from 'lodash';
import Helper from 'library/Helper';
import { fetchPageForEmployee } from './objectpage-actions';
import { OtherBuckets } from 'library/Types';

export const loadInitialData = () => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_MISCELLANEOUS_DATA,
        field: OtherBuckets.EXPAND_STATE,
        data: {},
    });
    dispatch({
        type: SET_FETCHING_DATA,
        data: true,
    });
    dispatch({
        type: SET_UI_THEME,
        data: {
            primaryColor: window.localStorage.getItem('primaryColor'),
            secondryColor: window.localStorage.getItem('secondryColor'),
            ...(Helper.getThemeColorFromPrimary(window.localStorage.getItem('primaryColor') || '')),
        },
    });
    await fetchPageForEmployee(dispatch);
    const masterData = await fetchMaster();
    const faviconUrl = get(masterData, 'data.config.favicon_url');
    Helper.changeFavicon(faviconUrl);
    dispatch({
        type: SET_MASTER_DATA,
        data: {
            ...masterData.data,
            masterDataLoaded: true,
        },
    });
    if (masterData.data.config?.custom_theme?.primaryColor) {
        dispatch({
            type: SET_UI_THEME,
            data: {
                primaryColor: masterData.data.config.custom_theme?.primaryColor,
                secondryColor: masterData.data.config.custom_theme?.secondryColor
                || masterData.data.config.custom_theme?.primaryColor,
                ...(Helper.getThemeColorFromPrimary(masterData.data.config.custom_theme?.primaryColor)),
            },
        });
    } else {
        dispatch({
            type: SET_UI_THEME,
            data: {
                primaryColor: '#082E78',
                secondryColor: '#ED1C24',
                ...(Helper.getThemeColorFromPrimary('#082E78')),
            },
        });
    }
    dispatch({
        type: SET_FETCHING_DATA,
        data: false,
    });
};
