import { UITheme } from 'library/Types';
import {
    SET_UI_THEME,
    SET_DEFAULT_PORTAL,
} from '../reducers/redux-constants';

export const setRTL = (isRTL: boolean) => {
    return {
        type: SET_UI_THEME,
        data: {
            isRTL,
        },
    };
};
export const setTheme = (theme: UITheme) => {
    return {
        type: SET_UI_THEME,
        data: theme,
    };
};

export const setDefaultPortalAction = () => {
    return {
        type: SET_DEFAULT_PORTAL,
        data: true,
    };
};
