import { CheckCircleFilled, RightOutlined } from '@ant-design/icons';
import { Modal, Progress } from 'antd';
import React, { useState, useEffect } from 'react';
import { HocOptions } from './generic-hoc.types';
import GenericHoc from './generic-hoc';
import { Routes } from 'library/constants';
import { useTour } from '@reactour/tour';
import { ReduxStore } from 'reducers/redux.types';
import { steps as tourStepData } from '../../constants';

const styles = {
    subTitle: {
        fontSize: '10px',
        color: '#666666',
    },
    steps: {
        display: 'flex',
        height: '60px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    stepName: {
        display: 'flex',
        alignItems: 'center',
    },
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};

const steps = [
    {
        title: 'Take a tour of Customer Portal',
        key: 'tour',
        isCompleted: false,
    },
    {
        title: 'KYC & GST Verification',
        key: 'kyc',
        isCompleted: false,
    },
    {
        title: 'Bank Details',
        key: 'bank',
        isCompleted: false,
    },
    {
        title: 'Create First Booking',
        key: 'booking',
        isCompleted: false,
    },
];

const GetStartedModal = (props: any) => {
    const {
        isVisible, classes, cancelModal, history, openBookingModal, routesInfo,
    } = props;

    const storage = window.localStorage;
    const { setIsOpen, setCurrentStep, setSteps } = useTour();


    const stepsTour = React.useMemo(() => {
        return tourStepData.filter((step) => {
            if (step.isVisible) {
                return true;
            }
            if (step.visibleForRoutes) {
                return step.visibleForRoutes.some((route) => {
                    return routesInfo[route] && routesInfo[route].isVisible;
                });
            }
            return false;
        });
    }, [routesInfo]);

    useEffect(() => {
        if (setSteps) {
            setSteps(stepsTour);
        }
    }, [stepsTour]);

    const [modalSteps, setModalSteps] = useState(steps);
    const [completedNum, setCompletedNum] = useState(0);

    useEffect(() => {
        let completedSteps: string[] = storage.getItem('completedSteps')?.split(',') || [];
        completedSteps = completedSteps.filter((step) => steps.map((s) => s.key).includes(step));
        if (completedSteps.length) {
            const initialSteps: { title: string, key: string, isCompleted: boolean }[] = [];
            modalSteps.forEach((step, i) => {
                const currentStep = { ...step };
                currentStep.isCompleted = completedSteps.includes(step.key);
                initialSteps[i] = currentStep;
            });
            setCompletedNum(completedSteps.length);
            setModalSteps(initialSteps);
        }
    }, []);

    const onStepClick = (step: any, index: number) => {
        const completedSteps: string[] = storage.getItem('completedSteps')?.split(',') || [];
        if (!completedSteps.includes(step.key)) {
            const stepsCopy = [...modalSteps];
            stepsCopy[index].isCompleted = true;
            setModalSteps(stepsCopy);
            completedSteps.push(step.key);
            setCompletedNum(completedSteps.length);
            storage.setItem('completedSteps', completedSteps.toString());
        }
        switch (step.key) {
            case 'tour':
                setCurrentStep(0);
                setIsOpen(true);
                break;
            case 'kyc':
            case 'bank':
                history.push(`/${Routes.SETTINGS_ACCOUNT}`);
                break;
            case 'booking':
                openBookingModal();
                break;
            default:
                break;
        }
        cancelModal();
    };

    const getProgressPercent = () => {
        let percent = 0;
        modalSteps.forEach((step) => {
            if (step.isCompleted) {
                percent += 25;
            }
        });
        return percent;
    };

    return (
        <Modal
            className={classes.modal}
            closable
            visible={isVisible}
            title={
                (
                    <div>
                        <div style={{ fontWeight: 600 }}>Get Started</div>
                        <div
                            className={classes.subTitle}
                        >
                            Complete these tasks to get better acquainted with the product
                        </div>
                    </div>
                )
            }
            width={350}
            footer={null}
            onCancel={() => cancelModal()}
        >
            <div>
                <div className={classes.progress}>
                    <Progress
                        percent={getProgressPercent()}
                        strokeColor="#065492"
                        style={{ width: '85%' }}
                        showInfo={false}
                        size="small"
                    />
                    <div>{`${completedNum} / 4`}</div>
                </div>
                <div>
                    {modalSteps.map((step: any, index) => {
                        return (
                            <div
                                onClick={() => onStepClick(step, index)}
                                key={step.key}
                                className={classes.steps}
                                style={{ borderBottom: index === steps.length - 1 ? 'none' : '1px solid #ccc' }}
                            >
                                <div className={classes.stepName}>
                                    <CheckCircleFilled style={{ color: step.isCompleted ? '#21AF49' : '#ccc' }} />
                                    <div style={{ marginLeft: '10px' }}>{step.title}</div>
                                </div>
                                {!step.isCompleted ? <RightOutlined /> : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};


const mapStateToProps = (state: ReduxStore) => {
    const { miscellaneousState } = state;
    return {
        routesInfo: miscellaneousState?.ROUTES_INFO || {},
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(GetStartedModal);
