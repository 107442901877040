/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const QuickActionSvg = (props: any) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13 0V1H0V0H13ZM7.92969 4L7.40625 5H0V4H7.92969ZM0 8H5.83594L5.3125 9H0V8ZM11.8125 8H16.25L7.84375 16H5.83594L8.21094 11H5.39844L9.57812 3H14.3594L11.8125 8ZM7.44531 15L13.75 9H10.1875L12.7266 4H10.1875L7.04688 10H9.78906L7.41406 15H7.44531Z"
            fill="current"
        />
    </svg>
);

const QuickActionIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return QuickActionSvg({ ...innerProps, ...props });
        }}
    />
);

export default QuickActionIcon;
