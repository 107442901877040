import { MiscellaneousAction } from 'reducers/redux.types';
import {
    SET_MISCELLANEOUS_DATA,
} from '../reducers/redux-constants';
import { OtherBuckets } from 'library/Types';

const setMiscellaneousState = (field: OtherBuckets, data: any) => {
    const action: MiscellaneousAction = {
        data,
        field,
        type: SET_MISCELLANEOUS_DATA,
    };
    return action;
};

export const storeExpandingState = (state: Record<any, any>) => {
    return setMiscellaneousState(
        OtherBuckets.EXPAND_STATE,
        state,
    );
};

export const storeRoutesInfoState = (state: Record<any, any>) => {
    return setMiscellaneousState(
        OtherBuckets.ROUTES_INFO,
        state,
    );
};

export const storeCNCDataState = (state: Record<any, any>) => {
    return setMiscellaneousState(
        OtherBuckets.CNC_INFO,
        state,
    );
};
