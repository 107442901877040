export const SET_UI_THEME = 'SET_UI_THEME';
export const SET_MASTER_DATA = 'SET_MASTER_DATA';
export const SET_FETCHING_DATA = 'SET_FETCHING_DATA';

// generic actions
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';
export const SET_PAGINATION = 'SET_PAGINATION';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const SET_GENERIC_DATA = 'SET_GENERIC_DATA';
export const SET_GENERIC_SUMMARY = 'SET_GENERIC_SUMMARY';

// Iframe
export const IFRAME_SET_VISIBILITY = 'IFRAME_SET_VISIBILITY';
export const IFRAME_SET_DIMENSIONS = 'IFRAME_SET_DIMENSIONS';
export const IFRAME_SET_LOADED = 'IFRAME_SET_LOADED';
export const IFRAME_SET_RENDERED = 'IFRAME_SET_RENDERED';
export const SET_DEFAULT_PORTAL = 'SET_DEFAULT_PORTAL';

// Object Page actions
export const SET_PAGE_FOR_CUSTOMERS = 'SET_PAGE_FOR_CUSTOMERS';
export const UPDATE_VIEW_COLUMNS = 'UPDATE_VIEW_COLUMNS';
export const UPDATE_VIEW_FILTERS = 'UPDATE_VIEW_FILTERS';
export const FETCH_VIEW_DATA = 'FETCH_VIEW_DATA';
export const SET_VIEW_DATA = 'SET_VIEW_DATA';

// Miscellaneous actions
export const SET_MISCELLANEOUS_DATA = 'SET_MISCELLANEOUS_DATA';
export const SET_EXPAND_STATE = 'SET_EXPAND_STATE';
export const SET_ROUTES_INFO_DATA = 'SET_ROUTES_INFO_DATA';
