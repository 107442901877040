/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const TargetSvg = (props: any) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.29571 8.29669C7.95189 8.6405 7.48558 8.83366 6.99935 8.83366C6.51312 8.83366 6.0468 8.6405 5.70299 8.29669C5.35917 7.95287 5.16602 7.48656 5.16602 7.00033C5.16602 6.51409 5.35917 6.04778 5.70299 5.70396C6.0468 5.36015 6.51312 5.16699 6.99935 5.16699C7.48558 5.16699 7.95189 5.36015 8.29571 5.70396C8.63953 6.04778 8.83268 6.51409 8.83268 7.00033C8.83268 7.48656 8.63953 7.95287 8.29571 8.29669Z"
            fill="currentColor"
            stroke="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 0C7.20628 0 7.40411 0.0819442 7.54997 0.227806C7.69583 0.373667 7.77778 0.571498 7.77778 0.777778V1.61C8.9413 1.77818 10.0192 2.31824 10.8505 3.14952C11.6818 3.98081 12.2218 5.0587 12.39 6.22222H13.2222C13.4285 6.22222 13.6263 6.30417 13.7722 6.45003C13.9181 6.59589 14 6.79372 14 7C14 7.20628 13.9181 7.40411 13.7722 7.54997C13.6263 7.69583 13.4285 7.77778 13.2222 7.77778H12.39C12.2218 8.9413 11.6818 10.0192 10.8505 10.8505C10.0192 11.6818 8.9413 12.2218 7.77778 12.39V13.2222C7.77778 13.4285 7.69583 13.6263 7.54997 13.7722C7.40411 13.9181 7.20628 14 7 14C6.79372 14 6.59589 13.9181 6.45003 13.7722C6.30417 13.6263 6.22222 13.4285 6.22222 13.2222V12.39C5.0587 12.2218 3.98081 11.6818 3.14952 10.8505C2.31824 10.0192 1.77818 8.9413 1.61 7.77778H0.777778C0.571498 7.77778 0.373667 7.69583 0.227806 7.54997C0.0819442 7.40411 0 7.20628 0 7C0 6.79372 0.0819442 6.59589 0.227806 6.45003C0.373667 6.30417 0.571498 6.22222 0.777778 6.22222H1.61C1.7783 5.05875 2.3184 3.98092 3.14966 3.14966C3.98092 2.3184 5.05875 1.7783 6.22222 1.61V0.777778C6.22222 0.571498 6.30417 0.373667 6.45003 0.227806C6.59589 0.0819442 6.79372 0 7 0ZM3.11111 7C3.11111 5.9686 3.52083 4.97945 4.25014 4.25014C4.97945 3.52083 5.9686 3.11111 7 3.11111C8.0314 3.11111 9.02055 3.52083 9.74986 4.25014C10.4792 4.97945 10.8889 5.9686 10.8889 7C10.8889 8.0314 10.4792 9.02055 9.74986 9.74986C9.02055 10.4792 8.0314 10.8889 7 10.8889C5.9686 10.8889 4.97945 10.4792 4.25014 9.74986C3.52083 9.02055 3.11111 8.0314 3.11111 7Z"
            fill="currentColor"
        />
        <path
            d="M7.00065 8.16634C7.31007 8.16634 7.60682 8.04342 7.82561 7.82463C8.0444 7.60584 8.16732 7.30909 8.16732 6.99967C8.16732 6.69026 8.0444 6.39351 7.82561 6.17472C7.60682 5.95592 7.31007 5.83301 7.00065 5.83301C6.69123 5.83301 6.39449 5.95592 6.17569 6.17472C5.9569 6.39351 5.83398 6.69026 5.83398 6.99967C5.83398 7.30909 5.9569 7.60584 6.17569 7.82463C6.39449 8.04342 6.69123 8.16634 7.00065 8.16634Z"
            fill="currentColor"
        />
    </svg>
);

const TargetIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return TargetSvg({ ...innerProps, ...props });
        }}
    />
);

export default TargetIcon;
