import * as React from 'react';
import Loader from '../../common/Loader';
import withStyles from 'react-jss';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import CreateAddress from '../../address/create-address';
import ScheduleIcon from '../../../assets/schedule-details';
import ContentsIcon from '../../create-consignment/single-consignment-icons/content';
import AddressIcon from '../../create-consignment/single-consignment-icons/address';
import moment, { Moment } from 'moment';
import Cross from '../../../assets/cross';
import { pickupFormFields } from './create-pickup.constants';
import { createPickupStyles } from './create-pickup.styles';
import { StylesProps, ThemeType } from '../../../theme/jss-types';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import {
    deleteAddress,
    fetchPickupAddress,
    savePickup,
    setDefaultAddressData,
    getDefaultAddress,
    editAddress as editBusinessAddress,
} from '../../../network/pickup.api';
import { PickupAddress } from './pickup.types';
import { Commodity, TimeSlot } from '../../../library/Types';
import { useDebounce } from '../../../hooks/use-debounce';
import { FormField, InputTypes } from '../../create-consignment/create-consignment.types';
import { getContentList, getTimeSlots } from '../../../network/consignments.api';
import { getLTLCustomerHubDetails } from '../../../network/common.api';
import {
    Button,
    Drawer,
    Radio,
    Form,
    Input,
    Select,
    DatePicker,
    message,
    Popconfirm,
    Spin,
} from 'antd';
import { formRules } from '../../../library/constants';
import Helper from 'library/Helper';
import { MasterConfig, Master } from 'types/master-data-types';
import PrimaryIcon from 'components/create-consignment/single-consignment-icons/primary';
import { BookOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { isEqual, uniqueId, uniqWith } from 'lodash';
import { useTranslation } from 'react-i18next';

const {
    PickupSchedule,
    PickupTime,
    customerPickupId,
    ItemType,
    NumPieces,
    Weight,
    AddressOption,
    AddressLine,
    Length,
    Height,
    Width,
    Unit,
    ContentTypes,
    DeclaredValue,
    WeightUnit,
    Quantity,
    Volume,
    WaybillType,
    autoAllocateHub,
    pickupHubCode,
} = pickupFormFields;

const { useState, useEffect } = React;
interface SinglePickupProps extends StylesProps<ReturnType<typeof createPickupStyles>> {
    onClose: () => void;
    setSuccess: (data: any) => void;
    loadpickups: () => void;
    phoneRegex: any;
    config: MasterConfig;
    uiTheme: ThemeType;
    master: Master;
    loadTypeOptions: Array<{key: string; label: string; value: string;}>;
}
interface DestinationDetails {
    key: any;
    city?: any;
    quantity?: any;
    units?: any;
}

const CreatePickup = (props: SinglePickupProps) => {
    const {
        classes,
        onClose,
        setSuccess,
        loadpickups,
        phoneRegex,
        config,
        uiTheme,
        loadTypeOptions,
        master,
    } = props;
    const { t, i18n } = useTranslation();
    const [addressList, setAddressList] = useState<PickupAddress[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [creating, setCreating] = useState<boolean>(false);
    const [selectedAddress, setSelected] = React.useState<PickupAddress>();
    const [addressType, setAddressType] = React.useState<string>('saved');
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const [pickupDate, setPickupDate] = useState<Moment | null>(moment());
    const [itemType, setItemType] = React.useState<string>('non-document');
    const [contentList, setContentList] = React.useState<Commodity[]>([]);
    const [editAddress, setEditAddress] = React.useState<PickupAddress | undefined>();
    const [toggle, setToggle] = React.useState<boolean>(true);
    const allowedUnits = config?.allowed_units_in_pickup;
    const pickupConfig = config?.customer_portal_pickup_config;
    const [destinationDetails, setDestinationDetails] = useState<DestinationDetails[]>([]);
    const partsToShowSinglePickup = pickupConfig?.parts_to_show_single_pickup;
    const showDestinationDetailsInPickup = config?.show_destination_details_in_pickup;
    const showAddressCode = config?.customer_portal_config?.allow_address_code_in_addresses;
    const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);
    const isLTLCustomer = master?.Customer?.customer_category === 'ltl';
    const [fetching, setFetching] = React.useState(false);
    const [pickHubCode, setPickHubCode] = React.useState('');
    const [pickupHubs, setPickupHubs] = React.useState<any[]>([]);
    const [selectedHubId, setSelectedHubId] = React.useState(null);
    const [defaultAddress, setDefaultAddress] = useState<Record<any, any>>({});
    const [defaultAddressSrc, setDefaultAddressSrc] = useState<Record<any, any>>({});
    const allowDefaultSourceAddress = !config?.customer_portal_config?.disable_default_address;
    const defaultMultipleLimit = allowDefaultSourceAddress
        ? config?.customer_portal_config?.default_addresses_limit : undefined;
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };
    const [autoAllocation, setAutoAllocation] = useState(false);

    const addDestination = () => {
        let currentDestination : DestinationDetails[] = [];
        if (destinationDetails)currentDestination = [...destinationDetails];
        currentDestination.push({
            key: uniqueId(),
            quantity: 0,
            units: null,
            city: null,
        });
        setDestinationDetails(currentDestination);
    };

    const removeDestination = (key: any) => {
        setDestinationDetails(destinationDetails.filter((destination) => destination.key !== key));
    };
    const onChangeDestinationDetails = (key: any, fieldKey: any, value: any) => {
        const updatedDestinations = destinationDetails.map((item) => {
            if (item.key === key) {
                return {
                    ...item,
                    [fieldKey]: value,
                };
            }
            return item;
        });
        setDestinationDetails(updatedDestinations);
    };

    const loadDefaultAddress = async () => {
        if (!allowDefaultSourceAddress) return;
        setLoading(true);
        const response = await getDefaultAddress();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            for (let i = 0; i < response.data.length; i += 1) {
                const entry = response.data[i];
                if (entry.type === 'srcAddress') {
                    setDefaultAddressSrc(entry);
                }
            }
        }
        setLoading(false);
    };

    const saveDefaultAddress = async (address: PickupAddress, isMultipleDefault: boolean = false) => {
        setLoading(true);
        if (isMultipleDefault) {
            const response = await editBusinessAddress(address);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                loadAddress();
            }
        } else {
            const id = address.id;
            const response = await setDefaultAddressData({
                businessAddressId: id,
                addressTypeRadio: 'srcAddress',
            });
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadDefaultAddress();
                setLoading(true);
                let data = addressList || [];
                // eslint-disable-next-line no-underscore-dangle
                if (allowDefaultSourceAddress && id && searchString.length < 1) {
                    let add: PickupAddress | undefined;
                    data = data.filter((item: PickupAddress) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (item.id === id) {
                            add = item;
                            return false;
                        }
                        return true;
                    });
                    if (add) data.unshift(add);
                    setAddressList(data);
                }
            }
        }
        setLoading(false);
    };

    const [form] = Form.useForm();

    const debounceTime = useDebounce(searchString);
    const loadAddress = async () => {
        let defaultAdd : Record<any, any> = {};
        if (allowDefaultSourceAddress && !isDefaultLoaded && !defaultMultipleLimit) {
            const response = await getDefaultAddress();
            if (response.isSuccess) {
                defaultAdd = response?.data || {};
                for (let i = 0; i < defaultAdd.length; i += 1) {
                    const entry = defaultAdd[i];
                    if (entry.addType === 'Origin') {
                        setDefaultAddressSrc(entry);
                    } else if (entry.addType === 'Destination') {
                        setDefaultAddress(entry);
                    }
                }
                setIsDefaultLoaded(true);
            }
        } else {
            defaultAdd = defaultAddress;
        }
        setLoading(true);
        const response = await fetchPickupAddress({
            showDefaultBy: defaultMultipleLimit ? 'src' : undefined,
            searchString,
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        }

        response.data = response.data.filter((item: PickupAddress) => item.addType === 'Origin' || item.addType === 'Both');
        let data = response?.data || [];

        // eslint-disable-next-line no-underscore-dangle
        if (allowDefaultSourceAddress && defaultAdd?._id && searchString.length < 1 && !defaultMultipleLimit) {
            let add: PickupAddress | undefined;
            data = data.filter((item: PickupAddress) => {
                // eslint-disable-next-line no-underscore-dangle
                if (item.id === defaultAdd._id) {
                    add = item;
                    return false;
                }
                return true;
            });
            if (add) data.unshift(add);
        }
        setAddressList(uniqWith(data, isEqual));
        setLoading(false);
    };

    const loadContents = async () => {
        setLoading(true);
        const response = await getContentList({
            isInternational: false,
        });
        setContentList(response?.data || []);
        setLoading(false);
    };

    const loadTimeSlots = async () => {
        const response = await getTimeSlots({
            pickupDate: moment(pickupDate).format('DD/MM/YYYY').toString(),
        });
        setTimeSlots(Helper.isValidArray(response?.data) ? response?.data : []);
    };

    useEffect(() => {
        loadTimeSlots();
        loadContents();
    }, [pickupDate]);

    useEffect(() => {
        loadAddress();
    }, [debounceTime, addressType, toggle]);

    const handlePickupDate = (date: Moment | null) => {
        setPickupDate(date);
        form.resetFields([PickupTime.key]);
    };

    const getDestinationDetails = () => {
        if (showDestinationDetailsInPickup) {
            return destinationDetails.map((destination) => {
                return {
                    city: destination.city,
                    quantity: destination.quantity,
                    units: destination.units,
                };
            });
        }
        return undefined;
    };

    const checkForErrors = async () => {
        const values = await form.validateFields();
        const { errorFields } = values;
        if (errorFields?.length) {
            return;
        }
        if (!selectedAddress?.name) {
            message.error('Please select pickup address!');
            return;
        }
        if (showDestinationDetailsInPickup) {
            let totalQuantity = 0;
            destinationDetails.forEach((destination) => {
                totalQuantity += parseInt(destination.quantity, 10);
            });
            if (totalQuantity !== parseInt(values.totalItems, 10)) {
                message.error('Total Items and Total Quantity Mismatch!');
                return;
            }
        }
        const pickupTime = values.pickupTime?.split('-');
        const storage = window.localStorage;
        const apiBody = {
            childClient: {
                code: storage.getItem('childCustomerCode') || storage.getItem('userCode'),
                id: storage.getItem('childCustomerId') || storage.getItem('userId'),
            },
            closingTimeString: null,
            // date: '2021-10-01T18:30:00.000Z',
            destinationDetails: getDestinationDetails(),
            pickupType: 'BUSINESS',
            pickupAddress: {
                ...(selectedAddress || {}),
                pincode: selectedAddress?.pincode?.toUpperCase(),
            },
            customerPickupId: values.customerPickupId,
            totalItems: values.totalItems,
            declaredPrice: values.declaredValue,
            commodityId: values.commodityId,
            courierType: values.courierType?.toUpperCase(),
            pickupSlot: {
                start: pickupTime[0].trim(),
                end: pickupTime[1].trim(),
                date: moment(pickupDate).format('DD/MM/YYYY'),
            },
            dimensions: {
                length: Helper.converttoCentimeter(values.length, values.unit),
                width: Helper.converttoCentimeter(values.width, values.unit),
                height: Helper.converttoCentimeter(values.height, values.unit),
            },
            totalVolume: values.totalVolume,
            totalWeight: Helper.converttoKilogram(Number(values.totalWeight), values.weightUnit),
            waybillType: values.waybillType,
            ...(isLTLCustomer && {
                autoAllocate: autoAllocation,
                hubId: autoAllocation ? null : selectedHubId,
            }),
        };
        setCreating(true);
        const response = await savePickup(apiBody);
        if (response.isSuccess) {
            loadpickups();
            setSuccess(response?.data);
        } else {
            message.error(response.errorMessage);
        }
        setCreating(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButton;
        const btnDisabled = false;
        if (btnDisabled) {
            btnClass = classes.newButtonDisabled;
        }
        return (
            <Button
                type="primary"
                onClick={() => checkForErrors()}
                style={{ marginRight: 16 }}
                loading={creating}
                disabled={btnDisabled}
                className={btnClass}
            >
                {t('submit')}
            </Button>
        );
    };
    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>{t('add_pickup')}</span>
                </div>
                {renderButton()}
            </div>
        );
    };

    // const renderTitle = (field: FormField) => {
    //     return (
    //         <div
    //             className={classes.title}
    //         >
    //             {t(field.key)}
    //         </div>
    //     );
    // };

    const renderTitle = (field: FormField) => {
        return (
            <div
                className={classes.title}
                style={{
                    whiteSpace: 'nowrap',
                    display: 'block',
                }}
            >
                {t(field.key)}
            </div>
        );
    };

    const renderItemType = (field: FormField) => {
        const options = field.key === ItemType.key ? loadTypeOptions || field.options : field.options;
        if (options) {
            options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                <Radio.Group
                    options={field.options}
                    onChange={(e) => setItemType(e.target.value)}
                    style={{
                        display: 'flex',
                        flexDirection: field.direction,
                    }}
                />
            </Form.Item>
        );
    };

    const renderAddressType = (field: FormField) => {
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                <Radio.Group
                    onChange={(e) => setAddressType(e.target.value)}
                    options={field.options}
                    style={{
                        display: 'flex',
                        flexDirection: field.direction,
                    }}
                />
            </Form.Item>
        );
    };

    const renderRadio = (field: FormField) => {
        if (field.key === ItemType.key) {
            return renderItemType(field);
        }
        return renderAddressType(field);
    };

    const renderWeightUnit = () => {
        return (
            <Form.Item
                name={WeightUnit.key}
                key={WeightUnit.key}
                initialValue={WeightUnit.defaultValue}
            >
                <Select
                    disabled={WeightUnit.disabled}
                    placeholder={WeightUnit.placeholder}
                    options={WeightUnit.options}
                    style={{ width: WeightUnit.width }}
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                >
                    {WeightUnit.options?.map((item) => {
                        return (
                            <Select.Option
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const renderInput = (field: FormField, type: string, optional = false) => {
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={optional ? [] : [formRuleRequired]}
            >
                <Input
                    type={type}
                    placeholder={t(field.key)}
                    style={{ width: field.width }}
                />
            </Form.Item>
        );
    };

    const disabledDate = (current: Moment) => {
        return moment(current) <= moment().subtract(1, 'd');
    };

    const renderDate = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={[formRuleRequired]}
            >
                <DatePicker
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                    disabledDate={disabledDate}
                    onChange={(val) => handlePickupDate(val)}
                />
            </Form.Item>
        );
    };

    const renderTime = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={[formRuleRequired]}
            >
                <Select
                    placeholder={t('time_slot')}
                    style={{ width: field.width }}
                >
                    {timeSlots.map((item) => {
                        const name = `${item.start} - ${item.end}`;
                        return (
                            <Select.Option key={name} value={name}>
                                {name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            message.success('Address Deleted Successfully');
            setToggle(!toggle);
        } else {
            message.error(result.errorMessage);
        }
    };

    const renderDelete = (item: PickupAddress) => {
        return (
            <Popconfirm
                placement="topLeft"
                title="Are you sure you want to delete this Address"
                onConfirm={(e) => handleDelete(e, item.id)}
                onCancel={(e) => Helper.stops(e as any)}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ marginRight: 8, color: 'red' }}
                />
            </Popconfirm>
        );
    };

    const renderEdit = (item: PickupAddress) => {
        return (
            <EditOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    setAddressType('createnew');
                    form.setFieldsValue({
                        [AddressOption.key]: 'createnew',
                    });
                    setEditAddress(item);
                }}
                style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            />
        );
    };

    const renderMarkAsDefaultMultiple = (item: PickupAddress) => {
        const isMultipleDefault = item?.isDefaultSrc;
        return (
            <>
                { isMultipleDefault ? (
                    <Button
                        style={{ marginRight: 8, cursor: 'default' }}
                        shape="round"
                    >
                        Default
                    </Button>
                ) : null}
                { isMultipleDefault ? (
                    <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to remove this default Address"
                        onConfirm={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: false,
                            }, true);
                        }}
                        onCancel={(e) => Helper.stops(e as any)}
                    >
                        <BookOutlined
                            style={{ color: 'red', marginRight: 8 }}
                            onClick={(e) => { Helper.stops(e as any); }}
                        />
                    </Popconfirm>
                ) : (
                    <BookOutlined
                        onClick={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: true,
                            }, true);
                        }}
                        style={{ color: uiTheme.primaryColor, marginRight: 8 }}
                    />
                )}
            </>
        );
    };

    const renderMarkAsDefault = (item: PickupAddress) => {
        if (!allowDefaultSourceAddress) {
            return null;
        }
        if (defaultMultipleLimit) {
            return renderMarkAsDefaultMultiple(item);
        }
        // eslint-disable-next-line no-underscore-dangle
        if (defaultAddress._id === item.id || defaultAddressSrc._id === item.id) {
            return (
                <Button
                    style={{ marginRight: 8, cursor: 'default' }}
                    shape="round"
                    // type="primary"
                >
                    Default
                </Button>
            );
        }
        return (
            <Button
                type="link"
                onClick={(e) => {
                    Helper.stops(e as any);
                    saveDefaultAddress(item);
                }}
                style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            >
                SET AS DEFAULT
            </Button>
        );
    };
    const showaddcode = (item: PickupAddress) => {
        return (
            <span style={{ display: 'flex', fontWeight: 'bold' }}>
                <span>{item?.addressCode}</span>
                <span style={{ margin: '0px 6px 0px 4px', fontWeight: 'bold' }}> | </span>
            </span>
        );
    };


    const renderCheckbox = (item: PickupAddress) => {
        return (
            <Checkbox
                key={item.id}
                checked={item.id === selectedAddress?.id}
                className={classes.checkbox}
            >
                <div className={classes.addressName}>
                    <span style={{ display: 'flex' }}>
                        {showAddressCode && item?.addressCode ? showaddcode(item) : null}
                        <span style={{ display: 'inline-block', fontWeight: 'bold' }}>{item.name}</span>
                    </span>
                    <div style={{ justifyContent: 'flex-end' }}>
                        {renderMarkAsDefault(item)}
                        {renderEdit(item)}
                        {renderDelete(item)}
                    </div>
                </div>
            </Checkbox>
        );
    };

    const renderSelect = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                <Select
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                    options={field.options}
                />
            </Form.Item>
        );
    };

    const renderFormItem = (field: FormField, type: string = 'text', optional = false) => {
        switch (field.type) {
            case InputTypes.Radio: return renderRadio(field);
            case InputTypes.Input: return renderInput(field, type, optional);
            case InputTypes.Date: return renderDate(field);
            case InputTypes.Time: return renderTime(field);
            case InputTypes.Select: return renderSelect(field);

            default: return 'Input is not defined';
        }
    };

    const renderDimensions = () => {
        if (itemType === 'document') {
            return null;
        }
        return (
            <div className={classes.dimensions}>
                <div className={classes.dimentionTitle}>
                    {t('dimensions')}
                    *
                </div>
                <div className={classes.flexRow}>
                    {renderFormItem(Length, 'number')}
                    {renderFormItem(Width, 'number')}
                    {renderFormItem(Height, 'number')}
                    {renderFormItem(Unit, 'number')}
                </div>
            </div>
        );
    };

    const renderDeclaredValue = () => {
        if (itemType === 'document') {
            return null;
        }
        return (
            <div className={classes.field}>
                {renderTitle(DeclaredValue)}
                <Form.Item
                    name={DeclaredValue.key}
                    className={classes.contentType}
                    valuePropName={DeclaredValue.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Input
                        type="number"
                        placeholder={t(DeclaredValue.key)}
                        style={{ width: DeclaredValue.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDeclaredValueAndRisk = () => {
        if (itemType === 'document') {
            return null;
        }
        return (
            <div className={classes.boxFields}>
                {renderDeclaredValue()}
            </div>
        );
    };

    const renderNumPeices = (destination: DestinationDetails | undefined) => {
        return (
            <div className={classes.field}>
                {renderTitle(destination ? Quantity : NumPieces)}
                <Form.Item
                    name={destination ? undefined : NumPieces.key}
                    className={classes.contentType}
                    initialValue={NumPieces.defaultValue}
                    valuePropName={NumPieces.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Input
                        type="number"
                        min={0}
                        placeholder={t('num_pieces_placeholder')}
                        style={{ width: NumPieces.width }}
                        onChange={destination
                            ? (e) => onChangeDestinationDetails(destination.key, Quantity.key, e.target.value)
                            : undefined}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderContentType = () => {
        const optionList = contentList.map((item) => {
            return {
                value: item.COMMODITY_ID,
                label: item.COMMODITY_NAME,
            };
        });
        return (
            <div className={classes.field}>
                {renderTitle(ContentTypes)}
                <Form.Item
                    name={ContentTypes.key}
                    className={classes.contentType}
                    initialValue={ContentTypes.defaultValue}
                    valuePropName={ContentTypes.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Select
                        placeholder={t('content_type_placeholder')}
                        style={{ width: ContentTypes.width }}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        options={optionList}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderWaybillType = () => {
        return (
            <div className={classes.weight}>
                {renderTitle(WaybillType)}
                <Form.Item
                    name={WaybillType.key}
                    className={classes.contentType}
                    initialValue={WaybillType.defaultValue}
                    valuePropName={WaybillType.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Radio.Group
                        options={WaybillType.options}
                        defaultValue={WaybillType.defaultValue}
                        style={{
                            display: 'flex',
                            flexDirection: WaybillType.direction,
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderContentAndPieces = () => {
        if (itemType === 'document') {
            return null;
        }
        return (
            <div className={classes.flexRow}>
                { partsToShowSinglePickup && partsToShowSinglePickup[WaybillType.key] ? renderWaybillType() : null}
                { partsToShowSinglePickup && !partsToShowSinglePickup[ContentTypes.key] ? null : renderContentType()}
                {renderNumPeices(undefined)}
            </div>
        );
    };

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const renderField = (field: FormField, type: string = 'text', optional = false) => {
        return (
            <div className={classes.field}>
                {renderTitle(field)}
                {renderFormItem(field, type, optional)}
            </div>
        );
    };

    // const renderUpcoming = () => {
    //   return (
    //     <div className={classes.upcoming}>
    //       <span className={classes.upcomingText}>
    //         Upcoming Pickups
    //       </span>
    //       <span className={classes.timeleft}>
    //         3 Hrs Left
    //       </span>
    //       <span className={classes.datetime}>
    //         {moment().format('DD MMM YYYY ')}
    //         (12:00 PM - 3:00 PM)
    //       </span>
    //     </div>
    //   );
    // };

    const renderSchedule = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(ScheduleIcon, t('pickup_schedule_details'))}
                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {renderField(PickupSchedule)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {renderField(PickupTime)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {renderField(customerPickupId, 'text', true)}
                    </div>
                </div>
            </div>
        );
    };

    const renderItemTypeField = () => {
        return (
            <div className={classes.itemTypeField}>
                {renderTitle(ItemType)}
                {renderFormItem(ItemType)}
            </div>
        );
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const renderWeight = () => {
        return (
            <div className={classes.weight}>
                {renderTitle(Weight)}
                <Form.Item
                    name={Weight.key}
                    className={classes.contentType}
                    initialValue={Weight.defaultValue}
                    valuePropName={Weight.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Input
                        type="number"
                        size="small"
                        min={0}
                        placeholder={t('weight')}
                        style={{ width: Weight.width }}
                        addonAfter={renderWeightUnit()}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderVolume = () => {
        return (
            <div className={classes.weight}>
                {renderTitle(Volume)}
                <Form.Item
                    name={Volume.key}
                    className={classes.contentType}
                    initialValue={Volume.defaultValue}
                    valuePropName={Volume.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Input
                        type="number"
                        size="small"
                        min={0}
                        placeholder={t('volume')}
                        style={{ width: Volume.width }}
                        // addonAfter={renderWeightUnit()}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderWeightDimenstion = () => {
        return (
            <div className={classes.flexRow}>
                {itemType === 'document' && renderField(NumPieces)}
                {renderWeight()}
                {partsToShowSinglePickup && partsToShowSinglePickup[Volume.key] ? renderVolume() : null}
            </div>
        );
    };

    const renderDetails = () => {
        return (
            <div className={classes.details}>
                { partsToShowSinglePickup && !partsToShowSinglePickup[ItemType.key] ? null : renderItemTypeField()}
                { partsToShowSinglePickup && !partsToShowSinglePickup[ItemType.key] ? null : renderLine()}
                {renderContentAndPieces()}
                {renderWeightDimenstion()}
                { partsToShowSinglePickup && !partsToShowSinglePickup.dimensions ? null : renderDimensions()}
                { partsToShowSinglePickup && !partsToShowSinglePickup[DeclaredValue.key]
                    ? null
                    : renderDeclaredValueAndRisk()}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(ContentsIcon, t('item_details'))}
                {renderDetails()}
            </div>
        );
    };

    const fetchHubList = async () => {
        setFetching(true);
        const response = await getLTLCustomerHubDetails({
            hubCode: pickHubCode,
            loadType: itemType?.toUpperCase(),
        });
        if (response?.data?.success) {
            setPickupHubs(response?.data?.success);
        }
        setFetching(false);
    };
    const debouncePickupHubString = useDebounce(pickHubCode);
    React.useEffect(() => {
        if (pickHubCode && pickHubCode.length > 2) {
            fetchHubList();
        }
    }, [debouncePickupHubString]);
    const handleFetchHubList = (value: string) => {
        setPickHubCode(value);
    };
    const onChangehandleHubList = (code: string) => {
        const selectedHub = pickupHubs.find((hub) => hub.code === code);
        if (selectedHub) {
            setSelectedHubId(selectedHub.id);
        }
    };
    const renderPickupHubCode = () => {
        return (
            <div style={{ fontSize: 10, width: '60%' }}>
                <span>{pickupHubCode.label}</span>
                <Form.Item
                    name={pickupHubCode.key}
                    className={classes.contentType}
                    valuePropName={pickupHubCode.valuePropName}
                    rules={isLTLCustomer && !autoAllocation ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={pickupHubCode.placeholder}
                        style={{ width: pickupHubCode.width }}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        options={(pickupHubs || []).map((hub) => ({
                            value: hub.code,
                            label: hub.name,
                        }))}
                        onSearch={handleFetchHubList}
                        onChange={(code) => { onChangehandleHubList(code); }}
                        disabled={autoAllocation}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                    />
                </Form.Item>
            </div>
        );
    };
    const onChangeHub = (value: any) => {
        setAutoAllocation(value.target.checked);
    };
    const renderHubCheckBox = () => {
        return (
            <div className={classes.formItem}>
                <Form.Item
                    name={autoAllocateHub.label}
                    className={classes.contentType}
                    initialValue={autoAllocateHub.defaultValue}
                    dependencies={[autoAllocateHub.key]}
                >
                    <Checkbox style={{ fontSize: 10, width: '200px' }} onChange={onChangeHub}>
                        {t(autoAllocateHub.label)}
                    </Checkbox>
                </Form.Item>
            </div>
        );
    };
    const renderHubDetails = () => {
        return (
            <div style={{ display: 'flex' }}>
                {renderHubCheckBox()}
                {renderPickupHubCode()}
            </div>
        );
    };
    const renderHubContent = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, 'Hub Details')}
                {renderHubDetails()}
            </div>
        );
    };
    const renderUnit = (destination: DestinationDetails) => {
        const optionList = allowedUnits.map((item: any) => {
            return {
                value: item,
                label: item,
            };
        });
        return (
            <div className={classes.field}>
                <div className={classes.title}>
                    Units
                </div>
                <Form.Item
                    name={undefined}
                    className={classes.contentType}
                    valuePropName={ContentTypes.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Select
                        placeholder="Units"
                        style={{ width: ContentTypes.width }}
                        allowClear
                        options={optionList}
                        onChange={
                            (e) => onChangeDestinationDetails(destination.key, 'units', e)
                        }
                    />
                </Form.Item>
            </div>
        );
    };

    const renderCity = (destination: DestinationDetails) => {
        return (
            <div className={classes.field}>
                <div className={classes.title}>
                    City
                </div>
                <Form.Item
                    className={classes.contentType}
                    initialValue={destination.city}
                    rules={[formRuleRequired]}
                >
                    <Input
                        value={destination.city}
                        style={{ width: 150 }}
                        placeholder="City"
                        onChange={
                            (e) => onChangeDestinationDetails(destination.key, 'city', e.target.value)
                        }
                    />
                </Form.Item>
            </div>
        );
    };

    const renderRemoveDestination = (id: any) => {
        return (
            <Button style={{ marginTop: 8 }} danger onClick={() => removeDestination(id)}>Delete</Button>
        );
    };

    const renderDestinationDetails = () => {
        if (destinationDetails.length < 1) addDestination();
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, t('destination_details'))}
                <div className={classes.boxFields}>
                    { destinationDetails.map((destination: any) => {
                        return (
                            <div key={destination.key}>
                                <div className={classes.flexRow}>
                                    {renderCity(destination)}
                                    { destinationDetails.length > 1 ? renderRemoveDestination(destination.key) : null }
                                </div>
                                <div className={classes.flexRow}>
                                    {renderUnit(destination)}
                                    {renderNumPeices(destination)}
                                </div>
                            </div>
                        );
                    })}
                    <Button type="primary" onClick={() => addDestination()}>Add More</Button>
                </div>
            </div>
        );
    };

    const renderAddressSearch = () => {
        return (
            <div className={classes.searchAddress}>
                <span className={classes.searchText}>
                    {t('select_pickup_address')}
                    *
                </span>
                <div>
                    {
                        // eslint-disable-next-line no-underscore-dangle
                        defaultAddress?._id
                            ? (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        const item: PickupAddress | undefined = addressList.find((add) => {
                                            // eslint-disable-next-line no-underscore-dangle
                                            return add.id === defaultAddress?._id;
                                        });
                                        if (item) setSelected(item);
                                    }}
                                >
                                    {t('select_default_address')}
                                </Button>
                            ) : null
                    }
                    <Input
                        placeholder={t('search')}
                        type="search"
                        onChange={(e) => setSearchString(e.target.value)}
                        style={{ width: 200 }}
                    />
                </div>
            </div>
        );
    };

    const renderAddressDetails = (item: PickupAddress) => {
        return (
            <div className={classes.addressDetails}>
                <div style={{ width: '25%', marginRight: '10%' }}>
                    {`${item.addressLine1}, ${item.addressLine2 || ''}`}
                </div>
                <div style={{ width: '25%', marginRight: '10%' }}>
                    {`${item.pincode} - ${item.cityName || ''} ${item.stateName || ''}, ${item.countryName || ''}`}
                </div>
                <div style={{ width: '15%' }}>
                    {`${item.phone || ''} ${item.alternatePhone || ''}`}
                </div>
            </div>
        );
    };

    const renderAddressLine = (item: PickupAddress) => {
        let className = classes.addressLine;

        if (selectedAddress?.id === item.id) {
            className = [classes.addressLineSelected, classes.addressLine].join(' ');
        }
        return (
            <div
                key={item.id}
                onClick={() => {
                    form.setFieldsValue({ [AddressLine.key]: item });
                    if (selectedAddress?.id === item.id) {
                        setSelected(undefined);
                    } else {
                        setSelected(item);
                    }
                }}
                className={className}
            >
                {renderCheckbox(item)}
                {renderAddressDetails(item)}
            </div>
        );
    };

    const renderAddressList = () => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <Form.Item
                key={AddressLine.key}
                name={AddressLine.key}
                className={classes.addressList}
            >
                <div className={classes.addressList}>
                    {addressList.map((item) => renderAddressLine(item))}
                </div>
            </Form.Item>
        );
    };

    const renderExisting = () => {
        return (
            <>
                {renderAddressSearch()}
                {renderLine()}
                {renderAddressList()}
            </>
        );
    };

    const renderAddressFormUtil = () => {
        if (addressType === 'createnew') {
            return (
                <CreateAddress
                    page="Pickup"
                    editData={editAddress}
                    onClose={() => {
                        setAddressType('saved');
                        form.setFieldsValue({
                            [AddressOption.key]: 'saved',
                        });
                    }}
                    phoneRegex={phoneRegex}
                    isInternationalAddress={false}
                />
            );
        }
        return renderExisting();
    };

    const renderAddressForm = () => {
        return (
            <div className={classes.boxFields}>
                {renderFormItem(AddressOption)}
                {renderAddressFormUtil()}
            </div>
        );
    };

    const renderAddress = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(AddressIcon, t('address_details'))}
                {renderAddressForm()}
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="70%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            <Form form={form}>
                {renderSchedule()}
                {renderContent()}
                {isLTLCustomer ? renderHubContent() : null}
                {showDestinationDetailsInPickup ? renderDestinationDetails() : null}
                {renderAddress()}
            </Form>
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    return {
        uiTheme: state.uiTheme,
        loadTypeOptions: master.config.load_type_options,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(createPickupStyles)(GenericHoc(hocConfig)(CreatePickup));
