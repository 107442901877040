import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Input,
    message,
    Row,
    Tag,
    Typography,
} from 'antd';
import * as React from 'react';
import Loader from '../common/Loader';
import { OtherBuckets, Service } from '../../library/Types';
import { getBusinessTat, getServiceabilityData } from '../../network/common.api';
import { StylesProps } from '../../theme/jss-types';
import { serviceAvailabilityStyles } from './service-availability.styles';
import { useTranslation } from 'react-i18next';
import { NAVBAR_HEIGHT } from 'library/globals';
import CrossIcon from '../../assets/cross';
import {
    ArrowsAltOutlined,
    CheckCircleFilled,
    EnvironmentFilled,
    InfoCircleFilled,
} from '@ant-design/icons';
import { getPincodeServiceabilityRoute } from 'routing/routing-helper';
import { bindActionCreators } from 'redux';
import GenericHoc from 'components/common/generic-hoc';
import { ReduxStore } from 'reducers/redux.types';
import { HocOptions } from 'components/common/generic-hoc.types';
import { getCityState } from 'network/consignments.api';
import { storeExpandingState } from 'actions/miscellaneous-actions';
import ContentFilledIcon from 'assets/contents-filled';
import PincodeCard from './pincode-details-card';

const { Paragraph } = Typography;

interface PincodeServiceProps extends StylesProps<ReturnType<typeof serviceAvailabilityStyles>> {
    onClose: () => void;
    shouldRenderInDrawer?: boolean;
    expandState: any;
    setStateForExpandAction: (data: any) => void;
    history: any,
}

const PincodeService = (props: PincodeServiceProps) => {
    const {
        classes,
        onClose,
        expandState,
        setStateForExpandAction,
        shouldRenderInDrawer,
        history,
    } = props;
    const { t } = useTranslation();
    const [showSingle, setShowSingle] = React.useState<boolean>(expandState?.showSingle ?? false);
    const [serviceabilityData, setServiceabilityData] = React.useState<any>(
        expandState?.apiResponse?.serviceabilityData || {},
    );
    const [services, setService] = React.useState<Service[]>(
        expandState?.apiResponse?.services || [],
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(true);

    const [pincodeData, setPincodeData] = React.useState<Record<string, any>>(expandState?.pincodeData || {
        src: {},
        dst: {},
        single: {},
    });

    const saveCityState = async (type: string, pincode: string) => {
        const response = await getCityState({
            pincode,
            isLTL: false,
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
            setPincodeData((current) => {
                return {
                    ...current,
                    [type]: {
                        ...current[type],
                        pincode,
                        valid: false,
                        city: '',
                        state: '',
                        country: '',
                    },
                };
            });
            return;
        }
        setPincodeData((current) => {
            return {
                ...current,
                [type]: {
                    ...current[type],
                    pincode,
                    valid: true,
                    city: response?.data?.city,
                    state: response?.data?.state,
                    country: response?.data?.country || 'INDIA',
                },
            };
        });
    };

    const handlePincodeChange = (e: any, type: string) => {
        const pincode = e?.target?.value;

        if (pincode?.length === 6) {
            saveCityState(type, pincode);
        }
        setPincodeData({
            ...pincodeData,
            [type]: {
                ...pincodeData[type],
                pincode,
                valid: false,
                city: '',
                state: '',
                country: '',
            },
        });
    };

    const loadBusinessTat = async () => {
        setLoading(true);
        const storage = window.localStorage;
        const response = await getBusinessTat({
            srcPincode: pincodeData?.src?.pincode,
            dstPincode: pincodeData?.dst?.pincode,
            source: 'CUSTOMER_PORTAL_SINGLE_V2',
            clientCode: storage.getItem('userCode'),
        });
        setService(response?.data || []);
        setLoading(false);
    };

    const loadBusinessServiceabilityData = async () => {
        setLoading(true);
        const response = await getServiceabilityData({
            srcPincode: pincodeData?.single?.pincode,
        });
        setServiceabilityData(response?.data?.srcServiceability);
        setLoading(false);
    };

    React.useEffect(() => {
        if (showSingle) {
            if (!pincodeData?.single?.valid) {
                return;
            }
            loadBusinessServiceabilityData();
        } else {
            if (
                !pincodeData?.src?.valid || !pincodeData?.dst?.valid
            ) {
                return;
            }
            loadBusinessTat();
        }
    }, [toggle]);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    {shouldRenderInDrawer && (
                        <CrossIcon onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    )}
                    <span>
                        {t('pincode_serviceability')}
                    </span>
                    {
                        shouldRenderInDrawer && (
                            <ArrowsAltOutlined
                                onClick={() => {
                                    setStateForExpandAction({
                                        pincodeData,
                                        showSingle,
                                        apiResponse: {
                                            services,
                                            serviceabilityData,
                                        },
                                        route: getPincodeServiceabilityRoute(),
                                    });
                                    onClose();
                                    history.push(getPincodeServiceabilityRoute());
                                }}
                            />
                        )
                    }
                </div>
            </div>
        );
    };

    const disableButton = () => {
        if (showSingle) {
            return !pincodeData?.single?.valid;
        }
        return !pincodeData?.dst?.valid || !pincodeData?.src?.valid;
    };


    const renderPincodeLabel = (type: string, title: string) => {
        return (
            <span className={classes.name}>
                {type === 'src' ? (
                    <span style={{
                        backgroundColor: '#F0ECFD',
                        width: 20,
                        height: 20,
                        marginRight: 5,
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <ContentFilledIcon style={{
                            color: '#6A3DF5',
                            backgroundColor: 'transparent',
                        }}
                        />
                    </span>
                ) : (
                    <span style={{
                        backgroundColor: '#EDF8F1',
                        width: 20,
                        height: 20,
                        marginRight: 5,
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <EnvironmentFilled style={{
                            color: '#5CAF7E',
                        }}
                        />
                    </span>
                )}
                {title}
                *
            </span>
        );
    };

    const renderValidation = (type: string) => {
        if (!pincodeData[type]?.valid) {
            return null;
        }

        return (
            <div className={classes.pincodeValidation}>
                <span style={{ marginRight: 5 }}>
                    {pincodeData[type]?.city}
                </span>
                <CheckCircleFilled />
            </div>
        );
    };


    const renderPincode = (type: string, title: string) => {
        return (
            <div className={classes.singleField}>
                {renderPincodeLabel(type, title)}
                <Input
                    style={{
                        boxSizing: 'border-box',
                        height: 32,
                        border: '1px solid #999999',
                        borderRadius: 4,
                    }}
                    className={classes.pincodeInput}
                    value={pincodeData[type]?.pincode}
                    placeholder={title}
                    suffix={renderValidation(type)}
                    onChange={(e) => handlePincodeChange(e, type)}
                />
            </div>
        );
    };


    const renderPincodeInputs = () => {
        if (showSingle) return null;
        return (
            <div className={classes.fields}>
                {renderPincode('src', t('origin_pincode'))}
                {renderPincode('dst', t('destination_pincode'))}
            </div>
        );
    };

    const renderSubmitButton = () => {
        const disable = disableButton();
        return (
            <Button
                className={classes.submitButton}
                type="primary"
                disabled={disable}
                onClick={() => setToggle(!toggle)}
            >
                {t('check')}
            </Button>
        );
    };

    const renderPincodeInput = () => {
        if (!showSingle) return null;
        return (
            <div className={classes.inputs}>
                {renderPincode('single', 'Origin/Destination Pincode')}
            </div>
        );
    };

    const renderPincodePair = (config: any) => {
        return (
            <div className={classes.nameValuePair}>
                <span className={classes.serviceName}>{config.name}</span>
                <span className={classes.value}>{config.value}</span>
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const renderServices = () => {
        if (loading) {
            return <Loader zIndex={5} />;
        }
        if (showSingle) return null;
        if (!services.length) {
            return null;
        }
        return (
            <div className={classes.services}>
                <div className={classes.availableText}>
                    Available Services
                </div>
                {services.map((service) => {
                    return renderPincodePair({
                        name: `${service.serviceType} (${service.serviceTypeId})`,
                        value: service.period,
                    });
                })}
            </div>
        );
    };

    const renderHeaderPageView = () => {
        if (shouldRenderInDrawer) return null;
        return (
            <>
                <div className={classes.heading}>
                    <h2 className={classes.headingTitle}>
                        {t('pincode_serviceability')}
                    </h2>
                </div>
            </>
        );
    };

    const renderSubHeading = () => {
        return (
            <div className={classes.subHeading}>
                Enter the pin code to check the serviceability of your shipping address.
            </div>
        );
    };

    const renderCheckbox = () => {
        return (
            <div className={classes.checkboxGroup}>
                <Checkbox
                    className={classes.checkbox}
                    checked={!showSingle}
                    onChange={() => setShowSingle(!showSingle)}
                />
                Check services available between two pincodes
            </div>
        );
    };

    const renderBetweenPincodes = () => {
        if (!showSingle) return null;
        return (
            <Paragraph>
                <pre className={classes.singleInfoDesc}>
                    <div className={classes.singleInfoDescIcon}>
                        <InfoCircleFilled />
                    </div>
                    <div className={classes.singleInfoDescText}>
                        A set of origin and destination pincodes can be used to check the available services.
                    </div>
                    <Button className={classes.singleInfoCheck} type="link" onClick={() => setShowSingle(false)}>
                        {t('check')}
                    </Button>
                </pre>
            </Paragraph>
        );
    };

    const renderPincodeFlags = () => {
        if (!showSingle) return null;
        const allFlags: JSX.Element[] = [];
        if (serviceabilityData?.isServiceableDelivery) {
            allFlags.push(<Tag>Delivery</Tag>);
        }
        if (serviceabilityData?.isServiceablePickup) {
            allFlags.push(<Tag>Pickup</Tag>);
        }
        if (serviceabilityData?.isLiteServiceable) {
            allFlags.push(<Tag>Lite</Tag>);
        }
        if (serviceabilityData?.isPEPServiceable) {
            allFlags.push(<Tag>PEP</Tag>);
        }
        if (serviceabilityData?.isPTPServiceable) {
            allFlags.push(<Tag>PTP</Tag>);
        }
        if (serviceabilityData?.isCodServiceable) {
            allFlags.push(<Tag>COD</Tag>);
        }
        if (serviceabilityData?.isForwardPickupServiceable) {
            allFlags.push(<Tag>Forward</Tag>);
        }
        if (serviceabilityData?.isReversePickupServiceable) {
            allFlags.push(<Tag>Reverse</Tag>);
        }
        return (
            <div className={classes.tags}>
                {allFlags}
            </div>
        );
    };

    const renderUiComponents = () => (
        <>
            {renderHeaderPageView()}
            {renderSubHeading()}
            <Row className={classes.rowView}>
                <Col span={shouldRenderInDrawer ? 24 : 12} className={classes.colView}>
                    <div className={classes.colData}>
                        {renderPincodeInputs()}
                        {renderPincodeInput()}
                        {renderCheckbox()}
                        {renderSubmitButton()}
                    </div>
                </Col>
                { (showSingle && pincodeData?.single?.valid)
                    || (!showSingle && (pincodeData?.dst?.valid || pincodeData?.src?.valid))
                    ? (
                        <Col span={shouldRenderInDrawer ? 24 : 12} className={classes.colView}>
                            <div className={classes.colData}>
                                <div style={{
                                    padding: 24,
                                }}
                                >
                                    <PincodeCard
                                        data={
                                            (showSingle
                                                ? {
                                                    src: pincodeData?.single,
                                                } : {
                                                    src: pincodeData?.src,
                                                    dst: pincodeData?.dst,
                                                }) as Record<string, any>
                                        }
                                        srcHeader={showSingle ? t('Origin/Destination') : t('Origin')}
                                        dstHeader={t('Destination')}
                                    />
                                </div>
                                {renderLine()}
                                {renderServices()}
                                {renderPincodeFlags()}
                                {renderBetweenPincodes()}
                            </div>
                        </Col>
                    ) : null}
            </Row>
        </>
    );

    return shouldRenderInDrawer ? (
        <Drawer
            visible
            width="45%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderUiComponents()}
        </Drawer>
    ) : (
        <div
            className={classes.main}
            style={{
                height: `calc(100vh - ${NAVBAR_HEIGHT}px - 15px)`,
                margin: 5,
                backgroundColor: '#ffffff',
            }}
        >
            {renderUiComponents()}
        </div>
    );
};


const mapStateToProps = (state: ReduxStore) => {
    const { miscellaneousState } = state;
    const { [OtherBuckets.EXPAND_STATE]: expandState } = miscellaneousState || {};
    return {
        expandState,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setStateForExpandAction: storeExpandingState,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: serviceAvailabilityStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(PincodeService);
