import * as React from 'react';
import { rateCalculatorStyles } from './rate-calculator.styles';
import { StylesProps } from 'theme/jss-types';
import CrossIcon from 'assets/cross';
import { find, sortBy } from 'lodash';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import {
    ArrowsAltOutlined,
    CheckCircleFilled,
    EnvironmentFilled,
    InfoCircleOutlined,
} from '@ant-design/icons';
import {
    Button, Col, Drawer, Input, Radio, Row, Select, Tooltip, message,
} from 'antd';
import { getCityState } from 'network/consignments.api';
import Helper from 'library/Helper';
import {
    getBusinessTat,
    getCities,
    getCountries,
    getInternationalBusinessTatV2,
    getStates,
} from 'network/common.api';
import {
    City, Country, OtherBuckets, Service, State,
} from 'library/Types';
import Loader from 'components/common/Loader';
import { ReduxStore } from 'reducers/redux.types';
import { bindActionCreators } from 'redux';
import { getRateCalculatorRoute } from 'routing/routing-helper';
import { NAVBAR_HEIGHT } from 'library/globals';
import { storeExpandingState } from 'actions/miscellaneous-actions';
import PincodeCard from './pincode-details-card';
import ContentFilledIcon from 'assets/contents-filled';
import AirPlaneIcon from 'assets/airplane-icon';
import TruckIcon from 'assets/truck-icon';
import { formFields } from 'components/create-consignment/create-modal.constants';
import LTLIcon from 'assets/ltl-icon';

const { PayBasis } = formFields;

interface RateCalculatorFormData {
    weight: string | undefined;
    unit: 'cm' | 'ft' | 'mtr' | 'inch',
    height: string | undefined;
    width: string | undefined;
    length: string | undefined;
    weightUnit: 'gm' | 'kg';
    payBasis: string;
}

interface RateCalculatorProps extends StylesProps<ReturnType<typeof rateCalculatorStyles>> {
    onClose: () => void;
    shouldRenderInDrawer?: boolean;
    expandState: any;
    setStateForExpandAction: (data: any) => void;
    history: any,
    isLTLCustomer: boolean;
}

const RateCalculator = (props: RateCalculatorProps) => {
    const {
        classes,
        onClose,
        expandState,
        setStateForExpandAction,
        shouldRenderInDrawer,
        history,
        isLTLCustomer,
    } = props;

    const [rateList, setRateList] = React.useState<Service[]>(expandState?.apiResponse || []);
    const [fetching, setFetching] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<RateCalculatorFormData>(
        (expandState?.formData || {
            unit: 'cm',
            weightUnit: 'kg',
            payBasis: 'PAID',
        }) as RateCalculatorFormData,
    );
    const [pincodeData, setPincodeData] = React.useState<Record<string, any>>(expandState?.pincodeData || {
        src: {},
        dst: {},
    });
    const [category, setCategory] = React.useState<string>(expandState?.category || 'domestic');
    const [loadtype, setLoadtype] = React.useState<string>(expandState?.loadType || 'NON-DOCUMENT');
    // const [numpieces, setNumPieces] = React.useState<string | undefined>();
    const [countryList, setCountryList] = React.useState<Country[]>([]);
    const [stateList, setStateList] = React.useState<State[]>([]);
    const [cityList, setCityList] = React.useState<City[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchCountries = async () => {
        const result = await getCountries();
        setCountryList(result?.data || []);
    };

    const fetchStateList = async () => {
        if (!pincodeData.dst?.country) {
            return;
        }
        const result = await getStates({
            stateName: '',
            countryName: pincodeData.dst?.country,
        });
        setStateList(result?.data || []);
    };

    let timeout: any = null;
    const fetchCityList = async (cityName: string) => {
        if (!pincodeData.dst?.country || cityName?.length < 2) {
            return;
        }
        setLoading(true);

        if (timeout) {
            timeout = null;
        }
        timeout = setTimeout(async () => {
            const result = await getCities({
                cityName,
                countryName: pincodeData.dst?.country,
            });
            setCityList(result?.data || []);
            setLoading(false);
        }, 500);
    };

    React.useEffect(() => {
        fetchCountries();
    }, []);

    React.useEffect(() => {
        fetchStateList();
    }, [pincodeData.dst?.country]);

    const saveCityState = async (type: string, pincode: string) => {
        const response = await getCityState({
            pincode,
            isLTL: isLTLCustomer || false,
        });

        if (!response.isSuccess) {
            message.error(response.errorMessage);
            return;
        }
        setPincodeData({
            ...pincodeData,
            [type]: {
                ...pincodeData[type],
                pincode,
                valid: true,
                city: response?.data?.city,
                state: response?.data?.state,
                country: response?.data?.country || 'INDIA',
            },
        });
    };

    const getButtonName = () => {
        if (rateList?.length) {
            return 'Edit Details';
        }

        return 'Calculate Rate';
    };

    const getApiBody = () => {
        const storage = window.localStorage;

        const hei = Helper.converttoCentimeter(Number(formData.height), formData.unit);
        const len = Helper.converttoCentimeter(Number(formData.length), formData.unit);
        const wid = Helper.converttoCentimeter(Number(formData.width), formData.unit);
        const wei = Helper.converttoKilogram(Number(formData.weight), formData.weightUnit);

        return {
            weight: wei,
            height: hei,
            width: wid,
            length: len,
            srcPincode: pincodeData.src?.pincode,
            srcStateName: pincodeData.src?.state,
            dstStateName: pincodeData.dst?.state,
            dstPincode: pincodeData.dst?.pincode,
            countryName: pincodeData.dst?.country,
            dstCountry: pincodeData.dst?.country,
            dstCity: pincodeData.dst?.city,
            srcCity: pincodeData.src?.city,
            clientCode: storage.getItem('userCode'),
            courierType: loadtype,
            commodityCode: category === 'international' ? 'ICOM9999' : undefined,
            is_international: category === 'international',
            pieceDetails: [{
                weight: wei,
                height: hei,
                width: wid,
                length: len,
            }],
            calculateRate: true,
            source: 'CUSTOMER_PORTAL_SINGLE_V2',
            payBasis: category === 'ltl' ? formData.payBasis : undefined,
        };
    };


    const fetchRates = async () => {
        if (rateList?.length) {
            setRateList([]);
            return;
        }
        setFetching(true);
        const apiBody = getApiBody();

        if (category === 'domestic' || category === 'ltl') {
            const response = await getBusinessTat(apiBody);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
                return;
            }
            const rateListSorted = sortBy(response?.data || [], ['serviceType']);
            setRateList(rateListSorted);
        } else {
            const response = await getInternationalBusinessTatV2(apiBody);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
                return;
            }
            setRateList(response?.data || []);
        }

        setFetching(false);
    };

    const getDisabled = () => {
        const apiBody: any = getApiBody();
        if (rateList?.length) {
            return false;
        }

        const requiredFields = ['srcPincode', 'dstPincode', 'weight'];

        if (category === 'international') {
            requiredFields.push('dstCountry');
            requiredFields.push('dstCity');
            requiredFields.push('srcCity');
        }

        const error = requiredFields.some((item: string) => !apiBody[item]);

        if (!pincodeData.src?.valid || !pincodeData.dst?.valid) {
            return true;
        }
        return !!error;
    };

    const getButtonProps = () => {
        const disabled = getDisabled();

        if (rateList?.length) {
            return {
                type: 'default',
                styles: {
                    color: '#082E78',
                    margin: '0 20px',
                },
            };
        }
        return {
            type: disabled ? 'default' : 'primary',
            styles: {
                margin: '0 20px',
                background: disabled ? 'default' : '#082E78',
            },
        };
    };

    const renderCalculateButton = () => {
        const buttonProps = getButtonProps();
        return (
            <Button
                loading={fetching}
                disabled={getDisabled()}
                type={buttonProps.type as any}
                style={buttonProps.styles}
                onClick={() => fetchRates()}
            >
                {getButtonName()}
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    {shouldRenderInDrawer && (
                        <CrossIcon onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    )}
                    <span>
                        Rate Calculator
                    </span>
                    {
                        shouldRenderInDrawer && (
                            <ArrowsAltOutlined
                                onClick={() => {
                                    setStateForExpandAction({
                                        formData,
                                        pincodeData,
                                        category,
                                        loadType: loadtype,
                                        apiResponse: rateList,
                                        route: getRateCalculatorRoute(),
                                    });
                                    onClose();
                                    history.push(getRateCalculatorRoute());
                                }}
                            />
                        )
                    }
                </div>
            </div>
        );
    };



    const renderLine = () => {
        return <div className={classes.line} />;
    };

    const fieldMapping: Record<string, string> = {
        src: 'Origin Pincode',
        dst: 'Destination Pincode',
    };

    const renderValidation = (type: string) => {
        if (!pincodeData[type]?.valid) {
            return null;
        }

        return (
            <div className={classes.pincodeValidation}>
                <span style={{ marginRight: 5 }}>
                    {pincodeData[type]?.city}
                </span>
                <CheckCircleFilled />
            </div>
        );
    };

    const handlePincodeChange = (e: any, type: string) => {
        const pincode = e?.target?.value;

        setPincodeData({
            ...pincodeData,
            [type]: {
                pincode,
                valid: false,
            },
        });

        let isValid = false;
        if (category !== 'international' || type === 'src') {
            if (pincode?.length === 6) {
                saveCityState(type, pincode);
            }
        } else if (pincodeData.dst?.country) {
            const country = find(countryList, { country: pincodeData.dst?.country });

            if (country?.pincode_regex) {
                const regex = new RegExp(country?.pincode_regex.replace(/^\//, '')?.replace(/\/$/, ''));
                if (regex.test(pincode)) {
                    isValid = true;
                }
            }
        }

        setPincodeData({
            ...pincodeData,
            [type]: {
                ...pincodeData[type],
                pincode,
                valid: isValid,
            },
        });
    };

    const renderPincodeLabel = (type: string) => {
        return (
            <span className={classes.name}>
                {type === 'src' ? (
                    <span style={{
                        backgroundColor: '#F0ECFD',
                        width: 20,
                        height: 20,
                        marginRight: 5,
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <ContentFilledIcon style={{
                            color: '#6A3DF5',
                            backgroundColor: 'transparent',
                        }}
                        />
                    </span>
                ) : (
                    <span style={{
                        backgroundColor: '#EDF8F1',
                        width: 20,
                        height: 20,
                        marginRight: 5,
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <EnvironmentFilled style={{
                            color: '#5CAF7E',
                        }}
                        />
                    </span>
                )}
                {fieldMapping[type]}
                *
            </span>
        );
    };

    const renderPincode = (type: string) => {
        return (
            <div className={classes.singleField}>
                {renderPincodeLabel(type)}
                <Input
                    style={{
                        boxSizing: 'border-box',
                        height: 32,
                        border: '1px solid #999999',
                        borderRadius: 4,
                    }}
                    autoFocus
                    disabled={rateList?.length > 0}
                    className={classes.pincodeInput}
                    value={pincodeData[type]?.pincode}
                    placeholder={fieldMapping[type]}
                    suffix={renderValidation(type)}
                    onChange={(e) => handlePincodeChange(e, type)}
                />
            </div>
        );
    };

    const renderDots = () => {
        return (
            <div className={classes.dots} />
        );
    };

    const renderPincodes = () => {
        return (
            <div className={classes.fields}>
                {renderPincode('src')}
                {renderDots()}
                {renderPincode('dst')}
            </div>
        );
    };

    const renderPayBasis = () => {
        if (category !== 'ltl') return null;
        return (
            <div className={classes.fieldsPayBasis}>
                <div className={classes.unitField}>
                    <span className={classes.name}>Pay Basis</span>
                    <Select
                        className={classes.weight}
                        disabled={rateList?.length > 0}
                        placeholder="Pay Basis"
                        options={PayBasis.options}
                        value={formData.payBasis}
                        onChange={(val: string) => setFormData({
                            ...formData,
                            payBasis: val,
                        })}
                    />
                </div>
            </div>
        );
    };

    const options = [{
        label: 'Domestic',
        value: 'domestic',
        icon: <TruckIcon />,
    },
    {
        label: 'International',
        value: 'international',
        icon: <AirPlaneIcon />,
    }, {
        label: 'LTL',
        value: 'ltl',
        icon: <LTLIcon />,
    }];

    const renderCategory = () => {
        return (
            <div className={classes.fields}>
                <div className={classes.radioGroup}>
                    <Radio.Group
                        buttonStyle="solid"
                        disabled={rateList?.length > 0}
                        value={category}
                        onChange={(e) => {
                            const value = e?.target?.value;
                            setCategory(value);
                            if (value !== 'international') {
                                setPincodeData({
                                    ...pincodeData,
                                    dst: null,
                                });
                            }
                        }}
                    >
                        {options.map((item) => {
                            return (
                                <Radio.Button
                                    value={item.value}
                                    style={{
                                        width: '33%',
                                        textAlign: 'center',
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <span style={{ margin: '0 5px' }}>
                                        {item.icon}
                                    </span>
                                    {item.label}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                </div>
            </div>
        );
    };

    // const renderNumPieces = () => {
    //     if (loadtype === 'DOCUMENT') {
    //         return null;
    //     }
    //     return (
    //         <div className={classes.numPiecesField}>
    //             <span className={classes.name}>No. of Pieces *</span>
    //             <Input
    //                 className={classes.numPieces}
    //                 type="number"
    //                 disabled={rateList?.length > 0}
    //                 value={numpieces}
    //                 placeholder="No of pieces"
    //                 onChange={(e) => {
    //                     const value = Number(e?.target?.value);
    //                     if (value < 0) {
    //                         message.error('No of pieces should be a positive number');
    //                         return;
    //                     }
    //                     setNumPieces(e?.target?.value);
    //                 }}
    //             />
    //         </div>
    //     );
    // };

    const loadOptions = [{
        label: 'Document',
        value: 'DOCUMENT',
    },
    {
        label: 'Non Document',
        value: 'NON-DOCUMENT',
    }];

    const renderLoadType = () => {
        return (
            <div className={classes.fields}>
                <div>
                    <span className={classes.name}>Item Type</span>
                    <Radio.Group
                        value={loadtype}
                        disabled={rateList?.length > 0}
                        options={loadOptions}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        onChange={(e) => setLoadtype(e?.target?.value)}
                    />
                </div>
                {/* {renderNumPieces()} */}
            </div>
        );
    };

    const renderWeightUnit = () => {
        return (
            <Select
                disabled={rateList?.length > 0}
                value={formData.weightUnit}
                autoFocus={false}
                placeholder="Weight Unit"
                onChange={(val: string) => setFormData({
                    ...formData,
                    weightUnit: val as any,
                })}
            >
                <Select.Option value="gm">gm</Select.Option>
                <Select.Option value="kg">Kg</Select.Option>
            </Select>
        );
    };

    const renderWeight = () => {
        return (
            <div
                className={classes.dimentionField}
                style={{
                    width: '30%',
                }}
            >
                <span className={classes.name}>Weight *</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={formData.weight}
                    placeholder="Weight"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Weight should be a positive number');
                            return;
                        }
                        setFormData({
                            ...formData,
                            weight: e?.target?.value,
                        });
                    }}
                    addonAfter={renderWeightUnit()}
                />
            </div>
        );
    };

    const renderLength = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.dimentionField}>
                <span className={classes.name}>Length</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={formData.length}
                    placeholder="Length"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Length should be a positive number');
                            return;
                        }
                        setFormData({
                            ...formData,
                            length: e?.target?.value,
                        });
                    }}
                />
            </div>
        );
    };

    const renderHeight = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.dimentionField}>
                <span className={classes.name}>Height</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={formData.height}
                    placeholder="Height"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Height should be a positive number');
                            return;
                        }
                        setFormData({
                            ...formData,
                            height: e?.target?.value,
                        });
                    }}
                />
            </div>
        );
    };

    const renderWidth = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.dimentionField}>
                <span className={classes.name}>Width</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={formData.width}
                    placeholder="Width"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Width should be a positive number');
                            return;
                        }
                        setFormData({
                            ...formData,
                            width: e?.target?.value,
                        });
                    }}
                />
            </div>
        );
    };

    const renderUnit = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.unitField}>
                <span className={classes.name}>Unit</span>
                <Select
                    className={classes.weight}
                    disabled={rateList?.length > 0}
                    value={formData.unit}
                    placeholder="Dimension Unit"
                    onChange={(val: string) => setFormData({
                        ...formData,
                        unit: val as any,
                    })}
                >
                    <Select.Option value="cm">cm</Select.Option>
                    <Select.Option value="inch">Inch</Select.Option>
                    <Select.Option value="mtr">Mtr</Select.Option>
                    <Select.Option value="ft">Ft</Select.Option>
                </Select>
            </div>
        );
    };

    const renderDimestions = () => {
        return (
            <div className={classes.fields}>
                {renderWeight()}
                {renderLength()}
                {renderHeight()}
                {renderWidth()}
                {renderUnit()}
            </div>
        );
    };

    const renderMessage = () => {
        return (
            <div className={classes.rateMsg}>
                Below are the offered services and their respective rates.
                { category === 'ltl' ? 'The rates are as per "Owner risk", "Carrier risk" rates will be different.' : ''}
            </div>
        );
    };

    const renderServiceName = (serviceName: string) => {
        return (
            <div className={classes.serviceName}>{serviceName}</div>
        );
    };

    const renderRateComponent = (title: string, value: string) => {
        if (!value) return null;
        return (
            <div className={classes.rateComponent}>
                <span className={classes.rateComponentTitle}>
                    {title}
                    &nbsp;:
                </span>
                <span className={classes.rateComponentValue}>{value}</span>
            </div>
        );
    };
    const renderPrice = (rate?: any) => {
        const charges = rate?.domesticRateComponents?.charges?.[0] || {};
        let misCharges = 0;
        if (category === 'ltl') {
            misCharges = (Number(charges?.MISC_DOCKETCHARGE || 0)
            + Number(charges?.MISC_WARAICHARGE || 0)
            + Number(charges?.MISC_ESSCHARGES || 0)
            + Number(charges?.KFC || 0)
            + Number(charges?.MISC_HIGHWEIGHT || 0)
            + Number(charges?.MISC_KERALADEST || 0)
            + Number(charges?.MISC_HILLSURCH || 0));
        }
        const rateMsg = (
            <>
                <div className={classes.rateBreakUp}>
                    Price Breakup
                </div>
                {category === 'international' ? (
                    <>
                        {renderRateComponent('Base Rate', rate?.intlRateComponents?.baseRate)}
                        {renderRateComponent('Fuel Surcharge', rate?.intlRateComponents?.fscAmount)}
                        {renderRateComponent('Covid Excess Surcharge', rate?.intlRateComponents?.cesChargeAmount)}
                        {renderRateComponent('Other Charges', rate?.intlRateComponents?.otherCharges)}
                        {rate?.intlRateComponents?.taxComponent?.map((tax: any) => (
                            renderRateComponent(tax?.taxType, tax?.taxAmount)
                        ))}
                    </>
                ) : null}
                {category === 'domestic' ? (
                    <>
                        {renderRateComponent('Base Amount', rate?.domesticRateComponents?.tsAmout)}
                        {renderRateComponent('Service Charge', rate?.domesticRateComponents?.serviceCharge)}
                        {renderRateComponent('Risk Surcharge', rate?.domesticRateComponents?.riskSurcharge)}
                        {renderRateComponent('Fuel Surcharge', rate?.domesticRateComponents?.fscCharge)}
                        {renderRateComponent('PDN charges', rate?.domesticRateComponents?.pdnCharges)}
                        {renderRateComponent('Freedom Amount', rate?.domesticRateComponents?.freedomTotalAmount)}
                        {renderRateComponent('Covid Excess Surcharge', rate?.domesticRateComponents?.cesCharges)}
                        {rate?.domesticRateComponents?.taxComponentList?.map((tax: any) => (
                            renderRateComponent(tax?.taxType, tax?.taxAmount)
                        ))}
                    </>
                ) : null}
                {category === 'ltl' ? (
                    <>
                        {renderRateComponent('Freight Charge', charges?.FREIGHT_CHARGE)}
                        {renderRateComponent('CES Charge', charges?.CES_CHARGE)}
                        {renderRateComponent('COD VAS charge', charges?.VASCOD_CHARGES)}
                        {renderRateComponent('GST', charges?.GST)}
                        {renderRateComponent('Risk Surcharge', charges?.RISK_SURCHARGE)}
                        {renderRateComponent('ToPay Charge', charges?.TOPAY_CHARGES)}
                        {renderRateComponent('Fuel Surcharge', charges?.FUELSURCHARGE)}
                        {renderRateComponent('Out of Delivery Area Charge', charges?.ODA_CHARGES)}
                        {renderRateComponent('FOD VAS Charge', charges?.VASFOD_CHARGES)}
                        {renderRateComponent('DACC Charge', charges?.DACC_CHARGES)}
                        {renderRateComponent('Miscellaneous Charges', misCharges ? misCharges?.toString() : '')}
                    </>
                ) : null}

            </>
        );
        return (
            <div className={classes.serviceName}>
                <span style={{ margin: '0px 6px' }}>
                    {rate?.amount ? `₹ ${rate?.amount}` : '--'}
                </span>
                <Tooltip
                    title={rateMsg}
                    placement="leftTop"
                    color="#ffffff"
                    overlayClassName={classes.tooltipBreakup}
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
        );
    };

    const renderTat = (period: string) => {
        return (
            <div className={classes.servicePeriod}>{period}</div>
        );
    };

    const renderMsg = () => {
        return (
            <div className={classes.msg}>
                Delivery of consignment across India via air.
            </div>
        );
    };

    const renderRateCard = (service: Service) => {
        return (
            <div className={classes.rateCard}>
                <div className={classes.serviceRate}>
                    {renderServiceName(service.serviceType)}
                    {renderPrice(service?.rate)}
                </div>

                <div className={classes.serviceRate}>
                    {renderMsg()}
                    {renderTat(service?.period)}
                </div>
            </div>
        );
    };

    const renderRateList = () => {
        const filteredList = rateList.filter((item) => Boolean(item?.rate?.amount));
        if (!filteredList?.length) {
            return (
                <div>
                    No rates found.
                </div>
            );
        }
        return filteredList?.map((rate) => {
            return renderRateCard(rate);
        });
    };

    const renderRateAndTat = () => {
        return (
            <div className={classes.rateBody}>
                {(pincodeData?.src?.valid || pincodeData?.dst?.valid) && (
                    <>
                        <PincodeCard
                            data={pincodeData}
                            srcHeader="Origin"
                            dstHeader="Destination"
                        />
                        { renderLine() }
                    </>
                )}
                {rateList?.length ? (
                    <>
                        {renderMessage()}
                        {renderRateList()}
                    </>
                ) : null}
            </div>
        );
    };

    const renderCountry = () => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>Country *</span>
                <Select
                    showSearch
                    disabled={rateList?.length > 0}
                    value={pincodeData?.dst?.country}
                    placeholder="Country"
                    filterOption={(input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())}
                    onChange={(value: string) => {
                        const country = find(countryList, { country: value });

                        let valid = false;
                        if (country?.pincode_regex) {
                            const regex = new RegExp(country?.pincode_regex.replace(/^\//, '')?.replace(/\/$/, ''));
                            if (regex.test(pincodeData.dst?.pincode)) {
                                valid = true;
                            }
                        }
                        setStateList([]);
                        timeout = undefined;
                        setCityList([]);
                        setPincodeData({
                            ...pincodeData,
                            dst: {
                                ...pincodeData.dst,
                                valid,
                                country: value,
                                state: undefined,
                                city: undefined,
                            },
                        });
                    }}
                >
                    {countryList.map((item: Country) => {
                        return <Select.Option value={item.country}>{item.country}</Select.Option>;
                    })}
                </Select>
            </div>
        );
    };

    const renderState = () => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>State</span>
                <Select
                    disabled={rateList?.length > 0 || !pincodeData?.dst?.country}
                    value={pincodeData?.dst?.state}
                    placeholder="State"
                    filterOption={(input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())}
                    onChange={(value: string) => {
                        setPincodeData({
                            ...pincodeData,
                            dst: {
                                ...pincodeData.dst,
                                state: value,
                                city: undefined,
                            },
                        });
                    }}
                    showSearch
                >
                    {stateList.map((item: State) => {
                        return <Select.Option value={item.state_name}>{item.state_name}</Select.Option>;
                    })}
                </Select>
            </div>
        );
    };

    const renderCity = () => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>City *</span>
                <Select
                    disabled={rateList?.length > 0 || !pincodeData?.dst?.country}
                    value={pincodeData?.dst?.city}
                    placeholder="City"
                    onChange={(value: string) => {
                        setPincodeData({
                            ...pincodeData,
                            dst: {
                                ...pincodeData.dst,
                                city: value,
                            },
                        });
                    }}
                    onSearch={fetchCityList}
                    showSearch
                    notFoundContent={loading ? <Loader zIndex={5} /> : null}
                >
                    {cityList.map((item: City) => {
                        return <Select.Option value={item.city_name}>{item.city_name}</Select.Option>;
                    })}
                </Select>
            </div>
        );
    };

    const renderInternationalFields = () => {
        if (category !== 'international') {
            return null;
        }

        return (
            <div className={classes.internationalFields}>
                {renderCountry()}
                {renderState()}
                {renderCity()}
            </div>
        );
    };

    const renderHeaderPageView = () => {
        if (shouldRenderInDrawer) return null;
        return (
            <>
                <div className={classes.heading}>
                    <h2 className={classes.headingTitle}>Rate Calculator</h2>
                </div>
            </>
        );
    };

    const renderUiComponents = () => {
        return (
            <>
                {renderHeaderPageView()}
                <Row className={classes.rowView}>
                    <Col span={shouldRenderInDrawer ? 24 : 12} className={classes.colView}>
                        <div className={classes.colData}>
                            {renderCategory()}
                            {renderInternationalFields()}
                            {renderPincodes()}
                            {renderLoadType()}
                            {renderDimestions()}
                            {renderPayBasis()}
                            {renderCalculateButton()}
                        </div>
                    </Col>
                    {(rateList?.length || pincodeData?.src?.valid || pincodeData?.dst?.valid) ? (
                        <Col span={shouldRenderInDrawer ? 24 : 12} className={classes.colView}>
                            <div className={classes.colData}>
                                {renderRateAndTat()}
                            </div>
                        </Col>
                    ) : null}
                </Row>
            </>
        );
    };

    return shouldRenderInDrawer ? (
        <Drawer
            visible
            width="45%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderUiComponents()}
        </Drawer>
    ) : (
        <div
            className={classes.main}
            style={{
                height: `calc(100vh - ${NAVBAR_HEIGHT}px - 15px)`,
                margin: 5,
                backgroundColor: '#ffffff',
            }}
        >
            {renderUiComponents()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { miscellaneousState } = state;
    const { [OtherBuckets.EXPAND_STATE]: expandState } = miscellaneousState || {};
    return {
        expandState,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setStateForExpandAction: storeExpandingState,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: rateCalculatorStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(RateCalculator);
