import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

export const serviceAvailabilityStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        marginBottom: 100,
        maxHeight: '100vh',
        overflow: 'scroll',
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    inputs: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 16,
        paddingRight: theme.isRTL ? undefined : '10%',
        paddingLeft: theme.isRTL ? '10%' : undefined,
    },
    input: {
        width: '40%',
    },
    availableText: {
        color: '#232529',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
    },
    body: {
        padding: 24,
        paddingBottom: 8,
    },
    serviceName: {
        color: '#232529',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0,
    },
    value: {
        color: '#777777',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
    },
    nameValuePair: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        margin: 12,
        marginLeft: 0,
        marginRight: '10%',
    },
    name: {
        color: '#777777',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 0,
        marginBottom: 5,
        display: 'flex',
    },
    hr: {
        width: '92%',
        height: 1,
        marginLeft: '4%',
        backgroundColor: '#CCCCCC',
    },
    services: {
        ...commonStyleSheet(theme).flexColumn,
        padding: 24,
        paddingTop: 8,
    },
    heading: {
        marginTop: 10,
        padding: 0,
        paddingTop: 10,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: '100%',
    },
    headingTitle: {
        padding: '0 20px',
        fontSize: 18,
        color: '#333333',
        fontWeight: 'bold',
        width: '100%',
    },
    subHeading: {
        margin: '0 20px',
        marginTope: 10,
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        color: '#232529',
    },
    colData: {
        backgroundColor: '#FCFCFC',
        padding: '16px 12px',
        border: '1px solid #DDDFE9',
        height: 'fit-content',
    },
    colView: {
        padding: 10,
    },
    rowView: {
        margin: 10,
    },
    checkboxGroup: {
        margin: '10px 0',
        color: '#333333',
        fontSize: 12,
        fontWeight: 500,
    },
    checkbox: {
        width: 25,
        marginRight: 5,
        marginLeft: 5,
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: theme.primaryColor,
            borderColor: theme.primaryColor,
        },
        '& .ant-checkbox + span': {
            width: '100%',
        },
    },
    submitButton: {
        marginTop: 20,
        backgroundColor: theme.primaryColor,
        color: '#FFFFFF',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
    },
    singleInfoDesc: {
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'center',
        borderRadius: 8,
        width: '100%',
        backgroundColor: '#FAFDFF !important',
        border: 'none !important',
    },
    singleInfoDescIcon: {
        fontSize: 24,
        padding: 10,
        color: theme.primaryColor,
    },
    singleInfoDescText: {
        fontSize: 12,
        color: '#333333',
        fontWeight: 500,
        // color: '#232529',
    },
    singleInfoCheck: {
        fontSize: 12,
        fontWeight: 600,
    },
    tags: {
        padding: 16,
        '& .ant-tag': {
            fontSize: 14,
            color: '#777777',
            backgroundColor: '#F7F7F7',
            fontWeight: 600,
            padding: '4px 8px',
            borderRadius: 4,
            border: 'none',
        },
    },
    singleField: {
        ...commonStyleSheet(theme).flexColumn,
        margin: '0px 4px',
        width: '48%',
    },
    pincodeInput: {},
    pincodeValidation: {
        ...commonStyleSheet(theme).flexRow,
        color: '#52C14B',
        fontSize: 12,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        fontWeight: 600,
    },
    fields: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        paddingTop: 16,
    },
});
