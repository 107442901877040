import {
    getDefaultHeaders,
    getHeaders,
    getPieceHeaders,
    getDefaultPieceHeaders,
    getOrganisationMandatoryHeaders,
} from 'network/settings-product';
import * as React from 'react';
import { StylesProps } from 'theme/jss-types';
import { Header, PositionOption, TableColumn } from './settings.types';
import {
    Button,
    message,
    Table,
} from 'antd';
import { headerColumns } from './settings.constants';
import { accountStyles } from './settings.styles';
import ResetHeaders from './reset-headers';
import EditHeaders from './edit-headers';
import { NAVBAR_HEIGHT } from 'library/globals';
import { HocOptions } from '../common/generic-hoc.types';
import { ReduxStore } from '../../reducers/redux.types';
import { MasterConfig } from 'types/master-data-types';
import GenericHoc from '../common/generic-hoc';
import { useTranslation } from 'react-i18next';


interface HeaderMappingProps extends StylesProps<ReturnType<typeof accountStyles>> {
    config: MasterConfig;
    bannerHeight: number;
    pieceHeaders?: boolean;
}

const HeaderMapping = (props: HeaderMappingProps) => {
    const {
        classes,
        config,
        bannerHeight,
        pieceHeaders,
    } = props;
    const { t } = useTranslation();

    const freezeDefaultMandatoryHeaders = config?.customer_portal_config?.allow_freeze_default_mandatory_headers || false;

    const [headers, setHeaders] = React.useState<Header[]>([]);
    const [defaultHeaders, setDefaultHeaders] = React.useState<Header[]>([]);
    const [orgMandatoryHeaders, setOrgMandatoryHeaders] = React.useState<Header[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [resetVisible, setResetVisible] = React.useState<boolean>(false);
    const [editVisible, setEditVisible] = React.useState<boolean>(false);

    const loadHeaders = async () => {
        setLoading(true);
        const response = pieceHeaders ? await getPieceHeaders({ searchKey: '' }) : await getHeaders({ searchKey: '' });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setHeaders(response?.data || {});
        }
        setLoading(false);
    };

    const loadDefaults = async () => {
        setLoading(true);
        const response = pieceHeaders ? await getDefaultPieceHeaders({}) : await getDefaultHeaders({});
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setDefaultHeaders(response?.data || {});
        }
        setLoading(false);
    };

    const loadOrganisationMandatoryHeaders = async () => {
        setLoading(true);
        const response = await getOrganisationMandatoryHeaders();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setOrgMandatoryHeaders(response?.data || {});
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadHeaders();
        loadDefaults();
        if (freezeDefaultMandatoryHeaders) {
            loadOrganisationMandatoryHeaders();
        }
    }, [toggle]);

    const renderTitle = () => {
        return (
            <div
                className={classes.title}
                style={{ borderBottom: 'none' }}
            >
                <span>{pieceHeaders ? t('piece_header_name_mapping') : t('header_name_mapping')}</span>
                <div className={classes.buttons}>
                    <Button
                        type="primary"
                        disabled={loading}
                        onClick={() => setResetVisible(true)}
                        className={classes.newButton}
                    >
                        {t('reset').toUpperCase()}
                    </Button>
                    <Button
                        type="primary"
                        disabled={loading}
                        onClick={() => setEditVisible(true)}
                        className={classes.newButton}
                    >
                        {t('edit').toUpperCase()}
                    </Button>
                </div>
            </div>
        );
    };

    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const renderColumn = (
        text: string,
        row: Header,
        column: string,
        index: number,
    ) => {
        switch (column) {
            case 'index': return renderText(index + 1);
            case 'useDefault': return renderText(text ? 'YES' : 'NO');
            case 'name': return renderText(`${text}${row?.mandatory ? ' *' : ''}`);
            default: return renderText(text as string);
        }
    };

    const renderColumns = () => {
        const columns: any = headerColumns.map((column: TableColumn) => {
            return {
                key: column.key,
                title: column.key === 'name' ? t('header_name').toUpperCase() : t(column.key),
                dataIndex: column.key,
                ellipsis: true,
                bordered: false,
                render: (text: string, row: any, idx: number) => renderColumn(text, row, column.key, idx),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                columns={renderColumns()}
                loading={loading}
                dataSource={headers}
                pagination={false}
                className={classes.table}
                rowKey={(item: Header) => (item.id)}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 100px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    const handleResetClose = (reload?: boolean) => {
        setResetVisible(false);
        if (reload) {
            setToggle(!toggle);
        }
    };

    const renderReset = () => {
        if (!resetVisible) {
            return null;
        }
        return (
            <ResetHeaders
                onClose={(reload) => handleResetClose(reload)}
                headers={defaultHeaders}
                pieceHeaders={pieceHeaders}
            />
        );
    };

    const handleEditClose = (reload?: boolean) => {
        setEditVisible(false);
        if (reload) {
            setToggle(!toggle);
        }
    };

    const getCharCode = (position: number) => {
        return String.fromCharCode(position);
    };

    const coloumnLabelCreater = (length: number) => {
        const positions: PositionOption[] = [];
        positions.push({
            positionAlpha: 'Not Present',
            position: -1,
        });
        const startIndex = 'A'.charCodeAt(0);
        const breakNumber = 26;
        let label = '';
        for (let i = 0; i < length; i += 1) {
            const turn = Math.floor(i / breakNumber);
            if (turn === 0) {
                label = getCharCode(startIndex + i);
            } else {
                label = getCharCode(startIndex + turn - 1) + getCharCode(startIndex + i - (turn * breakNumber));
            }
            positions.push({
                positionAlpha: label,
                position: i,
            });
        }
        return positions;
    };

    const renderEdit = () => {
        if (!editVisible) {
            return null;
        }
        const columnLength = 104; // columns up till CZ
        const positionOptions = coloumnLabelCreater(columnLength);
        return (
            <EditHeaders
                onClose={(reload) => handleEditClose(reload)}
                headers={headers}
                orgMandatoryHeaders={orgMandatoryHeaders}
                positionOptions={positionOptions}
                freezeDefaultMandatoryHeaders={freezeDefaultMandatoryHeaders}
                pieceHeaders={pieceHeaders}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderTitle()}
            {renderTable()}
            {renderReset()}
            {renderEdit()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        config: state.master.config,
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: accountStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const HeaderMappingPage = GenericHoc(hocConfig)(HeaderMapping);

export default HeaderMappingPage;
