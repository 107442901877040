import * as React from 'react';
import { message, Modal } from 'antd';
import { Moment } from 'moment';
import { StylesProps } from '../../theme/jss-types';
import { NAVBAR_HEIGHT } from '../../library/globals';
import { CreateActions } from './create-modal.constants';
import successIcon from '../../assets/consignment-success.png';
import withStyles from 'react-jss';
import ConsignmentsIcon from '../../assets/consignments';
import {
    CloseCircleFilled,
    ExclamationCircleFilled,
    PlusOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import Helper from 'library/Helper';
import { printInvoice } from 'network/consignments.api';
import InvoiceIcon from 'assets/invoice';
import { useTranslation } from 'react-i18next';

const styles = {
    modal: {
        position: 'absolute',
        marginLeft: '62%',
        top: NAVBAR_HEIGHT + 16,
        '& .ant-modal-content': {
            width: 523,
            borderRadius: 4,
            zIndex: 100,
            padding: '17px 22px 21px 11px',
            backgroundColor: '#FFFFFF',
        },
        '& .ant-modal-body': {
            padding: 0,
        },
    },
    row: {
        display: 'flex',
        marginBottom: 7,
        padding: '24px 24px 0px 24px',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    addedTitle: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 'bold',
    },
    addedMsg: {
        color: '#666666',
        fontSize: 12,
        width: '65%',
        marginTop: 4,
        marginBottom: 8,
    },
    img: {
        marginRight: 16,
        height: 64,
        width: 64,
        marginTop: 6,
    },
    dateTime: {
        color: '#666666',
        fontSize: 10,
        fontWeight: 600,
        marginTop: 4,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        padding: '17px 0px 17px 24px',
        backgroundColor: '#FCFCFC',
    },
    actionItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#111111',
        fontSize: 12,
        marginRight: 24,
        cursor: 'pointer',
        fontWeight: 600,
    },
    failureRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#EA2626',
        fontSize: 12,
        fontWeight: 600,
    },
    viewErr: {
        fontSize: 12,
        color: '#111111',
        textDecoration: 'underline',
        fontWeight: 600,
    },
    icon: {
        marginRight: 8,
    },
    content: {
        borderRadius: 4,
        border: '1px solid #F7F7F7',
        boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.1)',
    },
    falure: {
        backgroundColor: '#FFF3EE',
        padding: '12px 24px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeIcon: {
        position: 'absolute',
        right: -5,
        top: -5,
        fontSize: 16,
    },
};

interface IProps extends StylesProps<typeof styles> {
    action: CreateActions;
    data: any;
    creationTime: Moment;
    handleAction: (actionType: string) => void;
    successArray?: any[];
    failureArray?: any[];
    showSupplementaryServices: boolean;
}
const SuccessProtip = (props: IProps) => {
    const {
        classes,
        action,
        data,
        handleAction,
        successArray,
        failureArray,
        creationTime,
        showSupplementaryServices,
    } = props;

    const { t, i18n } = useTranslation();

    const renderSingleAdded = () => {
        if (action !== CreateActions.SingleConsignment) {
            return null;
        }
        return (
            <span className={classes.addedMsg}>
                Your consignment with CN Reference No. &nbsp;
                {`${data?.referenceNumber}  is successfully Created.`}
            </span>
        );
    };

    const renderTitle = () => {
        const len = successArray?.length;
        return (
            <span className={classes.addedTitle}>
                {`${len || ''} `}
                {i18n.exists('consignment_success')
                    ? t('consignment_success')
                    : 'Consignment Added Successfully!'}
            </span>
        );
    };

    const renderDateTime = () => {
        return (
            <span className={classes.dateTime}>
                {creationTime}
            </span>
        );
    };

    const renderMessage = () => {
        return (
            <div className={classes.col}>
                {renderTitle()}
                {renderSingleAdded()}
                {renderDateTime()}
            </div>
        );
    };

    const renderImg = () => {
        return (
            <img
                alt="success"
                src={successIcon}
                className={classes.img}
            />
        );
    };

    const renderDetails = () => {
        return (
            <div className={classes.row}>
                {renderImg()}
                {renderMessage()}
            </div>
        );
    };

    const renderPrint = () => {
        if (action !== CreateActions.SingleConsignment) {
            return null;
        }
        return (
            <span
                className={classes.actionItem}
                onClick={() => handleAction('print')}
            >
                <PrinterOutlined className={classes.icon} />
                {i18n.exists('print_label') ? t('print_label') : 'Print Label'}
            </span>
        );
    };

    const renderView = () => {
        return (
            <span
                className={classes.actionItem}
                onClick={() => handleAction('view')}
            >
                <ConsignmentsIcon />
                <span style={{ marginLeft: 8 }}>
                    {i18n.exists('view_consignments') ? t('view_consignments') : 'View Consignments'}
                </span>
            </span>
        );
    };

    const renderAddMore = () => {
        return (
            <span
                className={classes.actionItem}
                onClick={() => handleAction('addMore')}
            >
                <PlusOutlined className={classes.icon} />
                <span>
                    {i18n.exists('add_more_consignment') ? t('add_more_consignment') : 'Add More Consignment'}
                </span>
            </span>
        );
    };

    const handlePrintInvoice = async (referenceNumbers: any, fileName: string) => {
        const fileBuffer = await printInvoice({
            referenceNumbers,
        });
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Failed to generate PDF!');
        }
    };

    const renderPrintInvoice = () => {
        if (!data?.showInvoicePrint) return null;
        return (
            <span
                className={classes.actionItem}
                onClick={() => handlePrintInvoice(
                    [data?.referenceNumber],
                    `Invoice_${data?.referenceNumber}.pdf`,
                )}
            >
                <InvoiceIcon className={classes.icon} />
                <span>{i18n.exists('print_invoice') ? t('print_invoice') : 'Print Invoice'}</span>
            </span>
        );
    };

    const renderAddSuppServices = () => {
        if (!showSupplementaryServices) return null;
        return (
            <span
                className={classes.actionItem}
                onClick={() => handleAction('addSuppServices')}
            >
                <PlusOutlined className={classes.icon} />
                <span> Add Supplementary Services </span>
            </span>
        );
    };

    const renderActions = () => {
        return (
            <div className={classes.actions}>
                {renderPrint()}
                {renderView()}
                {renderAddMore()}
                {renderAddSuppServices()}
                {renderPrintInvoice()}
            </div>
        );
    };

    const inComplete = () => {
        return (
            <ExclamationCircleFilled
                className={classes.icon}
            />
        );
    };

    const renderFailure = () => {
        const len = failureArray?.length;
        if (!len) {
            return null;
        }
        return (
            <div className={classes.falure}>
                <span className={classes.failureRow}>
                    {inComplete()}
                    {`${len} Consignment failed in uploading.`}
                </span>
                <span className={classes.viewErr}>
                    View Error
                </span>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.content}>
                {renderDetails()}
                {renderActions()}
                {renderFailure()}
            </div>
        );
    };

    const renderCloseIcon = () => {
        return (
            <CloseCircleFilled
                className={classes.closeIcon}
                onClick={() => handleAction('close')}
            />
        );
    };

    return (
        <Modal
            visible
            zIndex={501}
            width="523"
            title={null}
            footer={null}
            closable={false}
            maskClosable
            onCancel={() => handleAction('close')}
            className={classes.modal}
            maskStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
            }}
        >
            {renderCloseIcon()}
            {renderContent()}
        </Modal>
    );
};

export default withStyles(styles)(SuccessProtip);
