import * as React from 'react';
import { WithStylesProps } from 'react-jss';
import Cross from '../../../assets/cross';
import { Button, Drawer } from 'antd';
import AssignedFilter from '../../common-filters/is-assigned';
import StatusFilter from '../../common-filters/staus-filter';
import SortByFilter from '../../common-filters/sort-by';
import LoadTypeFilter from '../../common-filters/load-type-filter';
import { HocOptions } from '../../common/generic-hoc.types';
import { bindActionCreators } from 'redux';
import { ReduxStore } from '../../../reducers/redux.types';
import {
    setFilters,
    resetFilters,
    applyFilters,
    clearSelection,
} from '../../../actions/generic-action';
import { Buckets } from '../../../library/Types';
import GenericHoc from '../../common/generic-hoc';
import { useTranslation } from 'react-i18next';
import { ThemeType } from 'theme/jss-types';

const styles = (theme : ThemeType) => ({
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        overflow: 'scroll',
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            marginBottom: 56,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input-affix-wrapper': {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input, .ant-picker': {
            width: 250,
            borderRadius: 4,
            border: '1px solid #999999',
            height: 32,
            fontSize: 11,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-drawer-content-wrapper': {
            minWidth: 440,
        },
    },
    body: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    hr: {
        width: '100%',
        height: 1,
        backgroundColor: '#CCCCCC',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
    },
    moreFilter: {
        marginLeft: 16,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.primaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelClass: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: 120,
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        height: 56,
        display: 'flex',
        justifyContent: 'space-between',
    },
    subLabel: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 8,
        letterSpacing: 0,
        marginRight: 8,
    },
    filterName: {
        height: '60px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>> {
    onClose: () => void;
    setfilters: (bucketId: Buckets.PICKUPS, filters: any) => void;
    resetfilters: (bucketId: Buckets.PICKUPS) => void;
    applyfilters: (bucketId: Buckets.PICKUPS) => void;
    filters: Record<string, any>;
    resetVisible: boolean;
    clearselection: (bucketId: Buckets.PICKUPS) => void;
    defaultFilters: Record<string, any>;
}

const ExtraFilters = (props: IProps) => {
    const {
        classes,
        onClose,
        filters,
        resetfilters,
        setfilters,
        applyfilters,
        clearselection,
        resetVisible,
        defaultFilters,
    } = props;

    const [selectedFilter, setSelectedFilter] = React.useState<string>('isRiderAssigned');
    const { t } = useTranslation();

    const handleClose = (refresh = false) => {
        if (refresh) {
            applyfilters(Buckets.PICKUPS);
        } else {
            clearselection(Buckets.PICKUPS);
        }
        onClose();
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('more_filters')}
                    </span>
                </div>
                {resetVisible && (
                    <span
                        className={classes.resetFilter}
                        onClick={() => {
                            resetfilters(Buckets.PICKUPS);
                            onClose();
                        }}
                    >
                        {t('reset_filters')}
                    </span>
                )}
            </div>
        );
    };

    const onChangeFilter = (filter: string, value: any) => {
        const newFilters = {
            ...filters,
            [filter]: value,
        };
        setfilters(Buckets.PICKUPS, newFilters);
    };

    const renderAssignedFilter = () => {
        return (
            <div
                className={classes.row}
                key="isRiderAssigned"
            >
                <AssignedFilter
                    value={filters.isRiderAssigned}
                    onChange={((value) => onChangeFilter('isRiderAssigned', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderStatusFilter = () => {
        return (
            <div className={classes.row} key="status">
                <StatusFilter
                    value={filters.status}
                    onChange={((value) => onChangeFilter('status', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderSortFilter = () => {
        return (
            <div className={classes.row} key="sortBy">
                <SortByFilter
                    value={filters.sortBy}
                    onChange={((value) => onChangeFilter('sortBy', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderLoadFilter = () => {
        return (
            <div className={classes.row} key="courierType">
                <LoadTypeFilter
                    value={filters.courierType}
                    onChange={((value) => onChangeFilter('courierType', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderFilter = (filter: string) => {
        switch (filter) {
            case 'status': return renderStatusFilter();
            case 'sortBy': return renderSortFilter();
            case 'courierType': return renderLoadFilter();
            case 'isRiderAssigned':
            default: return renderAssignedFilter();
        }
    };

    const getLabel = (filter: string) => {
        switch (filter) {
            case 'status': return t('status');
            case 'sortBy': return t('sort_by');
            case 'courierType': return t('load_type');
            case 'isRiderAssigned': return t('is_assigned');
            default: return '';
        }
    };

    const renderFilterName = (filter: string) => {
        return (
            <div
                onClick={() => setSelectedFilter(filter)}
                className={classes.filterName}
                style={{
                    backgroundColor: selectedFilter === filter ? '#E2F2FF' : '#FFF',
                    borderRight: selectedFilter === filter ? '4px solid #1890FF' : 'none',
                }}
            >
                <div className={classes.labelClass}>
                    {getLabel(filter)}
                </div>
            </div>
        );
    };

    const renderFilterList = () => {
        const filterList = ['isRiderAssigned', 'courierType'];
        return filterList.map((filter: any) => renderFilterName(filter));
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                <div style={{ width: '40%', height: '100%', overflow: 'auto' }}>{renderFilterList()}</div>
                <div style={{ borderLeft: '1px solid #ccc', padding: '10px' }}>{renderFilter(selectedFilter)}</div>
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const showClearAll = () => {
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (['endDate', 'startDate'].includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const onClearChange = () => {
        setfilters(Buckets.PICKUPS, { ...defaultFilters });
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <div>
                    <Button
                        onClick={() => handleClose(true)}
                        type="primary"
                        style={{ marginLeft: 10, marginRight: 20 }}
                    >
                        {t('apply_filters')}
                    </Button>
                    <Button
                        onClick={() => handleClose()}
                        type="dashed"
                    >
                        {t('cancel')}
                    </Button>
                </div>
                {showClearAll() ? (
                    <Button
                        onClick={() => onClearChange()}
                        type="link"
                        style={{ color: 'red' }}
                    >
                        Clear All Filters
                    </Button>
                ) : null}
            </div>
        );
    };

    return (
        <Drawer
            className={classes.main}
            visible
            width="40%"
            title={renderHeader()}
            onClose={() => handleClose()}
            closable={false}
        >
            {renderBody()}
            {renderLine()}
            {renderFooter()}
        </Drawer>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const { PICKUPS } = generic;
    const { filters, defaultFilters } = PICKUPS;
    return {
        filters,
        defaultFilters,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        resetfilters: resetFilters,
        applyfilters: applyFilters,
        clearselection: clearSelection,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};

const PickupFilters = GenericHoc(hocConfig)(ExtraFilters);
export default PickupFilters;
