import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET, POST, PUT } from './api-hander.service';
import AuthService from '../auth/auth-helper';

import {
    AUTHENTICATE_ACTIVATION_CODE,
    CHANGE_PASSWORD,
    FETCH_MASTER_DATA,
    FETCH_COUNTRIES_NODE_DATA,
    GET_BUSINESS_TAT,
    GET_BUSINESS_TAT_V2,
    GET_CITIES,
    GET_COUNTRIES,
    GET_DOWNLOAD_REQUESTS,
    GET_INTERNATIONAL_BUSINESS_TAT,
    GET_FEEDBACK,
    GET_PICKUP_REQUESTS,
    GET_STATES,
    LOGIN,
    ORG_DETAILS,
    PASSWORD_POLICY,
    S3_UPLOAD_LINKS,
    SET_DEFAULT_PORTAL,
    SET_FEEDBACK,
    SEND_RESET_PASSWORD_MAIL,
    RESET_PASSWORD,
    GET_INTERNATIONAL_BUSINESS_TAT_V2,
    WHAT_3_WORD,
    LOGOUT_USER,
    FILE_UPLOAD_LINKS,
    UPLOAD_FILES,
    SET_PLUGIN_DATA,
    SEARCH_CUSTOMERS,
    CHANGE_CUSTOMER,
    GET_BUSINESS_TAT_SERVICEABILITY,
    GET_ADDRESS_HIERARCHY,
    GET_LOCATION_DATA,
    VALIDATE_RESET_PASSWORD_OTP,
    FETCH_CUSTOMER_EMAILS,
    SEND_FORGOT_PASSWORD_OTP_MAIL,
    LTL_CUSTOMER_HUB_DETAILS,
    GET_SERVICEABILITY_DATA,
} from './api.constants';

export const fetchMaster = async () => {
    const response = await GET(`${API_BASE_URL}${FETCH_MASTER_DATA}`);
    return response;
};

export const createPlugin = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${SET_PLUGIN_DATA}`, body);
    return response;
};

export const login = async (
    body: {
        username: string;
        password: string;
        keepMeSignedIn?: boolean;
        isEmployee?: boolean;
        customerCode?: string;
        captcha_validation_token?: string;
    },
) => {
    const response = await POST(`${API_BASE_URL}${LOGIN}`, body);
    return response;
};

export const searchCustomers = async (params: any) => {
    const storage = window.localStorage;
    const headers: any = {
        ...AuthService.getAuthenticationHeaders(),
        'access-token': storage.getItem('employeeAuthToken'),
        'user-id': storage.getItem('employeeId'),
    };
    const response = await GET(`${API_BASE_URL}${SEARCH_CUSTOMERS}`, params, 'json', headers);
    return response;
};

export const changeCustomerUser = async (body: any) => {
    const storage = window.localStorage;
    const headers: any = {
        ...AuthService.getAuthenticationHeaders(),
        'access-token': storage.getItem('employeeAuthToken'),
        'user-id': storage.getItem('employeeId'),
    };
    const response = await POST(`${API_BASE_URL}${CHANGE_CUSTOMER}`, body, false, headers);
    return response;
};

export const getBusinessTat = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GET_BUSINESS_TAT}`, params);
};

export const getServiceabilityData = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GET_SERVICEABILITY_DATA}`, params);
};

export const getServiceabilityRateTat = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GET_BUSINESS_TAT_SERVICEABILITY}`, params);
};

export const getUploadFileUrls = async (params: any) => {
    const endpoint = `referenceNumber=${params.reference_number}&contentType=${params.content_type}`;
    return GET(`${BUSINESS_BOOKING_API_URL}${FILE_UPLOAD_LINKS}${endpoint}`);
};

export const postUploadFiles = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${UPLOAD_FILES}`, body);
};

export const getBusinessTatV2 = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GET_BUSINESS_TAT_V2}`, body);
};

export const getInternationalBusinessTat = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_INTERNATIONAL_BUSINESS_TAT}`, params);
};

export const getInternationalBusinessTatV2 = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GET_INTERNATIONAL_BUSINESS_TAT_V2}`, params);
};

export const getDownloadRequests = async (params: any) => {
    return GET(`${API_BASE_URL}${GET_DOWNLOAD_REQUESTS}`, params);
};

export const getPickupDownloadRequests = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_PICKUP_REQUESTS}`, params);
};

export const getOrgDetails = async (params?: any) => {
    return GET(`${API_BASE_URL}${ORG_DETAILS}`, params);
};

export const getPasswordPolicy = async () => {
    const params = {
        type: 'password_constraint',
        user_type: 'customer',
    };
    return GET(`${API_BASE_URL}${PASSWORD_POLICY}`, params);
};

export const changePassword = async (params: any) => {
    return POST(`${API_BASE_URL}${CHANGE_PASSWORD}`, params);
};

export const authenticateActivationCode = async (params: any) => {
    return POST(`${API_BASE_URL}${AUTHENTICATE_ACTIVATION_CODE}`, params);
};

export const getS3Urls = async (params: any) => {
    return GET(`${API_BASE_URL}${S3_UPLOAD_LINKS}`, params);
};

export const uploadImageToS3 = async (file: any, url: string, type: string) => {
    return PUT(url, file, type);
};

export const getCountries = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_COUNTRIES}`);
};

export const getCountriesNotInternational = async (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_COUNTRIES_NODE_DATA}`, params);
};

export const getAddressHierarchy = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_ADDRESS_HIERARCHY}`);
};

export const getLocationData = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GET_LOCATION_DATA}`, body);
};

export const getStates = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_STATES}`, params);
};

export const getCities = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_CITIES}`, params);
};

export const setDefaultPortal = async (params?: any) => {
    return POST(`${API_BASE_URL}${SET_DEFAULT_PORTAL}`, params);
};

export const getPastFeedback = async (params: any) => {
    return GET(`${API_BASE_URL}${GET_FEEDBACK}`, params);
};

export const setNewFeedback = async (body: any) => {
    return POST(`${API_BASE_URL}${SET_FEEDBACK}`, body);
};

export const checkWhat3Word = async (body: any) => {
    return POST(`${API_BASE_URL}${WHAT_3_WORD}`, body);
};

export const sendResetPasswordMail = async (body: any) => {
    return POST(`${API_BASE_URL}${SEND_RESET_PASSWORD_MAIL}`, body);
};

export const fetchCustomerEmails = async (body: any) => {
    return POST(`${API_BASE_URL}${FETCH_CUSTOMER_EMAILS}`, body);
};

export const sendForgotPasswordOTP = async (body: any) => {
    return POST(`${API_BASE_URL}${SEND_FORGOT_PASSWORD_OTP_MAIL}`, body);
};

export const validateResetPasswordOtp = async (body: any) => {
    return POST(`${API_BASE_URL}${VALIDATE_RESET_PASSWORD_OTP}`, body);
};

export const resetPassword = async (body: any) => {
    return POST(`${API_BASE_URL}${RESET_PASSWORD}`, body);
};

export const logoutCustomer = async () => {
    return POST(`${API_BASE_URL}${LOGOUT_USER}`, {});
};

export const getLTLCustomerHubDetails = async (body: any) => {
    return POST(`${API_BASE_URL}${LTL_CUSTOMER_HUB_DETAILS}`, body);
};
