/* eslint-disable max-len */

import * as React from 'react';
import { Table } from 'antd';
import Helper from '../../../library/Helper';
import { withSize } from 'react-sizeme';
import {
    ThemeType,
} from 'theme/jss-types';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import CustomTableTitle from './CustomTableTitle';
import { LocalStorageService, LocalStorageKeys } from '../../../services/localStorage';
import CustomTableCell from './CustomTableCell';
import { withTranslation } from 'react-i18next';
import { HocOptions } from '../generic-hoc.types';
import GenericHoc from '../generic-hoc';
import { useGenericState } from 'hooks/use-generic-state';

const defaultWidth = 190;

const styles = (theme: ThemeType) => ({
    tableParent: {
        '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
            wordBreak: 'break-word',
            backgroundColor: theme.surfaceBg,
            color: theme.primaryText,
        },
        '& .ant-table-tbody > tr > td': {
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            '& a': {
                color: theme.hyperlink,
            },
        },
        '& .ant-table-thead': {
            backgroundColor: theme.primaryColor,
            color: theme.primaryText,
        },
    },
    compactTable: {
        '& .ant-table-thead > tr > th': {
            overflow: 'hidden',
            fontSize: theme.bodyText,
            fontWeight: '600',
            lineHeight: '12px',
            padding: '7px 12px',
            backgroundColor: theme.tableHeader,
            color: theme.primaryText,

            '& .ant-table-column-title': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '100%',
            },
        },
        '& .ant-table-thead > tr': {
            backgroundColor: theme.tableHeader,
        },
        '& .ant-table-row': {
            backgroundColor: theme.primaryColor,
        },
        '& .ant-table-row > td': {
            fontSize: theme.bodyText,
            padding: '10px 12px',
            color: theme.primaryText,
            fontWeight: '500',
        },
        '& .ant-table-expanded-row > td': {
            padding: '8px 0',
        },
        '& .ant-table-row-selected': {
            backgroundColor: `${theme.selectionOnWhiteBg} !important`,
            '& .ant-table-cell-fix-left, .ant-table-cell-fix-right': {
                backgroundColor: '#c4e8f6 !important',
            },
        },
        '& .ant-table-row-selected > td': {
            backgroundColor: `${theme.selectionOnWhiteBg} !important`,
            '&:hover': {
                backgroundColor: `${theme.HoverOnWhiteBg} !important`,
            },
        },
        '& .ant-table-ping-left .ant-table-cell-fix-left-first::after, .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
            boxShadow: 'none',
        },
        '& .ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-ping-right .ant-table-cell-fix-right-last::after': {
            boxShadow: 'none',
        },
        '& .ant-table-cell-row-hover': {
            backgroundColor: `${theme.HoverOnWhiteBg} !important`,
        },
    },
});

const FixedHeaderTableComponent = (props: any) => {
    const {
        columns,
        size,
        resizable = true,
        match,
        scroll,
        classes,
        hideScrollX,
        style,
        rowSelection,
        locale,
        components,
        t,
        handleSort,
        sortBy,
        descendingOrder,
        showHeaderTooltip,
        ...otherProps
    } = props;
    const [state, setState] = useGenericState({
        scrollY: 0,
        columnWidths: {},
    });

    // this state is useful to refetch of width of columns, when user reset it via dragging
    const [toggle, setToggle] = React.useState<boolean>(false);
    const tableRef = React.useRef(null);


    const getLocalStorageKeyForTable = () => {
        if (tableRef.current) {
            const pathname = match?.path ?? window.location.pathname;
            const currentTableRef = tableRef.current;
            const tabParent = (currentTableRef as HTMLElement).closest
                ? (currentTableRef as HTMLElement).closest('.ant-tabs-tabpane, .shipsy-generic-tabs-with-sm-cls') : null;
            let idxKey = tabParent?.getAttribute('id');
            if (!idxKey) {
                const allTableRefs = Array.from(document.getElementsByClassName('shipsy-fixed-header-table') ?? []);
                const currentTableIndex = currentTableRef
                    ? allTableRefs.findIndex((ref) => ref === currentTableRef) : -1;
                if (currentTableIndex !== null && currentTableIndex > -1) {
                    idxKey = currentTableIndex.toString();
                }
            }
            if (idxKey && pathname) {
                // eslint-disable-next-line no-underscore-dangle
                return `${LocalStorageKeys._TABLE_COLUMS_WIDTH_DATA}:${pathname}-${idxKey}`;
            }
        }
        return null;
    };

    const columnWidthLocalStorage = () => {
        let columnWidthFromLocalStorage;
        const key = getLocalStorageKeyForTable();
        if (key) {
            try {
                const localdata = LocalStorageService.getRaw(key);
                if (localdata) {
                    columnWidthFromLocalStorage = JSON.parse(localdata);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
        return columnWidthFromLocalStorage;
    };

    const getScrollHeight = () => {
        const { width, height } = size;

        const tableElement: any = tableRef.current;

        if (!tableElement || !width) {
            return 0;
        }

        const tableHeaderElementHeight = tableElement
            .getElementsByTagName('thead')[0]
            .offsetHeight;

        const paginationElement = tableElement
            .getElementsByClassName('ant-table-pagination')[0];
        // Pagination might not always be present
        const paginationElementHeight = paginationElement ? Helper.elementOuterHeight(paginationElement) : 0;

        // Offset of position of title (equal to 1 (top: 1 in ant css))
        const titlePositionOffset = 1;

        const tableTitleElementHeight = tableElement
            .getElementsByClassName('ant-table-title')[0]
            ? tableElement
                .getElementsByClassName('ant-table-title')[0]
                .offsetHeight + titlePositionOffset : 0;

        const cardHeaderHeight = (tableHeaderElementHeight
            + tableTitleElementHeight
            + paginationElementHeight
        );

        return Math.max(0, (height - cardHeaderHeight));
    };

    const updateWidth = () => {
        const newState: any = {
            columnWidths: columnWidthLocalStorage() || {},
        };
        const newscrollY = getScrollHeight();
        const { scrollY } = state;
        if (newscrollY !== scrollY) {
            newState.scrollY = newscrollY;
        }

        setState(newState);
    };

    React.useEffect(() => {
        updateWidth();
    }, []);

    React.useEffect(() => {
        updateWidth();
    }, [toggle]);

    React.useEffect(() => {
        const newscrollY = getScrollHeight();
        const { scrollY } = state;
        if (newscrollY !== scrollY) {
            setState({ scrollY: newscrollY });
        }
    }, [size]);

    const getScrollWidth = createSelector(
        (p: any) => p.columns,
        (columnsList: any) => {
            return (columnsList.reduce((acc: any, cur: { width: any; }) => {
                return acc + (cur.width || defaultWidth);
            }, 0) - 15);
        },
    );

    const getTotalColumnWidth = createSelector(
        (p: any) => p.columns,
        (columnsList: any) => {
            return (columnsList.reduce((acc: any, cur: { width: any; }) => {
                return acc + (cur.width || defaultWidth);
            }, 0));
        },
    );

    const handleResize = (column: any) => {
        return (width: number) => {
            const { columnWidths } = state;
            const newColumnWidth = { ...columnWidths };
            newColumnWidth[column.key as string ?? column.dataIndex as string] = Math.max(width, 36);
            const keyToSet = getLocalStorageKeyForTable();
            if (keyToSet) {
                LocalStorageService.set(keyToSet, JSON.stringify(newColumnWidth));
            }

            setState({ columnWidths: newColumnWidth });
        };
    };

    const getColumnData = () => {
        const { columnWidths } = state;
        return columns.map((column: {
            isSortable: any; key: any; dataIndex: any; sameTooltipAsElem: any; width: any;
        }) => {
            const widthKey = column.key ?? column.dataIndex;
            return {
                ...column,
                onHeaderCell: (columnNew: { width: any; }) => ({
                    width: columnNew.width,
                    tableHeight: size.height,
                    onResizeStop: handleResize(columnNew),
                    resizable,
                    isSortable: handleSort && column.isSortable,
                    onSort: (val: string) => handleSort(val, columnNew),
                    sortSelected: sortBy === column.key || sortBy === column.dataIndex,
                    descendingOrder,
                }),
                onCell: () => ({
                    sameTooltipAsElem: column.sameTooltipAsElem,
                }),
                width: columnWidths[widthKey] ?? column.width,
            };
        });
    };

    const render = () => {
        const { scrollY } = state;
        const columnsList = getColumnData();
        const { width, height } = size;
        const newScrollY = (width && height) ? scrollY : false;
        let scrollX: undefined | number;
        let columnsToSet = columnsList;
        if (hideScrollX) {
            // No scroll in x
            const totalColumnWidth = getTotalColumnWidth(props);
            let totalWidthToSet = 0;
            columnsToSet = columnsList.map((c: { width: any; }) => {
                const widthToSet = Math.floor(((c.width || defaultWidth) / totalColumnWidth) * (width - 10));
                totalWidthToSet += widthToSet;
                return { ...c, width: widthToSet };
            });
            scrollX = totalWidthToSet;
        } else {
            scrollX = getScrollWidth(props);
            if (scroll && scroll.x) {
                scrollX = scroll.x;
            }
            if (scrollX && scrollX < width && columnsList.length) {
                const givenTotalWidth = columnsList.reduce((acc: any, cur: { width: any; }) => {
                    return acc + (cur.width);
                }, 0);
                const numberOfdefinedWidthColumns = columnsList.reduce((acc: number, cur: { width: any; }) => {
                    return acc + (cur.width ? 1 : 0);
                }, 0);
                // eslint-disable-next-line no-return-assign
                columnsList.forEach((c: { [x: string]: number; width: any; }) => !c.width
                    // eslint-disable-next-line no-param-reassign
                    && (c.width = (
                        width - givenTotalWidth - 62) / (columnsList.length - numberOfdefinedWidthColumns)));
            }
            scrollX = Math.max(scrollX || 0, width);
        }
        const scrollPropToAdd: any = {
            y: newScrollY,
            x: scrollX,
        };
        const { className } = props;

        return (
            <div style={{ position: 'relative', height: '100%', ...style }}>
                <div
                    className={classNames(
                        classes.compactTable,
                        classes.tableParent,
                        className,
                        'shipsy-fixed-header-table',
                    )}
                    style={{ position: 'absolute', width: '100%', height: size.height }}
                    ref={tableRef}
                >
                    <Table
                        {...otherProps}
                        columns={columnsToSet}
                        scroll={scrollPropToAdd}
                        bordered
                        locale={{
                            ...(locale || {}),
                            emptyText: locale?.emptyText ?? t('No data found'),
                        }}
                        components={{
                            header: {
                                cell: (HeaderProps) => (
                                    <CustomTableTitle
                                        {...HeaderProps}
                                        toggleWidth={() => {
                                            setToggle(!toggle);
                                        }}
                                        showHeaderTooltip={showHeaderTooltip}
                                    />
                                ),
                            },
                            body: {
                                cell: CustomTableCell,
                            },
                        }}
                        rowSelection={rowSelection ? {
                            ...rowSelection,
                            getCheckboxProps: (record) => {
                                const propsFromParent = rowSelection.getCheckboxProps
                                    ? rowSelection.getCheckboxProps(record) : {};
                                return {
                                    ...propsFromParent,
                                    style: {
                                        borderRadius: '2px',
                                    },
                                };
                            },
                        } : null}
                    />
                </div>
            </div>
        );
    };

    return render();
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
};

const FixedHeaderTable = GenericHoc(hocConfig)(FixedHeaderTableComponent);

export default withTranslation()(React.memo(
    withSize({ monitorHeight: true, monitorWidth: true })(
        FixedHeaderTable,
    ),
));

