import * as React from 'react';
import withStyles from 'react-jss';

const styles = () => ({
    tooltip: {
        '& .ant-tooltip-inner > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div > a': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > span': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > button': {
            color: '#FFFFFF !important',
        },
    },
});

const CustomTableCell = (props: any) => {
    const {
        classes, children, sameTooltipAsElem, colWidth, ...restProps
    } = props;
    const childRef = React.useRef(null);

    const cellContent = (
        <div
            ref={childRef}
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                cursor: 'default',
            }}
        >
            {children}
        </div>
    );

    return (
        <td {...restProps}>
            {cellContent}
        </td>
    );
};

export default React.memo(withStyles(styles, {
    injectTheme: true,
})(CustomTableCell));
