import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import * as React from 'react';
import { ReduxStore } from 'reducers/redux.types';
import { bindActionCreators } from 'redux';
import { StylesProps } from '../../theme/jss-types';
import { remittanceReportStyles } from './remittance-report.styles';
import { RouteChildrenProps } from 'react-router';
import { applyFilters, setFilters, setPagination } from 'actions/generic-action';
import {
    DatePicker,
    message,
    Select,
    Table,
} from 'antd';
import { NAVBAR_HEIGHT } from 'library/globals';
import { InvoiceReportColumns, SBUOptions } from './reports.constants';
import moment, { Moment } from 'moment';
import { TableColumn } from 'components/settings-product/settings.types';
import { Master } from 'types/master-data-types';
import {
    Buckets,
    Customer,
    NextOrPrev,
    Pagination,
} from 'library/Types';
import { downloadInvoiceReport, downloadInvoiceReportRequest, getChildList } from 'network/consignments.api';
import { loadInvoiceData } from 'actions/invoice-actions';
import { isEqual } from 'lodash';
import Paginator from 'components/common/paginator';
import { getDownloadsRoute } from 'routing/routing-helper';

interface invoiceReportProps extends StylesProps<ReturnType<typeof remittanceReportStyles>>, RouteChildrenProps {
    master: Master,
    invoiceData: any[],
    loadInvoice: () => void,
    loading: boolean,
    pagination: Record<string, any>,
    changePagination: (bucketId: Buckets.INVOICE, newPagination: Partial<Pagination>) => void;
    setFilter: (bucketId: Buckets.INVOICE, filters: any) => void;
    applyFilter: (bucketId: Buckets.INVOICE) => void;
    filters: Record<string, any>,
    defaultFilters: Record<string, any>,
}

const InvoiceReportPage = (props: invoiceReportProps) => {
    const {
        classes,
        master,
        invoiceData,
        loadInvoice,
        loading,
        pagination,
        changePagination,
        setFilter,
        applyFilter,
        filters,
        defaultFilters,
        history,
    } = props;

    const [childClients, setChildClients] = React.useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(null);
    const [selectedSbu, setSelectedSbu] = React.useState<string>(filters?.sbu);
    const [defaultCustomer, setDefaultCustomer] = React.useState<any>(null);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [date, setDate] = React.useState<Moment>(moment(filters?.date * 1000));
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;

    const loadCustomerCodes = async () => {
        const response: any = await getChildList();
        if (response?.status) {
            setChildClients(response?.data);
        } else {
            return;
        }
        const storage = window?.localStorage;
        const id = storage?.getItem('userId');
        const code = storage?.getItem('userCode');
        const currentCustomer = JSON.stringify({
            customer_code: code,
            customer_id: id,
        });
        setDefaultCustomer(currentCustomer);
        setSelectedCustomer(currentCustomer);
    };

    React.useEffect(() => {
        loadCustomerCodes();
    }, []);

    React.useEffect(() => {
        loadInvoice();
    }, [toggle, filters]);


    const changeCustomerCodeFilter = (obj: any) => {
        const customer = JSON.parse(obj || '{}');
        setFilter(Buckets.INVOICE, {
            ...filters,
            ...(customer || {}),
        });
        applyFilter(Buckets.INVOICE);
    };

    const renderCustomerCode = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Customer/Franchisee
                </span>
                <Select
                    placeholder="Select Customer Code"
                    allowClear={false}
                    showSearch
                    options={childClients.map((cust) => {
                        return {
                            value: JSON.stringify({
                                customer_code: cust?.customer_code,
                                customer_id: cust?.customer_id,
                            }),
                            label: `${cust?.customer_code} - ${cust?.customer_name}`,
                        };
                    })}
                    value={selectedCustomer}
                    onChange={(value) => {
                        changeCustomerCodeFilter(value);
                        setSelectedCustomer(value);
                    }}
                />
            </div>
        );
    };

    const renderDatePicker = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Month
                </span>
                <DatePicker
                    value={date}
                    onChange={(e: any) => {
                        setDate(e);
                        setFilter(Buckets.INVOICE, {
                            ...filters,
                            date: e.unix(),
                        });
                        applyFilter(Buckets.INVOICE);
                    }}
                    format="MMMM, YYYY"
                    picker="month"
                />
            </div>
        );
    };

    const renderSbu = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    SBU
                </span>
                <Select
                    placeholder="SBU"
                    allowClear={false}
                    showSearch
                    options={SBUOptions}
                    value={selectedSbu}
                    onChange={(value) => {
                        setSelectedSbu(value);
                        setFilter(Buckets.INVOICE, {
                            ...filters,
                            sbu: value,
                        });
                        applyFilter(Buckets.INVOICE);
                    }}
                />
            </div>
        );
    };

    const isMoreFilterAdded = () => {
        return (!isEqual(defaultCustomer, selectedCustomer))
            || (!(
                defaultFilters?.date === date?.unix()))
            || defaultFilters?.sbu !== selectedSbu;
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setSelectedCustomer(defaultCustomer);
                    setDate(moment(defaultFilters?.date * 1000));
                    setSelectedSbu(defaultFilters?.sbu);
                    setFilter(Buckets.INVOICE, {
                        ...defaultFilters,
                    });
                    applyFilter(Buckets.INVOICE);
                }}
            >
                Reset Filter
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.filter}>
                {renderSbu()}
                {renderCustomerCode()}
                {renderDatePicker()}
                {renderResetFiltersOption()}
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        changePagination(Buckets.INVOICE, newPagination);
        setToggle(!toggle);
    };


    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination?.currentPageNumber || 1}
                isNextPresent={pagination?.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };


    const renderRightFilters = () => {
        return (
            <div className={classes.filter}>
                {renderPaginator()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderText = (text: string, row: any) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const handleDownloadInvoice = async (row: any) => {
        setDownloading(true);
        const response = await downloadInvoiceReportRequest({
            date: date.format('MMMM YYYY'),
            sbu: selectedSbu,
            customerCode: JSON.parse(selectedCustomer || '{}')?.customer_code,
            invoiceNumber: row.CIH_INVOICE_NO,
        });
        if (response.isSuccess) {
            const downloadResponse = await downloadInvoiceReport({
                uuid: response?.data?.uuid,
                invoiceNumber: row.CIH_INVOICE_NO,
            });
            if (downloadResponse.isSuccess) {
                message.success('Requested Successfully');
                history.push(getDownloadsRoute());
            } else {
                message.error(downloadResponse.errorMessage);
            }
        } else {
            message.error(response.errorMessage);
        }
        setDownloading(false);
    };

    const renderColumn = (text: any, row: any, column: TableColumn) => {
        if (['CIH_INV_DATE'].includes(column.key)) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString, row);
        }
        if (column.key === 'downloadPdfAction') {
            return (
                <div className={classes.downloadInvoiceBtn} onClick={() => handleDownloadInvoice(row)}>
                    Download
                </div>
            );
        }

        // please don't change this default object check
        if (typeof text === 'object') {
            return <div className={classes.cellValue}>{JSON.stringify(text)}</div>;
        }
        return renderText(text, row);
    };


    const getColumns = (): any[] => {
        return InvoiceReportColumns.map((column) => {
            return {
                key: column.key,
                title: column.pretty_name,
                dataIndex: column.key,
                width: 100,
                ellipsis: true,
                fixed: false,
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
    };

    const renderTable = () => {
        return (
            <Table
                columns={getColumns()}
                dataSource={invoiceData || []}
                loading={loading || downloading}
                className={classes.table}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                rowKey={(row) => row.key}
                bordered={false}
                pagination={false}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 115px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const remittance = generic.INVOICE;
    const {
        loading,
        data,
        pagination,
        filters,
        defaultFilters,
    } = remittance;
    return {
        loading,
        pagination,
        invoiceData: data,
        filters,
        defaultFilters,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setFilter: setFilters,
        applyFilter: applyFilters,
        loadInvoice: loadInvoiceData,
        changePagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: remittanceReportStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const InvoiceReport = GenericHoc(hocConfig)(InvoiceReportPage);
export default InvoiceReport;
