/* eslint-disable max-len */
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    message, Table, DatePicker, Button, Tooltip,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { NAVBAR_HEIGHT } from 'library/globals';
import { sortBy } from 'lodash';
import moment, { Moment } from 'moment';
import { getInvoiceDownloadRequests } from 'network/consignments.api';
import * as React from 'react';
import { ReduxStore } from 'reducers/redux.types';
import { Master } from 'types/master-data-types';
import { getDownloadRequests, getPickupDownloadRequests } from '../../network/common.api';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { useTranslation } from 'react-i18next';

const downloadPageStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        maxWidth: '100%',
        padding: '5px 8px',
        backgroundColor: theme.backgroundColor,
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: 12,
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-picker': {
            width: 130,
            border: '1px solid #999999',
            height: 28,
            fontSize: 14,
            borderRadius: 14,
        },
        '& .ant-picker-input > input': {
            fontSize: 14,
        },
    },
    title: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        fontSize: 16,
        color: '#111111',
        fontWeight: 'bold',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    name: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        color: '#111111',
    },
    value: {
        fontSize: 12,
        letterSpacing: 0,
        color: '#111111',
    },
    middle: {
        margin: '0 8px',
    },
    table: {
        '& td': {
            verticalAlign: 'baseline',
            border: 'none',
        },
        '& .ant-table-thead': {
            whiteSpace: 'pre-line !important',
            backgroundColor: '#',
            border: '1px solid #E8E8E8',
            color: '#262626',
            padding: 0,
        },
        '& .ant-table-selection-column': {
            borderRight: 'none !important',
        },
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
});

const {
    useEffect,
    useState,
} = React;

type Request = {
    createdAt: Moment;
    filters: Record<string, string>;
    status: string;
    type: string;
    url: string;
    dumpType: string;
};

interface DownloadPageProps extends StylesProps<ReturnType<typeof downloadPageStyles>> {
    master: Master,
    uiTheme: ThemeType,
}

const Downloads = (props: DownloadPageProps) => {
    const {
        classes,
        master,
        uiTheme,
    } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [requests, setRequests] = useState<Request[]>([]);
    const [createdAtDate, setCreatedAtDate] = React.useState(moment());
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const allow_child_login_on_parent = master?.parts_to_show?.allow_child_login_on_parent || false;
    const { t, i18n } = useTranslation();

    const loadRequests = async () => {
        const dateFormat = 'YYYY-MM-DD';
        setLoading(true);
        const response = await getDownloadRequests({
            fromDate: moment(createdAtDate).format(dateFormat),
            toDate: moment(createdAtDate).format(dateFormat),
            dumpType: ['CUSTOMER_PORTAL_CONSIGNMENT', 'CUSTOMER_PORTAL_CONSIGNMENT_DTDC', 'CUSTOMER_PORTAL_REPORT', 'PLUGIN_ORDER_DOWNLOAD'],
        });
        const invoiceResponse = await getInvoiceDownloadRequests({
            date: moment().format('MMMM YYYY'),
            dumpType: 'CUSTOMER_PORTAL_INVOICE_REPORTS',
        });
        const pickupRequests = await getPickupDownloadRequests({
            fromDate: moment(createdAtDate).format(dateFormat),
            toDate: moment(createdAtDate).format(dateFormat),
        });
        const requestList = [];

        if (response.isSuccess) {
            requestList.push(...(response?.data?.requests || []));
        } else {
            message.error(response.errorMessage);
        }
        if (pickupRequests.isSuccess) {
            const pickupRequestsList = pickupRequests.data.map((request: any) => {
                return {
                    dumpType: 'Pickups',
                    filterParams: request.filters,
                    createdAt: request.createdAt,
                    status: request.status,
                    url: request.url,
                };
            });
            requestList.push(...(pickupRequestsList || []));
        } else {
            message.error(response.errorMessage);
        }
        if (invoiceResponse.isSuccess) {
            requestList.push(...(invoiceResponse?.data?.requests || []));
        } else {
            message.error(response.errorMessage);
        }
        setRequests(sortBy(requestList, ['createdAt']).reverse());
        setLoading(false);
    };

    useEffect(() => {
        loadRequests();
    }, [createdAtDate]);

    const getDisabled = (current: Moment) => {
        return current && moment(current) >= moment();
    };

    const renderCreatedAtFilter = () => {
        return (
            <div>
                <span style={{ fontWeight: 400, fontSize: '14px', marginRight: '10px' }}>{t('createdAt')}</span>
                {allow_child_login_on_parent
                    && (
                        <DatePicker
                            value={createdAtDate}
                            disabledDate={(current) => getDisabled(current)}
                            placeholder="Select Date"
                            allowClear={false}
                            onChange={(value) => {
                                setCreatedAtDate(moment(value));
                            }}
                        />
                    )}
            </div>
        );
    };

    const renderRefresh = () => {
        return (
            <Tooltip title={t('reload')}>
                <Button
                    size="small"
                    type="ghost"
                    loading={loading}
                    style={{
                        color: uiTheme.primaryColor,
                        fontSize: 14,
                        margin: 10,
                    }}
                    onClick={() => {
                        loadRequests();
                    }}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };

    const renderTitle = () => {
        return (
            <div className={classes.title}>
                <div>
                    {t('download_request')}
                    {renderRefresh()}
                </div>
                {renderCreatedAtFilter()}
            </div>
        );
    };

    const downloadColumns: Record<string, string> = {
        serialNumber: 'S.No.',
        dumpType: 'Request Type',
        filterParams: 'Filters',
        createdAt: 'Created At',
        status: 'Status',
        url: 'Link',
    };

    const renderText = (text: string | number) => {
        const data = (text || '').toString().toLowerCase();
        return (
            <div
                className={classes.cellValue}
            >
                {`${i18n.exists(data) ? t(data) : text}`}
            </div>
        );
    };

    const renderFilter = (name: string, value: string) => {
        return (
            <span className={classes.row}>
                <span className={classes.name}>{name}</span>
                <span className={classes.middle}>-</span>
                <span className={classes.value}>{value || 'ALL'}</span>
            </span>
        );
    };

    const renderRemittanceFilters = (filters: Record<string, string>) => {
        const dateFormat = 'YYYY/MM/DD';
        const fromDate = filters?.fromDate?.replaceAll('-', '/') || (filters?.startDate ? moment(filters?.startDate).format(dateFormat) : 'ALL');
        const toDate = filters?.toDate?.replaceAll('-', '/') || (filters?.endDate ? moment(filters?.endDate).format(dateFormat) : 'ALL');
        const dateString = `${fromDate} - ${toDate}`;
        return (
            <div
                className={classes.col}
            >
                {renderFilter('Date', dateString)}
                {renderFilter('Account', filters?.customerCode)}
            </div>
        );
    };

    const renderFilters = (filters: Record<string, string>) => {
        const dateFormat = 'YYYY/MM/DD';
        const fromDate = filters?.fromDate?.replaceAll('-', '/') || (filters?.startDate ? moment(filters?.startDate).format(dateFormat) : 'ALL');
        const toDate = filters?.toDate?.replaceAll('-', '/') || (filters?.endDate ? moment(filters?.endDate).format(dateFormat) : 'ALL');
        const dateString = `${fromDate} - ${toDate}`;
        return (
            <div
                className={classes.col}
            >
                {renderFilter(t('date'), dateString)}
                {renderFilter(t('account'), filters?.clientName)}
                {renderFilter(t('status'), filters?.status)}
                {renderFilter(t('service_type'), filters?.serviceType)}
                {renderFilter(t('consignment_type'), filters?.consignmentType?.toUpperCase())}
            </div>
        );
    };

    const renderInvoiceFilters = (filters: Record<string, string>) => {
        return (
            <div
                className={classes.col}
            >
                {renderFilter('Date', filters?.date)}
                {renderFilter('Account', filters?.account)}
                {renderFilter('Invoice Number', filters?.invoiceNumber)}
                {renderFilter('SBU', filters?.sbu)}
            </div>
        );
    };

    const renderDownload = (url: string) => {
        if (!url) {
            return null;
        }
        return (
            <a href={url} download>
                <DownloadOutlined />
            </a>
        );
    };

    const renderDate = (date: Moment) => {
        const dateString = moment(date).format('DD MMM, YYYY | hh:mm:A');
        return renderText(dateString);
    };

    const renderColumn = (
        text: string | Record<string, string> | Moment,
        row: Request,
        column: string,
        index: number,
    ) => {
        switch (column) {
            case 'filterParams':
                if (row.dumpType === 'CUSTOMER_PORTAL_INVOICE_REPORTS') {
                    return renderInvoiceFilters(text as Record<string, string>);
                }
                if (row.dumpType === 'CUSTOMER_PORTAL_REPORT') {
                    return renderRemittanceFilters(text as Record<string, string>);
                }
                return renderFilters(text as Record<string, string>);
            case 'serialNumber': return renderText(index + 1);
            case 'url': return renderDownload(text as string);
            case 'createdAt': return renderDate(text as Moment);
            case 'dumpType':
            case 'status':
            default: return renderText(text as string);
        }
    };

    const getWidth = (column: string) => {
        switch (column) {
            case 'filterParams': return 300;
            case 'url':
            case 'dumpType':
            case 'status':
            case 'serialNumber':
            default: return 150;
        }
    };

    const getColumns = (): ColumnProps<Request>[] => {
        const columns: ColumnProps<Request>[] = Object.keys(downloadColumns).map((column) => {
            return {
                key: column,
                title: t(column),
                dataIndex: column,
                ellipsis: true,
                width: getWidth(column),
                render: (
                    text: string | Record<string, string> | Moment,
                    row: Request,
                    index: number,
                ) => renderColumn(text, row, column, index),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                rowKey={(row: Request) => JSON.stringify(row)}
                loading={loading}
                columns={getColumns()}
                dataSource={requests}
                pagination={false}
                className={classes.table}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px - 125px)) - ${bannerHeight}px)`,
                    x: true,
                }}
            />
        );
    };

    return (
        <div className={classes.main} style={{ height: `calc((100vh - ${NAVBAR_HEIGHT}px) - ${bannerHeight}px)` }}>
            {renderTitle()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master, uiTheme } = state;
    return {
        master,
        uiTheme,
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: downloadPageStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const DownloadPage = GenericHoc(hocConfig)(Downloads);
export default DownloadPage;
