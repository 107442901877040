import * as React from 'react';
import GenericHoc from '../generic-hoc';
import CreateModal from '../../create-consignment/create-modal';
import ConsignmentsIcon from '../../../assets/consignments';
import WalletIcon from '../../../assets/wallet';
import BulkConsignments from '../../create-consignment/bulk-consignments';
import PincodeService from '../../pincode-service/service-availability';
import TrackConsignment from '../../track-consignment/track-consignment';
import LocationSearch from '../../location-search/location-search';
import CreatePickup from '../../pickup/create-pickup/create-pickup';
import { Routes } from '../../../library/constants';
import {
    getAddressBookRoute,
    getConsignmentsRoute,
    getDownloadsRoute,
    getHeaderMappingRoute,
    getPickupRoute,
    getVirtualInventoryRoute,
    getWalletLedgerRoute,
    getAdvanceShippingNoteRoute,
    getAnalyticsRoute,
    getRtoDashboardRoute,
    getRemittanceReportsRoute,
    getAdditionalServicesRoute,
    getAPIPlaygroundRoute,
    getExcelUploadRoute,
    getPluginOrdersRoute,
    getPrintHistoryRoute,
    getInvoiceReportsRoute,
    getWeightMismatchRoute,
    getBlockedLedgerRoute,
    getPieceHeaderMappingRoute,
    getProductMappingRoute,
    getSettingsChannelRoute,
    getSettingsAccountRoute,
    getRateCalculatorRoute,
    getPincodeServiceabilityRoute,
    getSenderManagementRoute,
    getUserManagement,
} from '../../../routing/routing-helper';
import { RouteChildrenProps } from 'react-router';
import { CreateActions } from '../../create-consignment/create-modal.constants';
import { StylesProps } from '../../../theme/jss-types';
import { bindActionCreators } from 'redux';
import { HocOptions } from '../generic-hoc.types';
import { loadInitialData } from '../../../actions/load-initial-data';
import {
    DownloadOutlined,
    PlusOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    PlayCircleOutlined,
    ThunderboltOutlined,
    HomeOutlined,
} from '@ant-design/icons';
import { Pincodes } from '../../../library/Types';
import SuccessPopup from '../../create-consignment/success-popup';
import moment from 'moment';
import {
    Avatar,
    Button,
    Divider,
    Menu,
    message,
    Select,
} from 'antd';
import { loadConsignments } from '../../../actions/consignment-actions';
import { Master } from 'types/master-data-types';
import { printConsignment, getChildList, loginToChildCustomer } from 'network/consignments.api';
import Helper from 'library/Helper';
import { loadPickups } from 'actions/pickup-actions';
import BulkPickups from 'components/pickup/create-pickup/bulk-pickups';
import FailureList from '../../consignments/cancellation-failure';
import { useTranslation } from 'react-i18next';
import SingleHyperlocal from '../../create-hyperlocal/single-hyperlocal';
import SingleConsignmentNew from 'components/create-consignment/single-cn-creation/single-consignment-new';
import SingleConsignment from 'components/create-consignment/single-consignment';
import SubMenu from 'antd/lib/menu/SubMenu';
import InformationCetreIcon from 'assets/information-center';
import { MenuItemSelected, SubmenuItems } from './side-menu.constants';
import { sideMenuStyles } from './side-menu.styles';
import classNames from 'classnames';
import { storeExpandingState } from 'actions/miscellaneous-actions';
import { OlvyWidget } from '@olvyhq/widget-react';

interface SideMenuProps extends RouteChildrenProps, StylesProps<ReturnType<typeof sideMenuStyles>> {
    loadconsignments: () => void;
    loadpickups: () => void;
    loadinitial: () => void;
    master: Master;
    isRTL: boolean;
    showCreate: boolean;
    setCreateVisible: (isVisible: boolean) => void;
    showGetStartedModal: () => void;
    setStateForExpandAction: (state: Record<any, any>) => void;
    routesInfo: Record<any, any>;
}

const SideMenu = (props: SideMenuProps) => {
    const {
        classes,
        location,
        history,
        loadconsignments,
        loadpickups,
        loadinitial,
        master,
        isRTL,
        showCreate,
        setCreateVisible,
        showGetStartedModal,
        setStateForExpandAction,
        routesInfo,
    } = props;
    const [isMenuCollapsed, setMenuCollapsed] = React.useState<boolean>(true);
    const [createVisible, setCreateVisibility] = React.useState<boolean>(false);
    const [current, setCurrent] = React.useState<MenuItemSelected>({ key: Routes.CONSIGNMENTS });
    const [pincodes, setPincodes] = React.useState<Pincodes>({});
    const [referenceNumber, setReferenceNumber] = React.useState<string>();
    const [formVisible, setFormVisible] = React.useState<CreateActions | undefined>(
        // CreateActions.SingleConsignment,
    );
    const customerProperties = master?.properties;
    const freshdeskJWTSSOSPInitiationUrl = master?.config?.freshdesk_customer_login_sp_request_url;
    const [successVisible, setSuccessVisible] = React.useState<any>({});
    const phoneRegex = master?.parts_to_show?.remove_regex ? null : master?.config?.phone_regex;
    const storage = window.localStorage;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    let useThirdPartyLabel = false;
    let showWeightMismatch = true;
    if (customerProperties && enable_customer_config) {
        useThirdPartyLabel = customerProperties?.use_third_party_label;
        showWeightMismatch = customerProperties?.weight_mismatch;
    }
    const currentRoute = Helper.getCurrentRoute();
    const { t } = useTranslation();
    const logoUrl = master?.Customer?.logo_url;
    const menuItemList = [
        {
            key: Routes.ANALYTICS,
            icon: <HomeOutlined />,
            label: t('Overview'),
        }, {
            key: SubmenuItems.SHIPMENT,
            icon: <ConsignmentsIcon />,
            label: t(SubmenuItems.SHIPMENT),
            is_visible: true,
            isSubmenu: true,
            className: 'shipment-details',
            children: [
                {
                    key: Routes.CONSIGNMENTS,
                    label: t('consignments'),
                },
                {
                    key: Routes.PICKUPS,
                    label: t('pickups'),
                },
                {
                    key: Routes.PLUGIN_ORDERS,
                    label: t('Plugin Orders'),
                },
                {
                    key: Routes.EXCEL_UPLOAD,
                    label: t('Excel Uploaded'),
                },
                {
                    key: Routes.PRINT_HISTORY,
                    label: t('Print History'),
                },
                {
                    key: Routes.ASN,
                    label: t('ASN'),
                },
                {
                    key: Routes.ADDITIONAL_SERVICES,
                    label: t('supplementary_services'),
                },
                {
                    key: Routes.RTO_DASHBOARD,
                    label: t('RTO Dashboard'),
                },
            ],
        }, {
            key: SubmenuItems.FINANCES,
            icon: <WalletIcon />,
            label: t(SubmenuItems.FINANCES),
            is_visible: true,
            isSubmenu: true,
            className: 'wallet-details',
            children: [
                {
                    key: Routes.INVOICE_REPORT,
                    label: t('Invoices'),
                },
                {
                    key: Routes.REMITTANCE_REPORT,
                    label: t('COD Remittance'),
                },
                {
                    key: Routes.WALLET_LEDGER,
                    label: t('Wallet ledger statement'),
                },
                {
                    key: Routes.WALLET_WEIGHT_MISMATCH,
                    label: t('Weight mismatch'),
                },
                {
                    key: Routes.BLOCKED_LEDGER,
                    label: t('Blocked Amount Ledger'),
                },
            ],
        }, {
            key: SubmenuItems.SETTINGS,
            icon: <SettingOutlined />,
            label: t(SubmenuItems.SETTINGS),
            is_visible: true,
            isSubmenu: true,
            className: 'profile-settings',
            children: [
                {
                    key: Routes.VIRTUAL_INVENTORY,
                    label: t('Virtual Inventory'),
                },
                {
                    key: Routes.SETTINGS_HEADER,
                    label: t('Header Mapping'),
                },
                {
                    key: Routes.SETTINGS_PIECE_HEADER,
                    label: t('Piece Header Mapping'),
                },
                {
                    key: Routes.SETTINGS_PRODUCT,
                    label: t('Product Code Mapping'),
                },
                {
                    key: Routes.SETTINGS_CHANNEL,
                    label: t('Channel Integrations'),
                },
                {
                    key: Routes.SETTINGS_ACCOUNT,
                    label: t('Account Settings'),
                },
                {
                    key: Routes.CONSIGNOR_MANAGEMENT,
                    label: t('Consignor Admin'),
                },
                {
                    key: Routes.USER_MANAGEMENT,
                    label: t('User Management'),
                },
            ],
        }, {
            key: Routes.DOWNLOADS,
            icon: <DownloadOutlined />,
            label: t('downloads'),
            is_visible: true,
        }, {
            key: SubmenuItems.INFORMATION,
            icon: <InformationCetreIcon />,
            label: t(SubmenuItems.INFORMATION),
            is_visible: true,
            isSubmenu: true,
            children: [
                {
                    key: Routes.RATE_CALCULATOR,
                    label: t('Rate Calculator'),
                },
                {
                    key: Routes.PINCODE_SERVICEABILITY,
                    label: t('Pincode Serviceability'),
                },
                {
                    key: Routes.ADDRESS_BOOK,
                    label: t('Address Book'),
                },
                {
                    key: Routes.API_PLAYGROUND,
                    label: t('API Playground'),
                },
            ],
        },
    ];

    const [failures, setFailures] = React.useState<any>({
        visible: false,
        list: [],
    });

    const [childClients, setChildClients] = React.useState<any>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(window.localStorage.getItem('childCustomerCode'));

    const allow_child_login_on_parent = master?.parts_to_show?.allow_child_login_on_parent || false;

    const loadChildCustomer = async () => {
        const result = await getChildList();
        setChildClients(result?.data || []);
        const customer = result?.data?.length ? result.data[0].customer_code
            : window.localStorage.getItem('childCustomerCode');
        setSelectedCustomer(customer);
    };
    const [openedSubMenu, setOpenedSubMenu] = React.useState<Array<string>>([]);


    const handleCloseForm = () => {
        setFormVisible(undefined);
        setPincodes({});
    };

    React.useEffect(() => {
        // const currentPage = getCurrentPage(location);
        let currentPage: MenuItemSelected | undefined;
        switch (Helper.getCurrentRoute()) {
            case Routes.ANALYTICS:
                currentPage = {
                    key: Routes.ANALYTICS,
                };
                break;
            case Routes.PICKUPS:
                currentPage = {
                    key: Routes.PICKUPS,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.EXCEL_UPLOAD:
                currentPage = {
                    key: Routes.EXCEL_UPLOAD,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.PLUGIN_ORDERS:
                currentPage = {
                    key: Routes.PLUGIN_ORDERS,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.PRINT_HISTORY:
                currentPage = {
                    key: Routes.PRINT_HISTORY,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.ASN:
                currentPage = {
                    key: Routes.ASN,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.ADDITIONAL_SERVICES:
                currentPage = {
                    key: Routes.ADDITIONAL_SERVICES,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.RTO_DASHBOARD:
                currentPage = {
                    key: Routes.RTO_DASHBOARD,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
            case Routes.INVOICE_REPORT:
                currentPage = {
                    key: Routes.INVOICE_REPORT,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.FINANCES,
                };
                break;
            case Routes.REMITTANCE_REPORT:
                currentPage = {
                    key: Routes.REMITTANCE_REPORT,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.FINANCES,
                };
                break;
            case Routes.WALLET_LEDGER:
                currentPage = {
                    key: Routes.WALLET_LEDGER,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.FINANCES,
                };
                break;
            case Routes.WALLET_WEIGHT_MISMATCH:
                currentPage = {
                    key: Routes.WALLET_WEIGHT_MISMATCH,
                    isSubmenu: showWeightMismatch,
                    submenuKey: SubmenuItems.FINANCES,
                };
                break;
            case Routes.BLOCKED_LEDGER:
                currentPage = {
                    key: Routes.BLOCKED_LEDGER,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.FINANCES,
                };
                break;
            case Routes.VIRTUAL_INVENTORY:
                currentPage = {
                    key: Routes.VIRTUAL_INVENTORY,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.SETTINGS_HEADER:
                currentPage = {
                    key: Routes.SETTINGS_HEADER,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.SETTINGS_PIECE_HEADER:
                currentPage = {
                    key: Routes.SETTINGS_PIECE_HEADER,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.SETTINGS_PRODUCT:
                currentPage = {
                    key: Routes.SETTINGS_PRODUCT,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.SETTINGS_CHANNEL:
                currentPage = {
                    key: Routes.SETTINGS_CHANNEL,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.API_PLAYGROUND:
                currentPage = {
                    key: Routes.API_PLAYGROUND,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.SETTINGS_ACCOUNT:
                currentPage = {
                    key: Routes.SETTINGS_ACCOUNT,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.DOWNLOADS:
                currentPage = {
                    key: Routes.DOWNLOADS,
                };
                break;
            case Routes.ADDRESS_BOOK:
                currentPage = {
                    key: Routes.ADDRESS_BOOK,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.INFORMATION,
                };
                break;
            case Routes.RATE_CALCULATOR:
                currentPage = {
                    key: Routes.RATE_CALCULATOR,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.INFORMATION,
                };
                break;
            case Routes.PINCODE_SERVICEABILITY:
                currentPage = {
                    key: Routes.PINCODE_SERVICEABILITY,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.INFORMATION,
                };
                break;
            case Routes.CONSIGNOR_MANAGEMENT:
            case Routes.SELLER_DETAILS:
                currentPage = {
                    key: Routes.CONSIGNOR_MANAGEMENT,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.USER_MANAGEMENT:
                currentPage = {
                    key: Routes.USER_MANAGEMENT,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SETTINGS,
                };
                break;
            case Routes.CONSIGNMENTS:
            default:
                currentPage = {
                    key: Routes.CONSIGNMENTS,
                    isSubmenu: true,
                    submenuKey: SubmenuItems.SHIPMENT,
                };
                break;
        }
        setCurrent(currentPage || current);
    }, [location]);

    React.useEffect(() => {
        loadChildCustomer();
    }, []);

    const getRoute = (action: any) => {
        switch (action.key) {
            case 'pickups': return getPickupRoute();
            case 'virtual-inventory': return getVirtualInventoryRoute();
            case 'downloads': return getDownloadsRoute();
            case 'wallet': return getWalletLedgerRoute();
            case 'settings': return getHeaderMappingRoute();
            case 'asn': return getAdvanceShippingNoteRoute();
            case 'analytics': return getAnalyticsRoute();
            case 'api-playground': return getAPIPlaygroundRoute();
            case 'address-book': return getAddressBookRoute();
            case 'rto-dashboard': return getRtoDashboardRoute();
            case Routes.ADDITIONAL_SERVICES: return getAdditionalServicesRoute();
            case Routes.EXCEL_UPLOAD: return getExcelUploadRoute();
            case Routes.PLUGIN_ORDERS: return getPluginOrdersRoute();
            case Routes.PRINT_HISTORY: return getPrintHistoryRoute();
            case Routes.INVOICE_REPORT: return getInvoiceReportsRoute();
            case Routes.REMITTANCE_REPORT: return getRemittanceReportsRoute();
            case Routes.WALLET_WEIGHT_MISMATCH: return getWeightMismatchRoute();
            case Routes.WALLET_LEDGER: return getWalletLedgerRoute();
            case Routes.BLOCKED_LEDGER: return getBlockedLedgerRoute();
            case Routes.SETTINGS_HEADER: return getHeaderMappingRoute();
            case Routes.SETTINGS_PIECE_HEADER: return getPieceHeaderMappingRoute();
            case Routes.SETTINGS_PRODUCT: return getProductMappingRoute();
            case Routes.SETTINGS_CHANNEL: return getSettingsChannelRoute();
            case Routes.SETTINGS_ACCOUNT: return getSettingsAccountRoute();
            case Routes.RATE_CALCULATOR: return getRateCalculatorRoute();
            case Routes.PINCODE_SERVICEABILITY: return getPincodeServiceabilityRoute();
            case Routes.CONSIGNOR_MANAGEMENT: return getSenderManagementRoute();
            case Routes.USER_MANAGEMENT: return getUserManagement();
            case 'consignments':
            default: return getConsignmentsRoute();
        }
    };
    const handleTabChange = (action: any) => {
        if (currentRoute === Routes.PINCODE_SERVICEABILITY || currentRoute === Routes.RATE_CALCULATOR) {
            setStateForExpandAction({});
        }
        if (action.key === 'RTODashboard') return;
        const route = getRoute(action);
        history.push(route);
    };

    const closeModal = () => {
        setCreateVisibility(false);
        setCreateVisible(false);
    };

    const handleCreate = (e: any, action: CreateActions) => {
        e.stopPropagation();
        setFormVisible(action);
        closeModal();
    };

    const renderCreateModal = () => {
        if (!createVisible && !showCreate) {
            return null;
        }
        return (
            <CreateModal
                onClose={() => closeModal()}
                setPincodes={setPincodes}
                setReferenceNumber={setReferenceNumber}
                pincodes={pincodes}
                referenceNumber={referenceNumber}
                handleCreate={(e: any, action: CreateActions) => handleCreate(e, action)}
                isRTL={isRTL}
            />
        );
    };

    const renderButton = () => {
        return (
            <Button
                style={{
                    // width: isMenuCollapsed ? 32 : '100%',
                    width: '100%',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    setOpenedSubMenu([]);
                    setMenuCollapsed(true);
                    setCreateVisibility(true);
                }}
                className={classNames(classes.addNewButton, 'new-booking')}
            >
                <PlusOutlined style={{ color: '#111111' }} className={classes.buttonIcon} />
                {isMenuCollapsed ? '' : t('new_request')}
                {renderCreateModal()}
            </Button>
        );
    };

    const renderSupportButton = () => {
        return (
            <Button
                onClick={() => window.open(freshdeskJWTSSOSPInitiationUrl, '_blank')}
                className={classes.supportButton}
            >
                <QuestionCircleOutlined style={{ color: '#111111' }} className={classes.buttonIcon} />
                {isMenuCollapsed ? '' : t('Help & Support')}
            </Button>
        );
    };

    const renderTourButton = () => {
        return (
            <Button
                onClick={() => {
                    showGetStartedModal();
                }}
                className={classes.tourButton}
            >
                <PlayCircleOutlined className={classes.buttonIcon} />
                {isMenuCollapsed ? '' : t('Get Started')}
            </Button>
        );
    };

    const handleChildCustomerChange = async (val:any) => {
        const parentCode = storage.getItem('userCode') || null;
        storage.setItem('isChildCustomer', 'false');
        // if customer is child customer , call login to child customer api
        if (val !== parentCode) {
            const login_obj = {
                customer_code: val,
                parent_customer_id: storage.getItem('userId'),
            };
            storage.setItem('isChildCustomer', 'true');
            const res = await loginToChildCustomer(login_obj);
            if (res.isSuccess) {
                if (res?.data?.Customer) {
                    storage.setItem('childCustomerId', res.data.Customer.id);
                    storage.setItem('childCustomerCode', res.data.Customer.code);
                    storage.setItem('childCustomerUserId', res?.data?.CustomerUserDetails?.id);
                }
                if (res?.data?.access_token) {
                    storage.setItem('childAccessToken', res.data.access_token.id);
                }
                loadinitial();
                loadconsignments();
            } else {
                message.error(res.errorMessage);
            }
        } else {
            const userId = storage.getItem('userId') || '';
            const token = storage.getItem('authToken') || '';
            const userCode = storage.getItem('userCode') || '';
            const customerUserId = storage.getItem('customerUserId') || '';
            storage.setItem('isChildCustomer', 'false');
            storage.setItem('childCustomerId', userId);
            storage.setItem('childCustomerCode', userCode);
            storage.setItem('childAccessToken', token);
            storage.setItem('childCustomerUserId', customerUserId);
            loadinitial();
            loadconsignments();
        }
    };

    const renderDropdown = () => {
        if (!allow_child_login_on_parent) {
            return null;
        }
        const optionList = childClients?.map((item: any) => {
            return {
                value: item.customer_code,
                label: item.customer_name,
            };
        });
        return (
            <>
                <div className={classes.customerSwitch}>
                    <div className={classes.customerLogo}>
                        <Avatar
                            size="small"
                            shape="square"
                            src={logoUrl || undefined}
                        >
                            {logoUrl ? null : (selectedCustomer?.[0] || 'U')?.toUpperCase()}
                        </Avatar>
                    </div>
                    <Select
                        placeholder="Select Account"
                        style={{ display: isMenuCollapsed ? 'none' : '' }}
                        dropdownMatchSelectWidth={false}
                        className={classes.childDropdown}
                        defaultValue={selectedCustomer}
                        onChange={handleChildCustomerChange}
                        showSearch
                        allowClear
                        options={optionList}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                        bordered={false}
                    />
                </div>
                <Divider type="horizontal" className={classes.divider} />
            </>
        );
    };

    const handleCreation = (action: CreateActions, data: any) => {
        setFormVisible(undefined);
        setSuccessVisible({
            action,
            data,
            creationTime: moment().format('DD MMM YYYY | hh:mm A'),
        });
    };

    const renderSingleConsignment = () => {
        if (formVisible !== CreateActions.SingleConsignment && formVisible !== CreateActions.FTLHyperlocal) {
            return null;
        }
        const isConfigEnabled = master.config?.customer_portal_order_creation_config?.is_enabled;
        return (
            isConfigEnabled
                ? (
                    <SingleConsignmentNew
                        config={master}
                        onClose={handleCloseForm}
                        setSuccess={(data: any) => {
                            handleCreation(CreateActions.SingleConsignment, data);
                        }}
                        loadconsignments={loadconsignments}
                        isRTL={isRTL}
                        actionType={formVisible}
                    />
                )
                : (
                    <SingleConsignment
                        config={master}
                        onClose={handleCloseForm}
                        setSuccess={(data: any) => {
                            handleCreation(CreateActions.SingleConsignment, data);
                        }}
                        loadconsignments={loadconsignments}
                        actionType={formVisible}
                        isRTL={isRTL}
                    />
                )
        );
    };

    const renderBulkConsignment = () => {
        if (formVisible !== CreateActions.BulkConsignment) {
            return null;
        }
        return (
            <BulkConsignments
                onClose={handleCloseForm}
                config={{
                    name: t('add_consignment'),
                    uploadName: t('consignments'),
                }}
                allowInternational={master?.parts_to_show?.allow_international_booking || false}
                showServiceTypeOption={
                    master?.config?.customer_portal_config?.download_static_international_sample_file || false
                }
                itemArrayInDomestic={master?.parts_to_show?.enable_piece_items_detail || false}
            />
        );
    };

    const renderPincodeService = () => {
        if (formVisible !== CreateActions.PincodeAvailability) {
            return null;
        }
        return (
            <PincodeService
                pincodes={pincodes}
                onClose={handleCloseForm}
                shouldRenderInDrawer
            />
        );
    };

    const renderTrack = () => {
        if (formVisible !== CreateActions.Track) {
            return null;
        }
        return (
            <TrackConsignment
                referenceNumber={referenceNumber}
                onClose={handleCloseForm}
            />
        );
    };

    const renderLocationSearch = () => {
        if (formVisible !== CreateActions.LocationSearch) {
            return null;
        }
        return (
            <LocationSearch
                onClose={handleCloseForm}
            />
        );
    };

    const renderSinglePickup = () => {
        if (formVisible !== CreateActions.SinglePickup) {
            return null;
        }
        return (
            <CreatePickup
                onClose={handleCloseForm}
                loadpickups={loadpickups}
                setSuccess={(data: any) => {
                    setFormVisible(undefined);
                    message.success(`Pickup Created, PickupId = ${data.pickupId}`);
                }}
                phoneRegex={phoneRegex}
                config={master?.config}
            />
        );
    };

    const renderBulkPickup = () => {
        if (formVisible !== CreateActions.BulkPickup) {
            return null;
        }
        return (
            <BulkPickups
                onClose={handleCloseForm}
                config={{
                    name: t('add_bulk_pickup'),
                    uploadName: t('bulk_pickup'),
                }}
                success={() => {
                    loadpickups();
                    history.push('/pickups');
                }}
                setFailure={(failureList: any) => {
                    setFormVisible(undefined);
                    setFailures({
                        visible: true,
                        list: failureList,
                        referenceNumberTitle: 'row',
                    });
                }}
            />
        );
    };

    const handlePrintLabel = (fileBuffer: any, fileName: string) => {
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Failed to generate PDF!');
        }
    };

    const handlePrint = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        const fileBuffer = await printConsignment({
            isSmall,
            referenceNumbers,
            useThirdPartyLabel,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handleProtipActions = (action: string) => {
        if (action === 'view') {
            setSuccessVisible({});
            history.push(getConsignmentsRoute());
            loadconsignments();
        }
        if (action === 'print') {
            const refNumber = successVisible.data.referenceNumber;
            handlePrint([refNumber], true, `Shipping_Label_${refNumber}.pdf`);
        }
        if (action === 'close') {
            setSuccessVisible({});
        }
        if (action === 'addSuppServices') {
            setSuccessVisible({});
            localStorage.setItem('supplementaryServiceConsignmentReferenceNumber', successVisible.data.referenceNumber);
            history.push(getAdditionalServicesRoute());
        }
    };

    const renderSuccess = () => {
        if (!successVisible.action) {
            return null;
        }
        return (
            <SuccessPopup
                // successArray={[1, 2]}
                // failureArray={[1, 2]}
                action={successVisible.action}
                data={successVisible.data}
                creationTime={successVisible.creationTime}
                handleAction={handleProtipActions}
                showSupplementaryServices={
                    master?.config?.customer_portal_order_creation_config?.show_supplementary_services
                }
            />
        );
    };

    const renderSingleHyperlocal = () => {
        if (formVisible !== CreateActions.SingleHyperlocal) {
            return null;
        }
        return (
            <SingleHyperlocal
                // config={master}
                onClose={handleCloseForm}
                visible
                // setSuccess={(data: any) => {
                //     handleCreation(CreateActions.SingleHyperlocal, data);
                // }}
                loadconsignments={loadconsignments}
            />
        );
    };

    const renderBulkHyperlocal = () => {
        if (formVisible !== CreateActions.BulkHyperlocal) {
            return null;
        }
        return null;
        // return (
        //     <BulkHyperlocal
        //         onClose={handleCloseForm}
        //         config={{
        //             name: t('add_consignment'),
        //             uploadName: t('consignments'),
        //         }}
        //         allowInternational={master?.parts_to_show?.allow_international_booking || false}
        //         showServiceTypeOption={
        //             master?.config?.customer_portal_config?.download_static_international_sample_file || false
        //         }
        //         itemArrayInDomestic={master?.parts_to_show?.enable_piece_items_detail || false}
        //     />
        // );
    };

    const renderForms = () => {
        if (!formVisible) {
            return null;
        }
        return (
            <>
                {renderSingleHyperlocal()}
                {renderBulkHyperlocal()}
                {renderSingleConsignment()}
                {renderBulkConsignment()}
                {renderSinglePickup()}
                {renderBulkPickup()}
                {renderPincodeService()}
                {renderTrack()}
                {renderLocationSearch()}
                {renderCreateModal()}
            </>
        );
    };

    // const openWindowWithPostRequest = () => {
    //     const customerCode = storage.getItem('userCode');
    //     if (!RTODashboardURL || !customerCode) {
    //         return;
    //     }
    //     const winName = 'RTO Dashboard';
    //     const winURL = `${RTODashboardURL}?custcode=${customerCode}`;
    //     const windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
    //     const form = document.createElement('form');
    //     form.setAttribute('method', 'post');
    //     form.setAttribute('action', winURL);
    //     form.setAttribute('target', winName);
    //     document.body.appendChild(form);
    //     window.open('', winName, windowoption);
    //     form.target = winName;
    //     form.submit();
    //     document.body.removeChild(form);
    // };

    const renderMenu = () => {
        let topMenuItem = master?.config?.top_menu_item?.filter(Helper.onlyUnique);

        if (!Helper.isValidArray(topMenuItem)) {
            topMenuItem = [];
        }
        const topmenuItems = topMenuItem?.map((item) => item.toLowerCase()) || [];

        let priorityMenuItems = topmenuItems?.map((item) => {
            const menuItem = menuItemList.find((menu) => menu.key === item);
            return menuItem;
        }) || [];
        priorityMenuItems = priorityMenuItems.filter((item) => item);
        const remainingMenuItems = menuItemList.filter((item) => !topmenuItems?.includes(item.key)) || [];

        const finalmenuItemList = [...priorityMenuItems, ...remainingMenuItems];

        return (
            <>
                {finalmenuItemList.map((item: any) => {
                    if (item.isSubmenu) {
                        const submenuItemList = item.children.map((child: any) => {
                            if (routesInfo[child.key] && routesInfo[child.key]?.isProtected
                                && !routesInfo[child.key].isVisible) {
                                return null;
                            }
                            return (
                                <Menu.Item
                                    key={child.key}
                                    className={child.className}
                                >
                                    {child.label}
                                </Menu.Item>
                            );
                        }).filter((child) => child !== null);
                        if (submenuItemList.length === 0) {
                            return null;
                        }
                        return (
                            <SubMenu
                                key={item.key}
                                icon={item.icon}
                                title={item.label}
                                className={classNames(classes.SubMenu, item.className || '')}
                                onTitleClick={() => {
                                    if (openedSubMenu.includes(item.key)) {
                                        setOpenedSubMenu([]);
                                    } else {
                                        setOpenedSubMenu([item.key]);
                                    }
                                }}
                            >
                                {submenuItemList}
                            </SubMenu>
                        );
                    }
                    if (routesInfo[item.key] && routesInfo[item.key]?.isProtected
                        && !routesInfo[item.key].isVisible) {
                        return null;
                    }
                    return (
                        <Menu.Item
                            key={item.key}
                            icon={item.icon}
                            className={item.className}
                        >
                            {item.label}
                        </Menu.Item>
                    );
                })}
            </>
        );
    };


    // const renderBottomMenu = () => {
    //     const oldBaseURL = master?.config?.customer_portal_config?.old_cp_base_url;
    //     const show_old_portal_link = master?.config?.customer_portal_config?.show_old_portal_link;
    //     let languageList: any = [];
    //     const { config } = master;
    //     const { customer_portal_ops_config, available_language_list } = config;
    //     const isLanguageChangeAllowed =
    //         customer_portal_ops_config?.language_config?.config_value?.is_language_change_allowed
    //      || false;
    //     if (customer_portal_ops_config?.language_config?.config_value) {
    //         const allowed_language = customer_portal_ops_config.language_config
    //             ?.config_value?.allowed_language_list_customer_portal || [];
    //         if (Array.isArray(available_language_list) && Array.isArray(allowed_language)
    //              && available_language_list.length > 0 && allowed_language.length > 0) {
    //             languageList = available_language_list
    //                 .filter((item: any) => allowed_language.includes(item.language_name));
    //         }
    //     }
    //     return (
    //         <>
    //             {
    //                 isLanguageChangeAllowed
    //                 && (
    //                     <div>
    //                         {
    //                             isMenuCollapsed ? (
    //                                 <div className={classes.selectedLanguageIcon}>
    //                                     {i18n.language}
    //                                 </div>
    //                             ) : (
    //                                 <Select
    //                                     placeholder="Translate x"
    //                                     placement="topRight"
    //                                     defaultValue={languageCodeMapping[i18n.language].prettyName}
    //                                     onChange={handleLanguageChange}
    //                                     style={{
    //                                         width: '150px',
    //                                         borderRadius: '4px',
    //                                         marginLeft: '5%',
    //                                         marginRight: '5%',
    //                                     }}
    //                                 >
    //                                     {languageList?.map((item: any) => {
    //                                         return (
    //                                             <Select.Option
    //                                                 key={item.key}
    //                                                 value={item.key}
    //                                             >
    //                                                 {item.language_name}
    //                                             </Select.Option>
    //                                         );
    //                                     })}
    //                                 </Select>
    //                             )
    //                         }
    //                     </div>
    //                 )
    //             }
    //             {show_old_portal_link && (
    //                 <Menu.Item
    //                     style={{
    //                         color: '#262626',
    //                         fontSize: 10,
    //                         fontWeight: 600,
    //                         letterSpacing: 0,
    //                     }}
    //                     key={Routes.PLUGIN}
    //                     icon={<DeliveredProcedureOutlined />}
    //                     onClick={() => Helper.redirectToOldPortal(oldBaseURL)}
    //                 >
    //                     {t('switch_to_old_portal')}
    //                 </Menu.Item>
    //             )}
    //             {/* <Menu.Item key={Routes.PLUGIN} icon={<PluginIcon />}>
    //             Plugin
    //         </Menu.Item>
    //         <Menu.Item key={Routes.TUTORIAL} icon={<TutorialIcon />}>
    //             Tutorial Video
    //         </Menu.Item> */}
    //         </>
    //     );
    // };

    const renderFailure = () => {
        if (!failures.visible || !failures?.list?.length) {
            return null;
        }
        return (
            <FailureList
                failureList={failures.list}
                onClose={() => setFailures({ visible: false })}
                referenceNumberTitle={failures?.referenceNumberTitle}
            />
        );
    };

    const renderOlvyWidget = () => {
        return (
            <OlvyWidget
                config={{ workspaceAlias: 'shipsy' }}
                targetElement={(
                    <Button
                        className={classes.supportButton}
                        id="olvy-seller"
                    >
                        <ThunderboltOutlined className={classes.buttonIcon} />
                        {isMenuCollapsed ? '' : 'What\'s New?'}
                    </Button>
                )}
            />
        );
    };

    return (
        <>
            <div
                className={classNames(classes.main, 'navigation-menu')}
                style={{ width: isMenuCollapsed ? 56 : 210 }}
                onMouseEnter={() => {
                    if (current.isSubmenu) {
                        setOpenedSubMenu([current.submenuKey]);
                    }
                    setMenuCollapsed(false);
                }}
                onMouseLeave={() => {
                    setOpenedSubMenu([]);
                    setMenuCollapsed(true);
                }}
            >
                <Menu
                    style={{ overflowY: 'auto', overflowX: 'hidden' }}
                    mode="inline"
                    selectable
                    theme="light"
                    selectedKeys={[current.key]}
                    className={classes.sideMenu}
                    onClick={handleTabChange}
                    inlineCollapsed={isMenuCollapsed}
                    openKeys={openedSubMenu}
                >
                    <div style={{ height: 100 }}>
                        {renderDropdown()}
                        {renderButton()}
                    </div>
                    {renderMenu()}
                </Menu>
                <div
                    className={classes.sideMenuBottom}
                >
                    {renderTourButton()}
                    {renderOlvyWidget()}
                    {freshdeskJWTSSOSPInitiationUrl ? renderSupportButton() : null}
                </div>
                {renderForms()}
                {renderSuccess()}
                {renderFailure()}
            </div>
            <div className={classes.dummyClassForSpace} />
        </>
    );
};

const mapStateToProps = (state: any) => {
    const {
        master, navigation, uiTheme, miscellaneousState,
    } = state;
    return {
        master,
        navigation,
        isRTL: uiTheme?.isRTL,
        routesInfo: miscellaneousState?.ROUTES_INFO || {},
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadconsignments: loadConsignments,
        loadpickups: loadPickups,
        loadinitial: loadInitialData,
        setStateForExpandAction: storeExpandingState,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: sideMenuStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(SideMenu);
