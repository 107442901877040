import QRCode from 'qrcode';
import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import Paginator from '../common/paginator';
import moment, { Moment } from 'moment';
import { NextOrPrev, Pagination } from '../../library/Types';
import { Statement, WalletPages } from './wallet.types';
import { RouteChildrenProps } from 'react-router';
import { Routes } from '../../library/constants';
import GenericHoc from '../common/generic-hoc';
import { walletColumns } from './wallet.constants';
import { walletStyles } from './wallet.styles';
import {
    fetchCncData,
    fetchWalletLedger,
    fetchWalletWeightMismatch,
    downloadWalletLedger,
    fetchBlockedLedger,
    downloadBlockedWalletLedger,
} from '../../network/wallet.api';
import WalletIcon from '../../assets/wallet-balance.png';
import {
    Button, DatePicker, message, Spin, Table,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import WalletFaq from './wallet-faq';
import Helper from 'library/Helper';
import { ReduxStore } from 'reducers/redux.types';
import {
    Master,
} from '../../types/master-data-types';
import { NAVBAR_HEIGHT } from 'library/globals';
import { storeCNCDataState } from 'actions/miscellaneous-actions';
import { bindActionCreators } from 'redux';

interface WalletProps
    extends StylesProps<ReturnType<typeof walletStyles>>, RouteChildrenProps {
    uiTheme: ThemeType;
    master: Master;
    setCNCData: (state: Record<any, any>) => void;
}

const {
    useEffect,
} = React;

const WalletPage = (props: WalletProps) => {
    const {
        classes,
        history,
        master,
        uiTheme,
        setCNCData,
    } = props;

    const [rechargeVisible, setRechargeVisible] = React.useState(false);
    const [faqVisible, setFaqVisible] = React.useState(false);
    const [qrCode, setQrcode] = React.useState<string>('');
    const customerProperties = master?.properties;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    let showWallet = true;
    if (customerProperties && enable_customer_config) {
        showWallet = customerProperties?.Wallet;
    }

    const pageSelected = React.useMemo((): WalletPages => {
        if (!showWallet) {
            return 'WEIGHT_MISMATCH';
        }
        switch (history.location.pathname) {
            case `/${Routes.WALLET_LEDGER}`: return 'WALLET_LEDGER';
            case `/${Routes.BLOCKED_LEDGER}`: return 'BLOCKED_LEDGER';
            case `/${Routes.WALLET_WEIGHT_MISMATCH}`:
            default: return 'WEIGHT_MISMATCH';
        }
    }, []);

    const [toggle, setToggle] = React.useState<boolean>(true);
    const [statement, setStatement] = React.useState<Statement[]>([]);
    const [mismatchStatement, setMismatchStatement] = React.useState<Statement[]>([]);
    const [blockedStatement, setBlockedStatement] = React.useState<Statement[]>([]);
    const [cnc, setCnc] = React.useState<Record<string, string>>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState({
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
    });
    const [pagination, setPagination] = React.useState<Pagination>({
        currentPageNumber: 1,
        resultPerPage: 100,
        nextOrPrev: 'first',
        isNextPresent: false,
    });

    const loadWalletLedger = async () => {
        setLoading(true);
        const response = await fetchWalletLedger({
            fromDate: moment(filters.startDate).format('DD-MM-YYYY'),
            toDate: moment(filters.endDate).format('DD-MM-YYYY'),
        });
        setStatement(response?.data?.data || []);
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };
    const loadWeightMismatch = async () => {
        setLoading(true);
        const response = await fetchWalletWeightMismatch();

        setMismatchStatement(response?.data || []);
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const loadBlockedLedger = async () => {
        setLoading(true);
        const response = await fetchBlockedLedger({
            currentPageNumber: pagination?.currentPageNumber || 1,
            resultPerPage: pagination?.resultPerPage || 100,
            fromDate: moment(filters.startDate).format('DD-MM-YYYY'),
            toDate: moment(filters.endDate).format('DD-MM-YYYY'),
        });

        setBlockedStatement(response?.data?.data || []);

        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const loadStatement = () => {
        if (pageSelected === 'WALLET_LEDGER') {
            loadWalletLedger();
        } else if (pageSelected === 'WEIGHT_MISMATCH') {
            loadWeightMismatch();
        } else {
            loadBlockedLedger();
        }
    };

    const setQR = async (url: string) => {
        const qrcod = await QRCode.toDataURL(url);
        setQrcode(qrcod);
    };

    const loadCnc = async () => {
        const response = await fetchCncData();
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setCnc(response?.data || {});
            // to update the CNC data in redux, for navbar
            setCNCData(response?.data || {});
            setQR(response.data.qr_code);
        }
    };

    useEffect(() => {
        loadCnc();
        loadStatement();
    }, [pageSelected, filters]);

    const renderText = (text: string) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const renderColumn = (text: string, column: any) => {
        if (!text || text === null) {
            return <div className={classes.cellNa}>-</div>;
        }

        if (column === 'date' || column === 'created_at') {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString);
        }

        if (column === 'credit_debit_flag') {
            return renderText(text?.toUpperCase());
        }

        return renderText(text);
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'action_type':
            case 'actions': return 60;
            case 'status':
            case 'reference_number':
            case 'destination_name':
            case 'creation_source':
            case 'customer_reference_number': return 150;
            case 'movement_type':
            default: return 120;
        }
    };

    const getColumns = (): any[] => {
        const columnLables: Record<string, string> = walletColumns[pageSelected];

        const columns: any = Object.keys(columnLables).map((column: string) => {
            return {
                key: column,
                title: columnLables[column],
                dataIndex: column,
                width: getWidth(column),
                ellipsis: true,
                fixed: column === 'actions' ? 'right' : undefined,
                render: (text: string) => renderColumn(text, column),
            };
        });
        return columns;
    };

    const getDataSource = () => {
        switch (pageSelected) {
            case 'WALLET_LEDGER': return statement;
            case 'BLOCKED_LEDGER': return blockedStatement;
            case 'WEIGHT_MISMATCH':
            default: return mismatchStatement;
        }
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                className={classes.table}
                rowKey={(row) => row.reference_number}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>—</div>,
                }}
                scroll={{
                    y: `calc(
                        (((100vh - ${NAVBAR_HEIGHT}px) - 55px) - ${bannerHeight}px) 
                        - ${130}px)`, // Sum of Balance bar height, page filter height and extra filters height
                    // TODO: use ref for more accurate height calculation
                }}
                dataSource={getDataSource() || []}
            />
        );
    };

    const getDisabled = (current: Moment, key: 'startDate' | 'endDate') => {
        if (key === 'startDate') {
            return false;
        }
        return (
            moment(current).diff(filters.startDate, 'days') > 30
            || moment(current) >= moment()
        );
    };

    const renderDateField = (key: 'startDate' | 'endDate') => {
        const name = key === 'startDate' ? 'From' : 'To';
        const inputName = `${name} Date`;
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {inputName}
                </span>
                <DatePicker
                    value={filters[key]}
                    allowClear={false}
                    disabledDate={(current) => getDisabled(current, key)}
                    placeholder={`Select ${inputName}`}
                    onChange={(value) => {
                        const newFilters = {
                            ...filters,
                        };
                        newFilters[key] = moment(value);
                        setFilters(newFilters);
                    }}
                />
            </div>
        );
    };

    const handleDownloadClick = async () => {
        setDownloading(true);
        const params = {
            fromDate: moment(filters.startDate).format('DD-MM-YYYY'),
            toDate: moment(filters.endDate).format('DD-MM-YYYY'),
        };

        let fileBuffer: any = {};
        let fileName = 'Wallet Statement.xlsx';

        if (pageSelected === 'BLOCKED_LEDGER') {
            fileBuffer = await downloadBlockedWalletLedger(params);
            fileName = 'Blocked Wallet Statement.xlsx';
        } else {
            fileBuffer = await downloadWalletLedger(params);
        }

        const finalName = fileBuffer.filename || fileName;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                finalName,
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }

        setDownloading(false);
    };

    const renderDownloadButton = () => {
        return (
            <Button
                onClick={() => handleDownloadClick()}
                type="primary"
                style={{
                    height: 32,
                    fontSize: 12,
                    backgroundColor: uiTheme.primaryColor,
                    border: `1px solid ${uiTheme.primaryColor}`,
                    color: '#FFFFFF',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    letterSpacing: 0,
                    padding: '0px 24px',
                    borderRadius: 25,
                    marginLeft: 5,
                }}
                loading={downloading}
            >
                Download
            </Button>
        );
    };

    const renderLeftFilters = () => {
        if (pageSelected === 'WEIGHT_MISMATCH') {
            return <div />;
        }
        return (
            <div className={classes.leftFilters}>
                {renderDateField('startDate')}
                {renderDateField('endDate')}
                {renderDownloadButton()}
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination || {};
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setPagination(newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination?.currentPageNumber || 1}
                isNextPresent={pagination?.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderPaginator()}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderIcon = () => {
        return (
            <img
                alt="bulk-pickup"
                src={WalletIcon}
                className={classes.img}
            />
        );
    };

    const renderCurrentBalance = () => {
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(Number(cnc.wallet_balance || 0))}
                </div>
                <span className={classes.balanceText}>
                    Total Balance
                </span>
            </div>
        );
    };

    const renderAvailableBalance = () => {
        const blocked = Number(cnc?.blocked_amount || 0);
        const total = Number(cnc?.wallet_balance || 0);
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(total - blocked)}
                </div>
                <span className={classes.balanceText}>
                    Available Balance
                </span>
            </div>
        );
    };

    const renderBlockedBalance = () => {
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(Number(cnc.blocked_amount || 0))}
                </div>
                <span className={classes.balanceText}>
                    Blocked Amount
                </span>
            </div>
        );
    };

    const renderBalances = () => {
        return (
            <div className={classes.balanceDiv}>
                {renderIcon()}
                {renderCurrentBalance()}
                {renderBlockedBalance()}
                {renderAvailableBalance()}
            </div>
        );
    };

    const renderRecharge = () => {
        return (
            <Button
                onClick={() => setRechargeVisible(true)}
                type="primary"
                style={{
                    height: 32,
                    fontSize: 12,
                    backgroundColor: uiTheme.primaryColor,
                    border: `1px solid ${uiTheme.primaryColor}`,
                    color: '#FFFFFF',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    letterSpacing: 0,
                    padding: '0px 24px',
                    marginLeft: 12,
                    borderRadius: 4,
                }}
                disabled={loading || !qrCode}
            >
                Recharge
            </Button>
        );
    };

    const renderFAQsButton = () => {
        return (
            <Button
                onClick={() => setFaqVisible(true)}
                type="primary"
                style={{
                    height: 32,
                    fontSize: 12,
                    backgroundColor: uiTheme.primaryColor,
                    border: `1px solid ${uiTheme.primaryColor}`,
                    color: '#FFFFFF',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    letterSpacing: 0,
                    padding: '0px 24px',
                    borderRadius: 4,
                    marginLeft: 5,
                }}
            >
                Check FAQs
            </Button>
        );
    };

    const renderFAQsText = () => {
        return (
            <span className={classes.faqText}>
                Have doubts regarding your wallet?
            </span>
        );
    };

    const renderFAQs = () => {
        return (
            <div className={classes.faqBox}>
                {renderFAQsText()}
                {renderFAQsButton()}
            </div>
        );
    };

    const renderBalance = () => {
        return (
            <div className={classes.cashNCarry}>
                {renderBalances()}
                <div className={classes.recharge}>
                    {renderFAQs()}
                    {renderRecharge()}
                </div>
            </div>
        );
    };

    const renderWalletPage = () => {
        return (
            <>
                {renderTable()}
            </>
        );
    };

    const renderQrCode = () => {
        if (loading) {
            return null;
        }

        return (
            <img
                id="imgElem"
                style={{
                    height: 'auto',
                    width: '100%',
                }}
                src={qrCode}
                alt="QR"
            />
        );
    };

    const renderRechargeText = () => {
        return (
            <div style={{ fontSize: 12 }}>
                <b>Note: </b>
                Currently, DTDC accepts UPI payments from savings and current accounts.
                UPI payments from RuPay credit cards and Prepaid Payment Instruments (PPIs) are not accepted.
            </div>
        );
    };

    const renderRechargeModal = () => {
        if (!rechargeVisible) {
            return null;
        }
        return (
            <Modal
                className={classes.modal}
                visible
                title="Recharge via UPI"
                onCancel={() => setRechargeVisible(false)}
                footer="Scan the QR Code to recharge your wallet using UPI"
                width="20%"
            >
                {loading && (
                    <Spin
                        style={{
                            marginLeft: '45%',
                            marginTop: 100,
                            marginBottom: 100,
                        }}
                    />
                )}
                {renderQrCode()}
                {renderRechargeText()}
            </Modal>
        );
    };

    const renderFAQModal = () => {
        if (!faqVisible) {
            return null;
        }
        return (
            <WalletFaq
                onCancel={() => setFaqVisible(false)}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderBalance()}
            {/* {renderPageFilter()} */}
            {renderExtraFilters()}
            {renderWalletPage()}
            {renderRechargeModal()}
            {renderFAQModal()}
        </div>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};

const mapDispatchToProps = (dispatch: any) => (bindActionCreators({
    setCNCData: storeCNCDataState,
}, dispatch));

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: walletStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(WalletPage);
