export enum LocalStorageKeys {
    _TABLE_COLUMS_WIDTH_DATA = '_TABLE_COLUMS_WIDTH_DATA',
}

export class LocalStorageService {
    static async set(key: string, value: string | null | undefined): Promise<void> {
        try {
            if (!value) return;
            const dataToSet = value;

            localStorage.setItem(key.toString(), dataToSet);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }

    static exists(key: string): boolean {
        return !!localStorage.getItem(key);
    }

    static getRaw(key: string): string | null {
        return localStorage.getItem(key);
    }

    static async get(key: string): Promise<string | null> {
        try {
            const dataToGet: string | null = localStorage.getItem(key);
            if (!dataToGet) return '';
            return dataToGet;
        } catch (e) {
            if (e instanceof Error) {
                // eslint-disable-next-line no-console
                console.log(e.message);
            } else {
                // eslint-disable-next-line no-console
                console.log(e);
            }
            return null;
        }
    }

    static remove(key: string): void {
        localStorage.removeItem(key);
    }
}
