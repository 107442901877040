interface MenuItemSelectedBase {
    key: string;
}

interface MenuItemWithSubmenu extends MenuItemSelectedBase {
    isSubmenu: true;
    submenuKey: string;
}

interface MenuItemWithoutSubmenu extends MenuItemSelectedBase {
    isSubmenu?: false;
}

export type MenuItemSelected = MenuItemWithSubmenu | MenuItemWithoutSubmenu;

// creating enum for submenu keys
export enum SubmenuItems {
    SHIPMENT = 'Shipments',
    FINANCES = 'Finances',
    SETTINGS = 'Settings',
    INFORMATION = 'Information Centre',
}
