import { ZIndex } from 'library/constants';
import { NAVBAR_HEIGHT } from 'library/globals';
import { ThemeType } from 'theme/jss-types';

export const navbarStyles = (theme: ThemeType) => ({
    mainDiv: {
        width: '100%',
        height: `${NAVBAR_HEIGHT}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
        borderBottom: '1px solid #EFEFEF',
        color: 'black',
        backgroundColor: theme.primaryColor,
        position: 'sticky',
        top: 0,
        zIndex: ZIndex.navbar,
    },
    logoBox: {
        fontSize: 32,
        fontWeight: 600,
        height: '100%',
        alignSelf: 'center',
        color: 'pink',
        margin: 'auto 16px',
        display: 'flex',
        alignItems: 'center',
        // width: '40%',
        '& img': {
            maxHeight: 32,
        },
    },
    rightBox: {
        display: 'flex',
        width: '60%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .ant-input-affix-wrapper': {
            border: 'none',
            margin: '0 2px',
            color: '#DDDFE9 !important',
            backgroundColor: `${theme.tertiaryFillColor} !important`,
        },
        '& .ant-input-affix-wrapper > input.ant-input': {
            fontSize: 12,
            color: '#DDDFE9 !important',
            backgroundColor: `${theme.tertiaryFillColor} !important`,
        },
        '& .ant-btn-link': {
            color: '#FFF',
            fontSize: 12,
            fontWeight: 600,
        },
        '& .ant-input-group-addon': {
            backgroundColor: `${theme.secondaryFillColor} !important`,
            borderColor: `${theme.strokeColor} !important`,
        },
        '& .ant-select': {
            color: '#FCFCFC',
        },
        '& .ant-select-arrow': {
            color: '#FCFCFC',
        },
    },
    leftBox: {
        height: '100%',
        display: 'flex',
        width: '60%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    titleBar: {
        borderColor: `${theme.secondaryFillColor} !important`,
        height: 24,
    },
    pageTitle: {
        color: '#FCFCFC',
        fontWeight: 600,
        fontSize: 14,
    },
    trackInput: {
        backGroundColor: 'green !important',
        width: '180 !important',
        marginRight: '1%',
    },
    trackDropDown: {
        fontSize: 12,
    },
    inputs: {
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        width: '36%',
        margin: '0 2px',
    },
    profile: {
        height: 32,
        width: 32,
        borderRadius: 16,
        border: `1px solid ${theme.strokeColor}`,
        backgroundColor: theme.tertiaryFillColor,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        justifyContent: 'center',
    },
    username: {
        // width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        marginRight: 8,
    },
    name: {
        // width: '70%',
    },
    circle: {
        // width: '25%',
        // marginLeft: 10,
    },
    img: {
        fontSize: 32,
        marginRight: '4%',
        cursor: 'pointer',
        marginBottom: 6,
        width: '4%',
    },
    modal: {
        position: 'absolute',
        marginLeft: '57%',
        top: NAVBAR_HEIGHT,
        '& .ant-modal-content': {
            padding: 24,
            width: 351,
            borderRadius: '0 0 4px 4px',
            backgroundColor: '#F8F2DE',
        },
        '& .ant-modal-body': {
            padding: 0,
        },
        '& .ant-btn-primary': {
            marginLeft: 2,
            color: '#FFF',
            backgroundColor: `${theme.primaryColor} !important`,
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 4,
        },
        '& .ant-input, .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
    },
    pincode: {
        display: 'flex',
        flexDirection: 'column',
    },
    availabilityTitle: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 0,
        marginBottom: 12,
    },
    pincodeText: {
        fontSize: 12,
        marginLeft: '6%',
        fontWeight: 600,
    },
    oldLink: {
        boxSizing: 'border-box',
        maxHeight: '100%',
        color: 'white',
        justifyContent: 'flex-start',
        marginRight: 24,
    },
    rateCalculator: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        minWidth: 'fit-content',
        textAlign: 'center',
        margin: 12,
    },
    quickAction: {
        margin: 'auto 8px',
        backgroundColor: `${theme.secondaryFillColor} !important`,
        fontWeight: 500,
        fontSize: 12,
        color: '#FCFCFC !important',
        borderColor: `${theme.strokeColor} !important`,
    },
    changeLanguageDropdown: {
        '&.ant-select': {
            width: '100% !important',
        },
        '&.ant-select .ant-select-selector': {
            margin: '0 !important',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            border: 'none !important',
        },
        '& .ant-select-selector': {
            width: '100%',
            padding: '0 !important',
            height: '22px !important',
        },
    },
    walletBalance: {
        backgroundColor: `${theme.secondaryFillColor} !important`,
        borderColor: `${theme.strokeColor} !important`,
        color: '#FCFCFC !important',
    },
});
