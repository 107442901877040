import { Tooltip } from 'antd';
import * as React from 'react';
import type { ResizeCallbackData } from 'react-resizable';
import withStyles, { WithStylesProps } from 'react-jss';
import Draggable, { DraggableEvent } from 'react-draggable';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const styles = () => ({
    tooltip: {
        '& .ant-tooltip-inner > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > span': {
            color: '#FFFFFF !important',
        },
    },
});

const CustomTableTitle = (
    props: React.HTMLAttributes<any> & WithStylesProps<typeof styles> & {
        onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
        onResizeStop: (width: number) => void;
        width: number | undefined;
        tableHeight: number;
        resizable: boolean;
        children: React.ReactNode,
        style: React.CSSProperties | undefined,
        isSortable?: boolean,
        onSort: (sortOrder: 'asc' | 'desc') => void;
        sortSelected?: boolean;
        descendingOrder?: boolean;
        toggleWidth: () => void;
        showHeaderTooltip?: boolean;
    },
) => {
    const {
        onResize,
        onResizeStop,
        resizable,
        classes,
        children,
        width,
        tableHeight,
        isSortable,
        onSort,
        style,
        sortSelected,
        descendingOrder,
        toggleWidth,
        showHeaderTooltip,
        ...restProps
    } = props;

    const childRef = React.useRef(null);

    const renderSortIcon = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpOutlined
                    onClick={() => onSort('desc')}
                    style={{ color: sortSelected && descendingOrder ? '#1659CB' : undefined }}
                />
                <CaretDownOutlined
                    onClick={() => onSort('asc')}
                    style={{ color: sortSelected && !descendingOrder ? '#1659CB' : undefined }}
                />
            </div>
        );
    };

    const cellContent = (
        <div
            ref={childRef}
            style={{
                width: '100%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {children}
            {
                isSortable ? renderSortIcon() : <></>
            }
        </div>
    );

    const isTitleResizable = !(!width || !resizable);

    const handleDragStop = (e: {
        preventDefault: () => void;
        stopPropagation: () => void;
    }, data: { lastX: string | number; }) => {
        e.preventDefault();
        e.stopPropagation();
        const draggingSpan = document.getElementById('__dragging_span_for_width_preview__');
        if (draggingSpan) {
            draggingSpan.remove();
        }
        const newWidth = (width ?? 0) + Number(data.lastX) + (Number(data.lastX) > 0 ? 5 : -5);
        setTimeout(() => {
            onResizeStop(newWidth);
            toggleWidth();
        }, 0);
    };

    const handleDrag = (e: DraggableEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const x = (e as MouseEvent).clientX;
        const y = (e as MouseEvent).clientY;
        if (childRef?.current) {
            const draggingSpan = (childRef.current as HTMLElement).parentElement?.querySelector(
                '.columm-resize-handler-dragging',
            );

            if (draggingSpan) {
                let spanElement = document.getElementById('__dragging_span_for_width_preview__');
                const tableElement = draggingSpan.closest('.ant-table-wrapper');
                const tableActualHeight = tableElement?.getBoundingClientRect().height ?? tableHeight;
                if (!spanElement) {
                    spanElement = document.createElement('span');
                    spanElement.style.left = `${x}px`;
                    spanElement.style.top = `${draggingSpan.getBoundingClientRect().top ?? y}px`;
                    spanElement.style.position = 'fixed';
                    spanElement.style.width = '1px';
                    spanElement.style.height = `${tableActualHeight}px`;
                    spanElement.style.backgroundColor = '#333333';
                    spanElement.id = '__dragging_span_for_width_preview__';
                    document.body.appendChild(spanElement);
                } else {
                    spanElement.style.left = `${x}px`;
                }
            }
        }
    };

    const renderResizeHandler = () => {
        return (
            <Draggable
                defaultClassName="columm-resize-handler"
                defaultClassNameDragging="columm-resize-handler-dragging"
                axis="x"
                onDrag={handleDrag}
                onStop={handleDragStop}
            >
                <span onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                />
            </Draggable>
        );
    };

    return (
        <th
            style={{
                ...(style || {}),
                width,
            }}
            {...restProps}
        >
            {showHeaderTooltip
                ? (<Tooltip title={children}>{cellContent}</Tooltip>)
                : cellContent}
            {
                isTitleResizable ? renderResizeHandler() : <></>
            }
        </th>
    );
};

export default React.memo(withStyles(styles, {
    injectTheme: true,
})(CustomTableTitle));
