import { FilterType } from 'library/Types';
import { MasterConfig, ConsignmentBucketKeys } from '../../types/master-data-types';

export const getFilters = (
    bucket: ConsignmentBucketKeys,
    config: MasterConfig,
    dashboardFilterIds?: string[],
) => {
    if (!bucket || !config || !config.filter_list_by_bucket || !config.filter_metadata) {
        return [];
    }
    const mainDashboardFilters:
    string[] = ['statusList', 'customerReferenceNumberList', 'referenceNumberList', 'bookingServiceTypeIdList'];
    let dashboardFilters = config?.filter_list_by_bucket[bucket];
    dashboardFilters = dashboardFilters.filter((filter) => (filter.show_on_main_dashboard));
    dashboardFilters = dashboardFilters.filter((filter) => !mainDashboardFilters.includes(filter.id));
    const filterIdList = dashboardFilterIds || dashboardFilters.map((filter) => (filter.id));
    filterIdList.push('cncFlag');

    const filtersToShow = config.filter_metadata.filter((filter) => filterIdList.includes(filter.id));
    filtersToShow.push({
        id: 'cncFlag',
        mode: undefined,
        label: 'C&C Flag',
        placeholder: 'C&C Flag',
        type: FilterType.select,
        options: [{
            label: 'ALL',
            value: 'all',
        }, {
            label: 'True',
            value: 'true',
        }, {
            label: 'False',
            value: 'false',
        }],
    });
    return filtersToShow;
};
