/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const EmptySpaceIcon = (props: any) => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Empty Space Icon</title>
        <rect x="0" y="0" width="26" height="26" fill="none" stroke="transparent" strokeWidth="1" />
    </svg>
);

const EmptyIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return EmptySpaceIcon({ ...innerProps, ...props });
    }}
    />
);

export default EmptyIcon;
