/* eslint-disable max-len */
import { NAVBAR_HEIGHT } from 'library/globals';
import { ThemeType } from '../../../theme/jss-types';

export const pickupStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        maxWidth: '100%',
        maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
        padding: '5px 8px',
        overflow: 'hidden',
        '& .ant-table-thead > tr > th': {
            padding: '8px 8px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 13,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 8px',
            fontSize: 13,
        },
        '& .ant-table-tbody > tr > td > .ant-checkbox-input': {
            fontSize: 12,
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-picker-range-separator, .ant-picker-input': {
            margin: 0,
            padding: 0,
        },
        backgroundColor: theme.backgroundColor,
    },
    pageFilter: {
        display: 'flex',
        marginBottom: 8,
        borderBottom: '1px solid #D9D9D9',
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        color: '#666666',
        padding: '12px 50px',
        cursor: 'pointer',
    },
    selectedPage: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: theme.primaryColor,
        padding: '12px 50px',
        borderBottom: `4px solid ${theme.primaryColor}`,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 180,
            border: '1px solid #999999',
            height: 28,
            fontSize: 9,
            borderRadius: 4,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 16px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
    },
    count: {
        height: 18,
        width: 28,
        borderRadius: 9,
        backgroundColor: '#E9E9E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        color: '#333',
        fontWeight: 600,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    countSelected: {
        backgroundColor: theme.primaryColor,
        color: '#FFF',
        fontWeight: 'bold',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 500,
        letterSpacing: 0,
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: 0,
    },
    bucketSelected: {
        color: '#111111',
        fontWeight: 'bold',
        backgroundColor: '#FFF',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    moreFilter: {
        marginLeft: 1,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.primaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Open Sans',
    },
    resetFilter: {
        color: theme.secondryColor,
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
    },
    bucketRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    bucketName: {
        marginRight: 12,
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 28,
            borderRadius: 4,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor}`,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
    },
    downloadBtn: {
        margin: '0 8px',
    },
    settingIcon: {
        color: '#333333',
        fontSize: 20,
        cursor: 'pointer',
        marginLeft: 8,
    },
    table: {
        '& td': {
            verticalAlign: 'baseline',
            cursor: 'pointer',
            border: 'none',
        },
        '& .ant-table-thead': {
            whiteSpace: 'pre-line !important',
            backgroundColor: '#',
            border: '1px solid #E8E8E8',
            color: '#262626',
            padding: 0,
        },
        '& .ant-table-selection-column': {
            borderRight: 'none !important',
        },
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    footer: {
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F7F7F7',
        borderRadius: '0 0 4px 4px',
    },
    paginationGroup: {
        display: 'flex',
        justifyContent: 'center',
        '& .ant-select-selection-item': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    resultPerPage: {
        margin: '0 5px !important',
        '& .ant-select-single .ant-select-selector': {
            padding: '5px !important',
            width: '55px !important',
            borderRadius: '4px !important',
        },
    },
    tabsContainer: {
        '& .ant-tabs-nav-list': {
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid #D9D9D9',
            minHeight: 42,
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: 4,
                height: '100%',
                transform: 'none',
                boxShadow: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
            },
        },
        '& .ant-tabs-bar': {
            margin: '0px',
            border: 'none',
        },
        '& .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab': {
            padding: 4,
            border: 'none',
        },
        '& .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav': {
            margin: 0,
            border: 'none',
        },
        '& .ant-tabs-tab': {
            color: '#909090',
            fontWeight: 500,
            minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            borderRadius: '4px 4px 0 0',
            border: 'none',
            backgroundColor: '#FFFFFF !important',
            cursor: 'pointer',
        },
        '& .ant-tabs-dropdown-menu-item': {
            color: '#666666',
            minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
        },
        '& .ant-tabs-tab.ant-tabs-tab-active': {
            color: `${theme.primaryColor} !important`,
            fontWeight: 'bold',
            borderBottom: `4px solid ${theme.secondaryColor || '#DD0035'} !important`,
        },
        '& .ant-tabs-tab-btn': {
            display: 'flex',
            width: '100%',
            padding: '2px 6px',
            justifyContent: 'space-between',
        },
        '& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab': {
            marginRight: 0,
        },
        '& .ant-tabs-nav-operations': {
            width: 150,
            backgroundColor: '#FFFFFF',
        },
    },
    dateRange: {
        '& .ant-picker-ranges .ant-picker-preset > .ant-tag-blue': {
            background: '#e6f7ff !important',
        },
    },
    dateFilter: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },
    selectFilter: {
        margin: '0 5px !important',
        '& .ant-select-selector': {
            borderRadius: '4px !important',
            width: '150px !important',
            height: 28,
        },
    },
});
