/* eslint-disable max-len */
import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from '../../theme/jss-types';

const consignemtStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        padding: '5px 5px 5px 15px',
        '& .ant-table-container table > thead > tr:first-child th:first-child': {
            padding: '8px 0px',
        },
        '& .ant-table-thead > tr > th': {
            padding: '8px 8px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 13,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
        },
        '& .ant-table-tbody > tr > td': {
            fontSize: 13,
        },
        '& .ant-table-tbody > tr > td .ant-table-cell, .ant-table-selection-column': {
            fontSize: '12px !important',
            padding: '12px 8px',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-selection-column': {
            padding: '8px !important',
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 100,
            borderRadius: 4,
            border: '1px solid #999999',
            height: 28,
            fontSize: 12,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-select': {
            marginRight: 24,
            marginLeft: 24,
        },
        '& .ant-table-selection': {
            marginLeft: 8,
            marginRight: 8,
        },
        '& .ant-picker-range-separator, .ant-picker-input': {
            margin: 0,
            padding: 0,
        },
        backgroundColor: theme.backgroundColor,
    },
    pageFilter: {
        display: 'flex',
        marginBottom: 8,
        borderBottom: '1px solid #D9D9D9',
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        color: '#666666',
        padding: '12px 50px',
        cursor: 'pointer',
    },
    selectedPage: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: theme.primaryColor,
        padding: '12px 50px',
        borderBottom: `4px solid ${theme.primaryColor}`,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 180,
            border: '1px solid #999999',
            height: 28,
            fontSize: 9,
            borderRadius: 4,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    actionButton: {
        marginRight: 8,
    },
    count: {
        height: 18,
        padding: 10,
        borderRadius: 9,
        backgroundColor: '#E9E9E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        color: '#333',
        fontWeight: 600,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    countSelected: {
        backgroundColor: theme.primaryColor,
        color: '#FFF',
        fontWeight: 'bold',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    notesCellValue: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    tableRowColor: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
        '&& tbody > tr:hover > td': {
            background: '#E7F5FF',
        },
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: 0,
    },
    ellipsisIcon: {
        fontSize: 20,
    },
    tabsContainer: {
        width: '75%',
        '& .ant-tabs-nav-list': {
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid #D9D9D9',
            minHeight: 42,
        },
        '& .ant-tabs-bar': {
            margin: '0px',
            border: 'none',
        },
        '& .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab': {
            padding: 4,
            border: 'none',
        },
        '& .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav': {
            margin: 0,
            border: 'none',
        },
        '& .ant-tabs-tab': {
            color: '#909090',
            fontWeight: 500,
            // minWidth: 105,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 18px !important',
            marginRight: 2,
            borderRadius: '4px 4px 0 0',
            border: 'none',
            backgroundColor: '#FFFFFF !important',
            cursor: 'pointer',
        },
        '& .ant-tabs-dropdown-menu-item': {
            color: '#666666',
            // minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
        },
        '& .ant-tabs-tab.ant-tabs-tab-active': {
            color: `${theme.primaryColor} !important`,
            fontWeight: 'bold',
            borderBottom: `4px solid ${theme.secondaryColor || '#DD0035'} !important`,
        },
        '& .ant-tabs-tab-btn': {
            display: 'flex',
            width: '100%',
            padding: '2px 6px',
            justifyContent: 'space-between',
        },
        '& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab': {
            marginRight: 0,
        },
        '& .ant-tabs-nav-operations': {
            width: 100,
            backgroundColor: '#FFFFFF',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: 4,
                height: '100%',
                transform: 'none',
                boxShadow: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
            },
        },
        '& .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after': {
            boxShadow: 'none',
        },
        '& .ant-tabs-nav-more': {
            padding: '6px !important',
        },
    },
    dateFilter: {
        width: '25%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        // borderBottom: '1px solid #D9D9D9',
    },
    selectFilter: {
        margin: '0 5px !important',
        '& .ant-select-selector': {
            padding: '5px !important',
            borderRadius: '4px !important',
        },
    },
    searchSelect: {
        margin: '0 !important',
        '& .ant-select-selector': {
            width: '160px !important',
            padding: '5px !important',
            borderRadius: '0px !important',
            borderRight: 'none',
        },
    },
    footer: {
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 2,
        backgroundColor: '#F7F7F7',
    },
    resultPerPage: {
        margin: '0 5px !important',
        '& .ant-select-selector': {
            padding: '5px !important',
            width: '55px !important',
            borderRadius: '4px !important',
        },
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 12px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    moreFilter: {
        fontFamily: 'Open Sans',
        marginLeft: 1,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
        fontFamily: 'Open Sans',
    },
    bucketRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'scroll',
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        flexDirection: 'row',
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            height: 28,
            borderRadius: 4,
        },
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 28,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor} `,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            height: 28,
        },
        '& .ant-select-selector': {
            borderRadius: '4px 0 0 4px !important',
        },
        '& .ant-input': {
            borderLeft: 'none !important',
            borderRadius: '0 4px 4px 0 !important',
        },
    },
    downloadBtn: {
        marginRight: 8,
    },
    settingIcon: {
        color: '#333333',
        fontSize: 20,
        cursor: 'pointer',
        marginLeft: 8,
    },
    menu: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    modalContainer: {
        padding: '1rem 0',
        paddingBottom: '3rem',
        minWidth: '40%',
    },
    carouselContainer: {
        padding: '1.5rem',
        maxWidth: '100%',
        '& span': {
            color: '#222021 !important',
        },
        '& span > svg': {
            width: '120%',
            height: '120%',
        },
    },
    popupbox: {
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '4px',
    },
    popupheader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#10477C',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '19px',
        padding: '14px',
    },
    modal: {
        '& .ant-modal-mask': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        '& .ant-modal-header': {
            backgroundColor: '#082E78',
            borderRadius: '4px 4px 0 0',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
            boxShadow: 'none !important',
        },
        '& .ant-modal-body': {
            padding: '0px !important',
        },
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    horizontalline: {
        height: '1px',
        backgroundColor: '#CCCCCC',
    },
    modalBody: {
        padding: '8px',
        fontSize: '13px !important',
        minWidth: 450,
    },
    uploadText: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 32,
    },
    closeIcon: {
        lineHeight: '24px',
        margin: '0px 6px',
        padding: '8px',
        width: 40,
        cursor: 'pointer',
        color: '#666666',
        fontWeight: 'bold',
    },
    uploadHint: {
        fontSize: 16,
        color: '#666666',
    },
    modalfooter: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mdbutton: {
        borderRadius: '2px',
        border: '0 !important',
        margin: '6px 8px',
        fontWeight: 500,
        minHeight: '36px',
        minWidth: '88px',
        textAlign: 'center',
        verticalAlign: 'middle',
        padidng: '0 8px',
    },
    dropdown: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap-reverse',
        alignItems: 'center',
    },
    grayCircle: {
        width: '32px',
        height: '32px',
        fontSize: '14px',
        backgroundColor: '#F5F5F5',
        borderRadius: '64px',
        marginRight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    customFlex: {
        display: 'flex', alignItems: 'center',
    },
    dateRange: {
        '& .ant-picker-ranges .ant-picker-preset > .ant-tag-blue': {
            background: '#e6f7ff !important',
        },
    },
});

export {
    consignemtStyles,
};
