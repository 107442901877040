/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const LTLSvg = (props: any) => (
    <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.6667 5.625H10V7.5L9.33333 7.08398L8.66667 7.5V5.625H7C6.81667 5.625 6.66667 5.76562 6.66667 5.9375V9.6875C6.66667 9.85938 6.81667 10 7 10H11.6667C11.85 10 12 9.85938 12 9.6875V5.9375C12 5.76562 11.85 5.625 11.6667 5.625ZM3.66667 4.375H8.33333C8.51667 4.375 8.66667 4.23438 8.66667 4.0625V0.3125C8.66667 0.140625 8.51667 0 8.33333 0H6.66667V1.875L6 1.45898L5.33333 1.875V0H3.66667C3.48333 0 3.33333 0.140625 3.33333 0.3125V4.0625C3.33333 4.23438 3.48333 4.375 3.66667 4.375ZM5 5.625H3.33333V7.5L2.66667 7.08398L2 7.5V5.625H0.333333C0.15 5.625 0 5.76562 0 5.9375V9.6875C0 9.85938 0.15 10 0.333333 10H5C5.18333 10 5.33333 9.85938 5.33333 9.6875V5.9375C5.33333 5.76562 5.18333 5.625 5 5.625Z"
            fill="currentColor"
        />
    </svg>
);

const LTLIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return LTLSvg({ ...innerProps, ...props });
        }}
    />
);

export default LTLIcon;
