/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const TruckSvg = (props: any) => (
    <svg
        width="19"
        height="10"
        viewBox="0 0 19 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17.4779 4.71805L15.6899 4.47903L14.6639 2.68593C14.3859 2.20146 13.8674 1.90039 13.3089 1.90039H11.3531C11.1637 1.90039 11.0097 2.05437 11.0097 2.24513V6.14941H5.46907C5.27831 6.14941 5.12433 6.30339 5.12433 6.49414V7.54445C5.12433 7.7352 5.27831 7.88919 5.46907 7.88919H6.13005C6.19256 8.683 6.85722 9.30997 7.66666 9.30997C8.47611 9.30997 9.14076 8.683 9.20327 7.88919H13.9579C14.0204 8.683 14.6851 9.30997 15.4945 9.30997C16.304 9.30997 16.9686 8.683 17.0312 7.88919H17.3855C17.7657 7.88919 18.0741 7.58076 18.0741 7.20063V5.40017C18.0741 5.05543 17.819 4.76402 17.4779 4.71805ZM7.6662 8.58142C7.2162 8.58142 6.8517 8.21692 6.8517 7.76692C6.8517 7.31692 7.2162 6.95242 7.6662 6.95242C8.1162 6.95242 8.48072 7.31692 8.48072 7.76692C8.48072 8.21692 8.1162 8.58142 7.6662 8.58142ZM14.4258 4.43399H12.1975C12.1593 4.43399 12.1285 4.40319 12.1285 4.3655L12.1253 2.94058C12.1253 2.90243 12.1561 2.87163 12.1942 2.87163H13.3089C13.5203 2.87163 13.7157 2.98563 13.82 3.16765L14.4861 4.33103C14.5123 4.37699 14.4791 4.43399 14.4263 4.43399H14.4258ZM15.4945 8.58142C15.0445 8.58142 14.68 8.21692 14.68 7.76692C14.68 7.31692 15.0445 6.95242 15.4945 6.95242C15.9445 6.95242 16.309 7.31692 16.309 7.76692C16.309 8.21692 15.9445 8.58142 15.4945 8.58142Z"
            fill="currentColor"
        />
        <path
            d="M4.86149 0.69043H10.1792C10.369 0.69043 10.5235 0.844412 10.5235 1.03471V5.31958C10.5235 5.50941 10.3695 5.66386 10.1792 5.66386H4.86149C4.67165 5.66386 4.51721 5.50987 4.51721 5.31958V1.03471C4.51721 0.844872 4.67119 0.69043 4.86149 0.69043Z"
            fill="currentColor"
        />
        <path
            d="M1.30742 4.06641H3.56568C3.7762 4.06641 3.94719 4.2374 3.94719 4.44792C3.94719 4.65844 3.7762 4.82943 3.56568 4.82943H1.30742C1.0969 4.82943 0.925903 4.65844 0.925903 4.44792C0.925903 4.2374 1.0969 4.06641 1.30742 4.06641Z"
            fill="currentColor"
        />
        <path
            d="M1.94298 2.7959H3.56555C3.77607 2.7959 3.94706 2.96689 3.94706 3.17741C3.94706 3.38793 3.77607 3.55892 3.56555 3.55892H1.94298C1.73245 3.55892 1.56146 3.38793 1.56146 3.17741C1.56146 2.96689 1.73245 2.7959 1.94298 2.7959Z"
            fill="currentColor"
        />
        <path
            d="M2.57914 1.52441H3.56602C3.77654 1.52441 3.94753 1.69541 3.94753 1.90593C3.94753 2.11645 3.77654 2.28743 3.56602 2.28743H2.57914C2.36862 2.28743 2.19763 2.11645 2.19763 1.90593C2.19763 1.69541 2.36862 1.52441 2.57914 1.52441Z"
            fill="currentColor"
        />
    </svg>
);

const TruckIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return TruckSvg({ ...innerProps, ...props });
        }}
    />
);

export default TruckIcon;
