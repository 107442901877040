/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const ContentFilledSvg = (props: any) => (
    <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.50984 5.12449V10.8671L11.4946 8.55267V2.80959L6.50984 5.12401V5.12449ZM10.6166 7.10561C10.6166 7.24441 10.5364 7.37072 10.4106 7.42883L7.89399 8.59733C7.84596 8.61943 7.79504 8.63047 7.74365 8.63047C7.6769 8.63047 7.61014 8.61174 7.55203 8.57428C7.44925 8.50896 7.3873 8.39562 7.3873 8.27363V6.57203C7.3873 6.43323 7.46799 6.30692 7.59382 6.24881L10.1104 5.08031C10.2209 5.02892 10.3496 5.03756 10.4524 5.10336C10.5552 5.16868 10.6166 5.28202 10.6166 5.40401V7.10561ZM3.73483 4.16731V5.78871C3.73483 5.98562 3.57539 6.14507 3.37848 6.14507C3.18156 6.14507 3.02211 5.98562 3.02211 5.78871V3.83641L0.812378 2.81055V8.55363L5.79663 10.8676V5.12497L3.73483 4.16731ZM6.15348 0L1.30274 2.25199L3.3074 3.18276L8.15813 0.930766L6.15348 0ZM9.00437 1.32363L4.15363 3.57562L6.15348 4.50398L11.0042 2.25199L9.00437 1.32363Z"
            fill="currentColor"
        />
        <path
            d="M8.10001 7.71538L9.9039 6.87827V5.96191L8.10001 6.79951V7.71538Z"
            fill="currentColor"
        />
    </svg>
);

const ContentFilledIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return ContentFilledSvg({ ...innerProps, ...props });
        }}
    />
);

export default ContentFilledIcon;
