import { defaultMiscellaneousState } from './default-values';
import { SET_MISCELLANEOUS_DATA } from './redux-constants';
import { MiscellaneousAction } from './redux.types';

const generic = (state = defaultMiscellaneousState, action: MiscellaneousAction) => {
    switch (action.type) {
        case SET_MISCELLANEOUS_DATA: {
            return {
                ...state,
                [action.field]: action.data,
            };
        }
        default: return state;
    }
};

export default generic;
