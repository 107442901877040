import { ZIndex } from 'library/constants';

export const settingsStyles = {
    appModal: {
        zIndex: ZIndex.iframe,
        background: '#fff',
        position: 'fixed',
        overflow: 'hidden',
    },
    customModalIframe: {
        border: 'none',
    },
};
