import React from 'react';
import i18n from './i18n';
import Routing from './routing/router-outlet';
import Helper from './library/Helper';
import Navbar from './components/common/Navbar';
import authHelper from './auth/auth-helper';
import SideMenu from './components/common/side-menu/side-menu';
import { StylesProps, ThemeType } from './theme/jss-types';
import { HocOptions } from './components/common/generic-hoc.types';
import { MIN_WIDTH, NAVBAR_HEIGHT } from './library/globals';
import { setDefaultPortalAction, setRTL } from './actions/ui-theme';
import { bindActionCreators } from 'redux';
import { ReduxStore } from './reducers/redux.types';
import 'antd/dist/antd.css';
import './App.css';
import GenericHoc from './components/common/generic-hoc';
import { RouteChildrenProps } from 'react-router-dom';
import PersistentIframe from './components/persistent-iframe/persistentIframeInjector';
import { IframeApps } from './components/persistent-iframe/persistent-iframe.constants';
import { useTranslation } from 'react-i18next';
import { Master } from 'types/master-data-types';
import moment from 'moment';
import { initGoogleAnalytics } from 'library/google-analytics-helper';
import SpLoginRequest from 'components/sp-login-request';
import { isSSOLoginRoute } from 'routing/routing-helper';
import GetStarted from 'components/common/getStarted';

const styles = () => ({
    mainDiv: {
        width: '100%',
        height: '100vh',
        backgroundColor: '#EDE8DE',
        minWidth: MIN_WIDTH,
        fontFamily: 'Open Sans',
        direction: (props: any) => Helper.languageDirection(props.i18n.language),
    },
    contentDiv: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    mainContent: {
        width: 'calc(100% - 48px)',
    },
    passwordbanner: {
        borderRadius: 4,
        margin: '3px 8px',
        background: '#F1BDA8',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        padding: '8px 12px',
    },
    leftText: {
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        textAlign: 'center',
    },
    passwordText: {
        color: '##000000',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        textAlign: 'center',
    },
    rightText: {
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        textAlign: 'center',
    },
    rightLink: {},
    modal: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
    },
    addText: {},
});

interface AppProps extends RouteChildrenProps, StylesProps<ReturnType<typeof styles>> {
    master: Master;
    setRtl: (isRTL: boolean) => void;
    i18n: any,
    uiTheme: ThemeType;
}

const App = (props: AppProps) => {
    const {
        classes,
        master,
        setRtl,
        uiTheme,
    } = props;
    const { t } = useTranslation();

    const [isGetStartedVisible, setIsGetStartedVisible] = React.useState(false);
    const [createVisible, setCreateVisible] = React.useState(false);

    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const initiatePrimaryColor = (theme: ThemeType) => {
        (window as any).less.modifyVars({
            '@primary-color': theme.primaryColor,
            '@secondry-color': theme.secondryColor,
        });
    };

    React.useEffect(() => {
        initiatePrimaryColor(uiTheme);
        const setUIDirection = (lng: string) => {
            const isRTL = Helper.isLanguageRTL(lng);
            setRtl(isRTL);
        };

        // Handle RTL theme set
        setUIDirection(i18n.language);
        i18n.on('languageChanged', (lng) => {
            setUIDirection(lng);
        });

        Helper.changeFavicon();

        // initialise google analytics
        initGoogleAnalytics();

        if (master?.Customer?.is_first_login) {
            setIsGetStartedVisible(true);
        }
    }, []);

    React.useEffect(() => {
        initiatePrimaryColor(uiTheme);
    }, [uiTheme]);

    const authenticated = authHelper.isAuthenticated();

    const renderNavbar = () => {
        if (!authenticated) {
            return null;
        }
        return (
            <Navbar
                pageTitle={t('customer_portal')}
                i18n={props.i18n}
            />
        );
    };
    const dateobject = master?.config?.customer_portal_config?.password_policy_banner_expiry_date;
    const formattedDate = moment(dateobject).format('Do MMM YYYY');

    const bannerText = `We are enhancing our security by implementing a new password policy
    . Please update your password before
    ${formattedDate} `;

    const renderLeftPasswordBanner = () => {
        return (
            <div className={classes.row}>
                <span className={classes.passwordText}>
                    {bannerText}
                </span>
            </div>
        );
    };

    const renderPasswordBanner = () => {
        if (!authenticated || !(master?.config?.customer_portal_config?.password_policy_banner_expiry_date
            && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) > new Date())) {
            return null;
        }
        return (
            <div className={classes.passwordbanner}>
                {renderLeftPasswordBanner()}
            </div>
        );
    };

    const renderSideMenu = () => {
        if (!authenticated) {
            return null;
        }
        return (
            <SideMenu
                i18n={props.i18n}
                showCreate={createVisible}
                setCreateVisible={setCreateVisible}
                isGetStartedVisible={isGetStartedVisible}
                showGetStartedModal={() => { setIsGetStartedVisible(true); }}
            />
        );
    };

    const renderGetStarted = () => {
        if (!authenticated) {
            return null;
        }
        return (
            <GetStarted
                isVisible={isGetStartedVisible}
                cancelModal={() => { setIsGetStartedVisible(false); }}
                openBookingModal={() => { setCreateVisible(true); }}
            />
        );
    };

    const renderRoutes = () => {
        if (!authenticated) {
            return <Routing bannerHeight={0} />;
        }
        return (
            <div className={classes.mainContent}>
                { renderPasswordBanner()}
                <Routing bannerHeight={bannerHeight} />
            </div>
        );
    };

    if (isSSOLoginRoute() && authenticated) {
        return (
            <SpLoginRequest />
        );
    }

    return (
        <div
            className={classes.mainDiv}
        >
            <PersistentIframe
                appName={IframeApps.Analytics}
            />
            <PersistentIframe
                appName={IframeApps.APIPlayground}
            />
            <>
                {renderNavbar()}
                <div className={classes.contentDiv}>
                    {renderSideMenu()}
                    {renderRoutes()}
                    {renderGetStarted()}
                </div>
            </>
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
        master: state.master,
        fetchingMaster: state.master.fetchingMaster,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setRtl: setRTL,
        setdefaultportal: setDefaultPortalAction,
    }, dispatch);
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};
export default GenericHoc(hocConfig)(App);
