import * as React from 'react';
import {
    Row,
    Col,
    Carousel,
    Image,
} from 'antd';
import CarouselsPage from './Carousels';
import { Switch, Route } from 'react-router-dom';
import shipsyLogoWhite from '../assets/logos/shipsy-logo.png';
import {
    getCustomerActivationRoute,
    getForgotPasswordRoute,
    getLoginRoute,
    getLoginRouteCP,
    getResetPasswordRoute,
    getSuperUserLoginRoute,
} from './routing-helper';
import { StylesProps } from '../theme/jss-types';
import Loader from '../components/common/Loader';
import Login from '../components/sign-in/sign-in';
import ForgotPassword from '../components/sign-in/forgot-password';
import DefaultUrl from './default-page';
import { getOrgDetails } from 'network/common.api';
import CreatePassword from 'components/sign-in/create-password';
import ResetPassword from 'components/sign-in/reset-password';
import Helper from 'library/Helper';
import { setTheme } from 'actions/ui-theme';
import { bindActionCreators } from 'redux';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import { UITheme } from 'library/Types';

const styles = {
    parentDiv: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '& .ant-carousel .slick-dots li.slick-active button': {
            backgroundColor: '#FFF',
            border: '2px solid #FFFFFF',
        },
        '& .ant-carousel .slick-dots-bottom': {
            bottom: 75,
        },
        '& .ant-carousel .slick-dots li.slick-active': {
            width: 16,
        },
        '& .ant-carousel .slick-dots li button': {
            height: 16,
            width: 16,
            borderRadius: 8,
            border: '1px solid #FFFFFF',
            margin: '0 8px',
            backgroundColor: 'transparent',
            opacity: 1,
        },
    },
    logoStyle: {
        marginTop: '10%',
        left: 0,
        textAlign: 'center',
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: 'min(10vh, 100px)',
        // maxWidth: 'min(10vh, 100px)',
    },
    logoImage: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: 'min(10vh, 100px)',
        // maxWidth: 'min(10vh, 100px)',
    },
    bannerParent: {
        width: '100%',
        padding: '20% 8%',
        border: '1px #FFF',
        height: '100vh',
    },
    bannerDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    bannerTitle: {
        fontSize: 20,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        color: '#FFF',
        marginTop: 24,
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: '0 10%',
        height: '5vh',
    },
    bannerDescription: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        color: '#FFF',
        marginTop: 24,
        width: '100%',
        padding: '0 10%',
        textAlign: 'center',
        height: '10vh',
    },
};

interface IProps extends StylesProps<typeof styles> {
    settheme: (theme: UITheme) => void;
}
const UnAuthPages = (props: IProps) => {
    const { classes, settheme } = props;
    const carouselRef = React.createRef<HTMLDivElement>();

    const [formHeight, setFormHeight] = React.useState(Math.max(window.innerHeight, 500));
    const [isLogoWhite, setIsLogoWhite] = React.useState(window.innerWidth >= 768);
    const [logoUrl, setLogoUrl] = React.useState(shipsyLogoWhite);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [landingImageUrl, setLandingImageUrl] = React.useState<string>('');
    const [config, setConfig] = React.useState<any>({});

    const initiatePrimaryColor = (primarycolor: string, secondrycolor?: string) => {
        (window as any).less.modifyVars({
            '@primary-color': primarycolor,
            '@secondry-color': secondrycolor || primarycolor,
        });
    };

    const loadOrgDetails = async () => {
        setLoading(true);
        const orgDetails = await getOrgDetails();
        setLogoUrl(orgDetails?.data?.logo_url || shipsyLogoWhite);
        setLandingImageUrl(orgDetails?.data?.login_page_landing_image_url);
        const theme = orgDetails?.data?.custom_theme;
        if (theme?.primaryColor) {
            settheme({
                primaryColor: theme?.primaryColor,
                secondryColor: theme?.secondryColor,
                ...(Helper.getThemeColorFromPrimary(theme?.primaryColor)),
            });
            initiatePrimaryColor(theme?.primaryColor, theme?.secondryColor);
        } else {
            settheme({
                primaryColor: '#082E78',
                secondryColor: '#ED1C24',
                ...(Helper.getThemeColorFromPrimary('#082E78')),
            });
            initiatePrimaryColor('#082E78', '#ED1C24');
        }

        setConfig(orgDetails?.data || {});
        const faviconUrl = orgDetails?.data?.favicon_url;
        Helper.changeFavicon(faviconUrl);
        setLoading(false);
    };

    React.useEffect(() => {
        loadOrgDetails();
    }, []);

    const getBannerConfig = () => {
        const bannerConfig = config?.customer_portal_configs?.customer_portal_banner_config;
        if (!bannerConfig?.config_value) {
            return null;
        }

        const bannerList = bannerConfig?.config_value?.banner_list;

        if (!Helper.isValidArray(bannerList)) {
            return null;
        }

        const bannerSwipeTime = bannerConfig?.config_value?.swipe_time;

        return {
            bannerList,
            backgroundColor: bannerConfig?.config_value?.background_color,
            swipeEnabled: bannerConfig?.config_value?.swipe_enabled,
            swipeTime: (!bannerSwipeTime || Number.isNaN(bannerSwipeTime)) ? 5 : Number(bannerSwipeTime),
        };
    };

    const changeFormHeight = () => {
        setFormHeight(Math.max(window.innerHeight, 500));
    };
    React.useEffect(() => {
        window.addEventListener('resize', changeFormHeight);

        return () => {
            window.removeEventListener('resize', changeFormHeight);
        };
    }, []);

    const changeLogo = () => {
        const width = window.innerWidth;
        if (width < 768 && isLogoWhite) {
            setIsLogoWhite(false);
        }
        if (width >= 768 && !isLogoWhite) {
            setIsLogoWhite(true);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', changeLogo);
        return () => {
            window.removeEventListener('resize', changeLogo);
        };
    }, [window.innerWidth]);

    const renderLogo = () => {
        if (!isLogoWhite) {
            return null;
        }
        return (
            <div className={classes.logoStyle}>
                <img
                    className={classes.logoImage}
                    src={logoUrl}
                    alt="Company logo"
                />
            </div>
        );
    };

    const renderPages = () => {
        return (
            <React.Suspense fallback={<Loader zIndex={5} />}>
                <Switch>
                    <Route
                        exact
                        path={getLoginRouteCP()}
                        render={() => <Login />}
                    />
                    <Route
                        exact
                        path={getSuperUserLoginRoute()}
                        render={() => <Login />}
                    />
                    <Route
                        exact
                        path={getLoginRoute()}
                        render={() => <Login />}
                    />
                    <Route
                        exact
                        render={() => <ResetPassword passwordPolicy={config?.password_policy || {}} />}
                        path={getResetPasswordRoute()}
                    />

                    <Route
                        exact
                        render={() => <CreatePassword passwordPolicy={config?.password_policy || {}} />}
                        path={getCustomerActivationRoute()}
                    />
                    <Route
                        exact
                        render={() => <ForgotPassword />}
                        path={getForgotPasswordRoute()}
                    />
                    <Route
                        path="/"
                        render={() => <DefaultUrl />}
                    />
                </Switch>
            </React.Suspense>
        );
    };

    if (loading) {
        return <Loader zIndex={5} />;
    }

    const renderSingleBanner = (banner: any) => {
        return (
            <div
                className={classes.bannerDiv}
                key={banner?.index}
            >
                <Image
                    preview={false}
                    src={banner?.banner_link}
                    style={{
                        maxHeight: '50vh',
                    }}
                />
                <div className={classes.bannerTitle}>
                    {banner?.title || ' '}
                </div>
                <div className={classes.bannerDescription}>
                    {banner?.description || ' '}
                </div>
            </div>
        );
    };

    const bannerConfig = getBannerConfig();
    const renderCarrousel = () => {
        if (bannerConfig) {
            return (
                <Carousel
                    dots={bannerConfig.bannerList?.length > 1}
                    dotPosition="bottom"
                    autoplay={bannerConfig.swipeEnabled}
                    className={classes.bannerParent}
                    autoplaySpeed={bannerConfig.swipeTime * 1000}
                    style={{
                        backgroundColor: bannerConfig?.backgroundColor ? bannerConfig?.backgroundColor : '#072F51',
                    }}
                >
                    {bannerConfig.bannerList.map((banner: any) => renderSingleBanner(banner))}
                </Carousel>
            );
        }
        return <CarouselsPage background_url={landingImageUrl} />;
    };
    return (
        <Row className={classes.parentDiv}>
            <Col
                style={{
                    height: formHeight,
                    background: 'white',
                }}
                xs={24}
                sm={24}
                md={12}
                lg={12}
            >
                {renderLogo()}
                {renderPages()}
            </Col>
            <Col
                xs={0}
                sm={0}
                md={12}
                lg={12}
                ref={carouselRef}
                style={{
                    top: 0,
                    backgroundColor: bannerConfig?.backgroundColor ? bannerConfig?.backgroundColor : '#072F51',
                }}
            >
                {renderCarrousel()}
            </Col>
        </Row>
    );
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        settheme: setTheme,
    }, dispatch);
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(UnAuthPages);
