import withStyles from 'react-jss';
import React from 'react';

const styles = {
    mainDiv: {
        backgroundColor: '#006EC3',
        borderRadius: 15,
        padding: 15,
        color: 'white',
    },
    actionButton: {
        background: 'none',
        color: 'white',
        border: 'none',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
        cursor: 'pointer',
    },
};
const ContentComponent = (props: any) => {
    const {
        classes, currentStep, steps, setIsOpen, setCurrentStep,
    } = props;
    const isLastStep = currentStep === steps.length - 1;
    const content = steps[currentStep].content;
    return (
        <div className={classes.mainDiv}>
            <div>
                {typeof content === 'function'
                    ? content({ ...props, someOtherStuff: 'Custom Text' })
                    : content}
            </div>
            <div className={classes.footer}>
                <div>{`${currentStep + 1}/${steps.length}`}</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        className={classes.actionButton}
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Skip Tour
                    </div>
                    <div
                        className={classes.actionButton}
                        style={{ marginLeft: 10, backgroundColor: '#2884CB', padding: '5px 10px' }}
                        onClick={() => {
                            if (isLastStep) {
                                setIsOpen(false);
                            } else {
                                setCurrentStep((s: number) => s + 1);
                            }
                        }}
                    >
                        Next
                    </div>
                </div>
            </div>
        </div>
    );
};
const UserGuide = withStyles(styles)(ContentComponent);
export default UserGuide;
