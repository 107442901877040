export const FETCH_MASTER_DATA = '/api/CustomerAnalytics/config';
export const SET_PLUGIN_DATA = '/api/CustomerAnalytics/setMarketplaceDetailsForCustomer';
export const LOGIN = '/api/CustomerAnalytics/login';
export const SEARCH_CUSTOMERS = '/api/CustomerAnalytics/customerList';
export const CHANGE_CUSTOMER = '/api/CustomerAnalytics/changeCustomerUser';
export const SEND_RESET_PASSWORD_MAIL = '/api/CustomerAnalytics/sendResetPasswordMail';
export const SEND_FORGOT_PASSWORD_OTP_MAIL = '/api/CustomerAnalytics/sendForgotPasswordOTP';
export const FETCH_CUSTOMER_EMAILS = '/api/CustomerAnalytics/fetchCustomerEmails';
export const VALIDATE_RESET_PASSWORD_OTP = '/api/CustomerAnalytics/verifyForgotPasswordOTP';
export const RESET_PASSWORD = '/api/CustomerAnalytics/customer/resetPassword';
export const LOGOUT_USER = '/api/CustomerAnalytics/logout';
export const ORG_DETAILS = '/api/CustomerAnalytics/orgdetails';
export const PASSWORD_POLICY = '/api/Dashboard/password-policy';
export const CHANGE_PASSWORD = '/api/CustomerAnalytics/customer/password/reset';
export const AUTHENTICATE_ACTIVATION_CODE = '/api/CustomerPortal/authenticateActivationCode';
export const SET_DEFAULT_PORTAL = '/api/CustomerPortal/setDefaultPortalForCustomer';
export const LTL_CUSTOMER_HUB_DETAILS = '/api/CustomerPortal/getLTLCustomerHubDetails';

// consignments
export const FETCH_CONSIGNMENTS = '/api/CustomerPortal/customer/consignments/fetch';
export const FETCH_PLUGIN_ORDERS = '/api/CustomerAnalytics/getAllShopifyOrders';
export const FETCH_SUMMARY_COUNT = '/api/CustomerPortal/summaryMetricsApi/getSummaryMetricsCount';
export const FETCH_CONSIGNMENT = '/api/CustomerPortal/consignments/fetchOne';
export const CREATE_CONSIGNMENT = '/consignment/business/save';
export const SAVE_PLUGIN_ORDER = '/api/CustomerAnalytics/updateSoftdatabody';
export const CREATE_CONSIGNMENT_INTERNATIONAL = '/consignment/business/international/save';
export const CREATE_SELLER_PROFILE = '/api/customerportal/seller/create';
export const UPDATE_SELLER_PROFILE = '/api/customerportal/seller/update';
export const FETCH_SELLER = '/api/customerportal/seller/fetch';
export const SEND_OTP_TO_VERIFY = '/api/customerportal/seller/sendOtp';
export const VERIFY_OTP = '/api/customerportal/seller/verifyOtp';
export const GET_AUTHORISATION_LETTER = '/api/customerportal/seller/getAuthorisationLetter';
export const GET_UNSIGNED_AUTHORISATION_LETTER = '/public/getDocumentForSeller';
export const CHECK_CODE_AVAILABILITY = '/api/customerportal/seller/checkCodeAvailaibility';
export const FETCH_SELLER_MASTER_DATA = '/api/customerportal/seller/masterdata';
export const VALIDATE_INDIVIDUAL_KYC = '/api/customerportal/seller/validateIndividualKyc';
export const DEACTIVATE_SELLER = '/api/customerportal/seller/deactivate';
export const SET_DEFAULT_SELLER = '/api/customerportal/seller/setDefaultProfile';
export const IMAGE_UPLOAD_LINKS = '/api/customerportal/seller/getFileUploadUrl';
export const IMAGE_DOWNLOAD_LINKS = '/api/customerportal/seller/getFileDownloadUrl';
export const DELETE_IMAGE = '/api/customerportal/seller/deleteFile';
export const DOWNLOAD_CONSIGNMENTS = '/api/CustomerPortal/consignments/download';
export const DOWNLOAD_PLUGIN_ORDERS = '/api/CustomerPortal/plugin/orders/download';
export const PRINT_CONSIGNMENT = '/print/getStream';
export const PRINT_4X6_CONSIGNMENT = '/print/international/getLabel';
export const PRINT_4X6_CONSIGNMENT_DOMESTIC = '/print/getShippingLabel4x6';
export const PRINT_POD_CONSIGNMENT = '/print/getPODShippingLabel';
export const PRINT_ROUTING_LABEL = '/print/getRoutinglabelStream';
export const PRINT_INVOICE = '/print/getInvoiceStream';
export const PRINT_ADDRESS_LABEL = '/print/getAddressLabel';
export const BULL_PRINT_CONSIGNMENT = '/consignment/business/bulk/label/print';
export const SAMPLE_DOWNLOAD = '/csvheaders/consignment/sample_consignment_upload.xlsx';
export const BULK_CONSIGNMENT = '/consignment/business/saveMultiplev2';
export const BULK_CONSIGNMENT_INTERNATIONAL = '/consignment/business/international/saveMultiplev2';
export const UPDATE_COLUMNS = '/api/CustomerPortal/update/consignmentColumns';
export const UPDATE_FILTERS = '/api/CustomerPortal/update/consignmentFilters';
export const PRINT_BULK_LABEL = '/consignment/business/printBulkLabel';
// export const GET_CONSIGNMENT_TRACKING = '/api/CustomerPortal/consignment/tracking';
export const GET_CONSIGNMENT_TRACKING = '/consignment/business/tracking';
export const CANCEL_CONSIGNMENTS = '/consignment/business/cancelConsignments';
export const REVOKE_CANCELLATION = '/consignment/business/revokeConsignments';
export const GET_CANCELLATION_REASONS = '/consignment/business/getReverseCancellationReason';
export const SET_CONSIGNMENT_NOTES = '/consignment/business/setconsignmentnotes';
export const SCHEDULE_PICKUP = '/pickup/business/create';
export const GET_VALIDATION_BUILDER_DATA = '/api/customeranalytics/object/validation/fetch';

// Reports
export const GET_REMITTANCE_DATA = '/consignment/business/getCODRemittanceData';
export const GET_INVOICE_DATA = '/consignment/business/getInvoiceReportsData';
export const DOWNLOAD_INVOICE_REPORT_REQUEST = '/api/CustomerPortal/invoice/download';
export const DOWNLOAD_INVOICE_REPORT = '/consignment/business/getIndividualInvoiceReportData';
export const GET_INVOICE_DOWNLOAD_REQUESTS = '/api/CustomerPortal/download/reports/getRequests';

export const CREATE_DOWNLOAD_REMITTANCE_DATA = '/api/CustomerPortal/reports/download';

// RTO Dashboard
export const GET_RTO_CONSIGNMENTS = '/api/CustomerPortal/rto/consignments/fetch';
export const RTO_ACTION = '/api/CustomerPortal/rto/consignments/action';

// pickups
export const GET_PICKUPS = '/pickup/business/get';
export const SAVE_PICKUP = '/pickup/business/save';
export const GET_TIME_RANGES = '/businessPickupSlots/get';
export const DOWNLOAD_PICKUPS = '/pickup/business/exportCSV';
export const GET_STATUS_FILTER = '/businessFilter/pickup/get';
export const SORT_BY_FILTER = '/businessFilter/getSortBy/pickup';
export const LOAD_TYPE_FILTER = '/businessFilter/getCourierType';
export const BULK_PICKUPS = '/pickup/business/saveMultiple';
export const UPDATE_PICKUP_COLUMNS = '/api/CustomerPortal/update/pickupcolumns';
export const FETCH_SUMMARY_COUNT_PICKUP = '/api/CustomerPortal/summaryMetricsApi/getSummaryMetricsPickupCount';

// print-history
export const FETCH_PRINT_HISTORY = '/consignment/business/getCsvPrintRequests';

// virtual-inventory
export const FETCH_VIRTUAL_INVENTORY = '/series/getSeries';

// excels
export const FETCH_EXCELS = '/consignment/business/getPaginatedCSVHistory';
export const EXCEL_DOWNLOAD = '/consignment/business/downloadData.xlsx';
export const EXCEL_DOWNLOAD_ERRORS = '/consignment/business/downloadErrors.xlsx';
export const EXCEL_PENDING = '/consignment/business/downloadSoftDataPendingConsignments.xlsx';
export const EXCEL_NON_INITIATED = '/consignment/business/downloadBookingPendingConsignments.xlsx';
export const EXCEL_PRINT_A4 = '/consignment/business/printCsv';
export const EXCEL_EPOD = '/consignment/business/printEpod';
// address
export const GET_STATE_CITY = '/serviceability/autopopulateStateCity';
export const FETCH_PICKUP_ADDRESS = '/businessAddress/search';
export const SAVE_ADDRESS = '/businessAddress/save';
export const EDIT_ADDRESS = '/businessAddress/edit';
export const DELETE_ADDRESS = '/businessAddress/delete';
export const GET_COUNTRIES = '/international/getCountries';
export const GET_ADDRESS_HIERARCHY = '/serviceability/address-hierarchy';
export const GET_STATES = '/international/getStates';
export const GET_CITIES = '/international/getCities';
export const GET_LOCATION_DATA = '/serviceability/address';
export const GET_DEFAULT_ADD = '/businessAddress/fetch-default';
export const SET_DEFAULT_ADD = '/businessAddress/save-as-default';
export const BULK_DELETE_ADDRESS = '/businessAddress/bulk-delete';
export const DOWNLOAD_ADDRESSES = '/businessAddress/download-addresses';
export const GET_ADDRESSES = '/businessAddress/get-all-address';
export const DOWNLOAD_ALL_ADDRESSES = '/businessAddress/download-all';
export const DOWNLOAD_ADDRESS_SAMPLE = '/businessAddress/download-address-sample';
export const BULK_EDIT = '/businessAddress/bulk-edit';
export const BULK_SAVE_ADDRESS = '/businessAddress/bulk-save-address';
export const FETCH_LOCALITY_FROM_ADDRESS_MAPPING = '/api/CustomerPortal/fetchAddressCode';
export const FETCH_CITIES_LIST = '/api/CustomerPortal/addressManagement/getAddressNodeData?type=City';
export const FETCH_ADDRESS_NODE_CONFIG = '/api/CustomerPortal/addressManagement/getAddressNodeConfig';
export const FETCH_PINCODES_LIST = '/api/CustomerPortal/addressManagement/getAddressNodeData?type=Pincode';
export const FETCH_COUNTRIES_NODE_DATA = '/api/CustomerPortal/addressManagement/getAddressNodeData?type=Country';
export const GEOCODE_BY_ADDRESS = '/businessAddress/geocode';

// general-apis
export const GET_BUSINESS_TAT = '/tat/getBusinessTAT';
export const GET_BUSINESS_TAT_SERVICEABILITY = '/tat/getServiceabilityRateTat';
export const GET_BUSINESS_TAT_V2 = '/tat/getBusinessTATV2';
export const GET_INTERNATIONAL_BUSINESS_TAT = '/international/getBusinessTAT';
export const GET_INTERNATIONAL_BUSINESS_TAT_V2 = '/international/getBusinessTATV2';
export const GET_DOWNLOAD_REQUESTS = '/api/CustomerPortal/download/v1/getRequests';
export const GET_PICKUP_REQUESTS = '/pickup/business/getRequests';
export const FETCH_SERVICES = '/serviceTypes/get';
export const FETCH_STORES = '/api/CustomerAnalytics/getAllShopifyStores';
export const GET_CONTENT_LIST = '/contentType/get';
export const GET_CHILD_LIST = '/childFilter/get';
export const GET_CURRENCY_CODES = '/consignment/business/currencyCodes';
export const GET_PUDO_HUBS = '/consignment/business/getPudoHub';
export const GET_INCO_TERMS = '/consignment/business/incoTerms';
export const GET_SERVICEABILITY_DATA = '/tat/getPincodeServiceability';

// asn-api
export const FETCH_ASN_DATA = '/api/CustomerPortal/asn/v1/fetch';
export const CREATE_ASN = '/api/CustomerPortal/asn/v1/create';
export const UPDATE_ASN = '/api/CustomerPortal/asn/v1/update';
export const GET_CUSTOMER = '/api/Dashboard/CustomerManagement/searchCustomers';
export const CANCEL_ASN = '/api/CustomerPortal/asn/v1/cancelMultiple';
export const FETCH_DESTINATION_LOCATION_CODE = '/api/CustomerPortal/asn/v1/fetchDestinationLocationData';
export const SAMPLE_UPLOAD_ASN = '/api/CustomerPortal/asn/v1/create/sampleCsv';
export const ASN_SUMMARY_METRICS = '/api/CustomerPortal/asn/v1/getSummaryMetricsCount';
export const BULK_UPLOAD_ASN = '/api/CustomerPortal/asn/v1/createMultiple';
export const FETCH_CSV_HISTORY = '/api/CustomerPortal/asn/v1/getCSVUploadHistory';
export const SAMPLE_BULK_CONVERT_ASN = '/csvheaders/asn/sample_asn_to_consignment_upload.xlsx';
export const BULK_ASN_TO_CONSIGNMENT = '/consignment/business/createMultipleConsignmentFromASN';


// Wallet
export const WALLET_FETCH_CNC = '/api/CustomerAnalytics/cnc';
export const WALLET_LEDGER = '/wallet/statement';
export const BLOCKED_LEDGER = '/wallet/blocked/statement';
export const WALLET_WEIGHT_MISMATCH = '/weight-mismatch/transaction';
export const WALLET_LEDGER_DOWNLOAD = '/wallet/statement/downloadData.xlsx';
export const BLOCKED_WALLET_LEDGER_DOWNLOAD = '/wallet/blocked/statement/downloadData.xlsx';

// parent-child
export const LOGIN_CHILD_CUSTOMER = '/api/CustomerAnalytics/loginToChildCustomer';

// Settings APIs
export const GET_API_KEY = '/api/CustomerPortal/CustomerManagement/getCustomerIntegrationKey';
export const GET_CUSTOMER_DETAILS = '/api/CustomerPortal/CustomerManagement/getCustomerDetails';
export const GET_CHILD_CUSTOMERS = '/api/CustomerPortal/CustomerManagement/getChildCustomerDetails';
export const UPDATE_CUSTOMER = '/api/CustomerPortal/CustomerManagement/updateCustomer';
export const GET_PRODUCTS = '/productcode/search';
export const GET_HEADERS = '/csvheaders/consignment/get';
export const GET_DEFAULT_HEADERS = '/csvheaders/consignment/getDefaultHeader';
export const GET_ORGANISATION_MANDATORY_HEADERS = '/csvheaders/consignment/getOrganisationMandatoryHeaders';
export const RESET_HEADERS = '/csvheaders/consignment/resetHeader';
export const UPDATE_HEADERS = '/csvheaders/consignment/update';
export const GET_PIECE_HEADERS = '/csvpieceheaders/consignment/get';
export const GET_DEFAULT_PIECE_HEADERS = '/csvpieceheaders/consignment/getDefaultHeader';
export const RESET_PIECE_HEADERS = '/csvpieceheaders/consignment/resetHeader';
export const UPDATE_PIECE_HEADERS = '/csvpieceheaders/consignment/update';
export const UPDATE_PRODUCT = '/productcode/update';
export const DELETE_PRODUCT = '/productcode/delete';
export const SAVE_PRODUCT = '/productcode/add';

// S3
export const S3_UPLOAD_LINKS = '/api/CustomerPortal/getS3signatureUrls';
// upload hellmann
export const FILE_UPLOAD_LINKS = '/consignment/s3/uploadLinks?';
export const UPLOAD_FILES = '/consignment/business/documents';
// Code Free
export const GET_PUBLISHED_LABELS = '/api/CustomerAnalytics/getAllPublishLabels';

// Feedback APIs
export const GET_FEEDBACK = '/api/CustomerPortal/feedback/get';
export const SET_FEEDBACK = '/api/CustomerPortal/feedback/set';

// W3W API
export const WHAT_3_WORD = '/api/CustomerPortal/validatew3wcode';

// miscellaneous
export const VERIFY_SRF_NUMBER = '/consignment/business/getSRFDetails';

// Supplementary Services
export const FETCH_SUPP_SERVICE_MASTER = '/api/CustomerPortal/supplementary-service-master/v1/fetch';
export const FETCH_SUPP_SERVICES = '/api/CustomerPortal/supplementary-service-request/v1/fetch';
export const CREATE_SUPP_SERVICES = '/api/CustomerPortal/supplementary-service-request/v1/create';
export const UPDATE_SUPP_SERVICES = '/api/CustomerPortal/supplementary-service-request/v1/update';
export const FETCH_ITEM_UNITS = '/api/CustomerPortal/itemunitmastermanagement/fetch';

// Object Page
export const FETCH_OBJECT_PAGES_CUSTOMER = '/api/CustomerAnalytics/objectpage/customer';

// Object View
export const FETCH_OBJECT_VIEW_BY_ID = (viewId: string) => `/api/CustomerAnalytics/objectview/${viewId}/get`;
export const FETCH_OBJECT_VIEW_DATA_OBJECTS = '/api/CustomerAnalytics/objectview/data/fetch';
// example: GENERATE_ASSERTION_RESPONSE_PREFIX/${SP-ORG-ID}/${PROTOCOL}
export const GENERATE_ASSERTION_RESPONSE_PREFIX = '/api/CustomerAnalytics/generate-assersion-response/';




// User Management api's
export const CREATE_USER = '/api/CustomerPortal/customeruser/create';
export const UPDATE_USER = '/api/CustomerPortal/customeruser/update';
export const GET_USERS = '/api/CustomerPortal/customeruser/get';
export const GET_ROLES = '/api/CustomerPortal/customeruser/getroles';
export const DOWNLOAD_USERS = '/api/CustomerPortal/customeruser/download';


// seller profile
export const FETCH_SELLERS = '/api/customerportal/seller/fetch';
export const FETCH_SELLER_DETAILS = '/api/customerportal/seller/getDetails';
export const GET_AUTH_LETTER_URLS = '/api/customerportal/seller/getAuthorizationLetter';
export const GET_BOOKING_FILTERS_SELLER = '/api/customerportal/seller/fetch/booking/filter';
