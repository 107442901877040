import moment from 'moment';
import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { getPickups } from '../network/pickup.api';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
    setPagination,
} from './generic-action';

const bucket = Buckets.PICKUPS;
const dateFormat = 'DD/MM/YYYY';
const sortByMap = {
    pickup_created_at: 'created_at',
    pickup_date: 'time_slot_start',
};

const getRiderAssigned = (value: string) => {
    if (value === 'ALL') {
        return undefined;
    }
    return value === 'ASSIGNED';
};

const getFilterValue = (value: string) => {
    if (value === 'ALL') {
        return undefined;
    }
    return value;
};

export const loadPickups = () => async (dispatch: Dispatch<any>, store: () => ReduxStore) => {
    dispatch(startLoading(bucket));

    const { generic } = store();
    const { PICKUPS } = generic;
    const { appliedFilters, pagination } = PICKUPS;
    const searchFilters = {};

    if (appliedFilters.searchValue) {
        searchFilters[appliedFilters.selectedOption] = appliedFilters.searchValue;
    }
    const response = await getPickups({
        resultPerPage: pagination.resultPerPage,
        pageNumber: pagination.currentPageNumber,
        fromDate: moment(appliedFilters.fromDate).format(dateFormat),
        toDate: moment(appliedFilters.toDate).format(dateFormat),
        sortBy: sortByMap[appliedFilters.sortBy || 'pickup_date'],
        courierType: getFilterValue(appliedFilters.courierType),
        isRiderAssigned: getRiderAssigned(appliedFilters.isRiderAssigned),
        status: getFilterValue(appliedFilters.status),
        descendingOrder: appliedFilters.descendingOrder,
        pickupId: appliedFilters.pickupId,
        ...searchFilters,
    });
    if (response.isSuccess) {
        dispatch(setGenericData(bucket, response?.data?.data || []));
        dispatch(setPagination(bucket, {
            isNextPresent: response.data?.isNextPresent,
        }));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
