/* eslint-disable max-len */

import { Routes } from 'library/constants';

// to logic to filter out steps of tour
// isVisible is true, it will always visible
// visibleForRoutes is array of routes where this step is visible
// else it won't be visible
export const steps = [
    {
        selector: '.quick-action',
        content: 'Check if pickup/delivery services are available in your area. Enter a pincode to see available shipping options and estimated delivery times.',
        visibleForRoutes: [Routes.PINCODE_SERVICEABILITY],
    },
    {
        selector: '.quick-action',
        content: 'Get instant shipping quotes. Enter package details and destination to view pricing for different service levels.',
        visibleForRoutes: [Routes.RATE_CALCULATOR],
    },
    {
        selector: '.navigation-menu',
        content: 'Access all app features from this central hub. Easily switch between different sections of the application.',
        isVisible: true,
    },
    {
        selector: '.new-booking',
        content: 'Book a new shipment request. Enter package and recipient details, choose service type, and schedule pickup.',
        isVisible: true,
    },
    {
        selector: '.logout-button',
        content: 'Securely end your session. Always logout when using shared devices to protect your account.',
        isVisible: true,
    },
    {
        selector: '.profile-settings',
        content: 'Manage your account information. Update personal details, change password, and get API keys and documentation for integration.',
        isVisible: true,
    },
    {
        selector: '.shipment-details',
        content: 'View all your shipments at a glance. Check status, sort by date, and access detailed information for each consignment.',
        isVisible: true,
    },
    {
        selector: '.consignment-tracking',
        content: 'Monitor your shipment\'s journey. Enter tracking number to see real-time location and estimated delivery date.',
        isVisible: true,
    },
    {
        selector: '.wallet-details',
        content: 'Review your transaction history. See past payments, refunds, and current balance.',
        visibleForRoutes: [Routes.WALLET_LEDGER],
    },
    {
        selector: '.wallet-balance',
        content: 'Check your available funds. Top up your wallet for seamless future transactions.',
        visibleForRoutes: [Routes.WALLET_LEDGER],
    },
];
