import React from 'react';
import {
    Row,
    Col,
    Typography,
} from 'antd';
import { EnvironmentFilled } from '@ant-design/icons';
import ContentFilledIcon from 'assets/contents-filled';

const { Text } = Typography;

interface PincodeCardProps {
    data: Record<string, any>,
    srcHeader: string,
    dstHeader: string,
}

const PincodeCard = (props: PincodeCardProps) => {
    const {
        data,
        srcHeader,
        dstHeader,
    } = props;
    return (
        <Row style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            color: '#232529',
        }}
        >
            { data?.src?.valid
                    && (
                        <Col span={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{
                                    backgroundColor: '#F0ECFD',
                                    width: 20,
                                    height: 20,
                                    marginRight: 5,
                                    borderRadius: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                >
                                    <ContentFilledIcon style={{
                                        color: '#6A3DF5',
                                        backgroundColor: 'transparent',
                                    }}
                                    />
                                </span>
                                <div
                                    style={{
                                        fontSize: 12,
                                        color: '#777777',
                                    }}
                                >
                                    {srcHeader}
                                </div>
                            </div>
                            <Text strong style={{ display: 'block' }}>
                                <span style={{ fontSize: 14, fontWeight: 600, marginRight: 10 }}>
                                    {data?.src?.pincode}
                                </span>
                                <span style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                }}
                                >
                                    {data?.src?.state}
                                    ,&nbsp;
                                    {data?.src?.city}
                                </span>
                            </Text>
                        </Col>
                    )}
            {data?.dst?.valid && (
                <Col span={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            backgroundColor: '#EDF8F1',
                            width: 20,
                            height: 20,
                            marginRight: 5,
                            borderRadius: 4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <EnvironmentFilled style={{
                                color: '#5CAF7E',
                            }}
                            />
                        </span>
                        <div
                            style={{
                                fontSize: 12,
                                color: '#777777',
                            }}
                        >
                            {dstHeader}
                        </div>
                    </div>
                    <Text strong style={{ display: 'block' }}>
                        <span style={{ fontSize: 14, fontWeight: 600, marginRight: 10 }}>
                            {data?.dst?.pincode}
                        </span>
                        <span style={{
                            fontSize: 14,
                            fontWeight: 400,
                        }}
                        >
                            {data?.dst?.state}
                            ,&nbsp;
                            {data?.dst?.city}
                        </span>
                    </Text>
                </Col>
            )}
        </Row>
    );
};

export default PincodeCard;
