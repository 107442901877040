import * as React from 'react';
import {
    DatePicker,
    Input,
    Select,
    Switch,
} from 'antd';
import { FilterMetadata } from '../../types/master-data-types';
import { Customer, FilterType } from '../../library/Types';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import withStyles from 'react-jss';
import { StylesProps } from '../../theme/jss-types';
import { fetchDestinationLocationData } from '../../network/asn.api';
import { fetchPickupAddress } from '../../network/pickup.api';
import moment from 'moment';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { displayConfig } from 'library/globals';

const { Option } = Select;
const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 100,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 10,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-picker': {
            width: 130,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 11,
        },
    },
    label: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    subLabel: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 8,
        letterSpacing: 0,
        marginRight: 8,
    },
};

interface FilterProps extends StylesProps<typeof styles> {
    filter: FilterMetadata;
    labelClass?: string;
    value?: any;
    key: string;
    onChange: (value: any) => void;
    moreFilter?: boolean;
    handleSelect? : any;
    handleDateSelect? : any;
    childList?: Customer[];
    loading?: boolean;
    newMoreFilter?: boolean;
}

const Filters = (props: FilterProps) => {
    const {
        filter,
        classes,
        onChange,
        labelClass,
        key,
        handleSelect,
        handleDateSelect,
        moreFilter,
        value,
        childList,
        loading,
        newMoreFilter,
    } = props;
    const [optionsList, setOptionsList] = React.useState<any[]>([]);
    const searchTimeOut = React.useRef(undefined as any);

    const { t } = useTranslation();

    const renderCheckbox = () => {
        return (
            <Checkbox
                onChange={onChange}
            />
        );
    };

    const commonStyle = {
        borderRadius: moreFilter ? 4 : 12,
        width: moreFilter ? 250 : 100,
        height: moreFilter ? 32 : 24,
    };

    const getPlaceholder = () => {
        const storage = window.localStorage;
        const organisationID = storage.getItem('organisationId');
        if (
            displayConfig
            && organisationID
            && displayConfig[organisationID]
            && displayConfig[organisationID][filter.id]
        ) {
            return displayConfig[organisationID][filter.id];
        }
        return t(filter.id);
    };
    const renderInput = () => {
        return (
            <Input
                value={value}
                placeholder={filter.placeholder === 'CN Reference Number'
                    ? t('consignment_number') : getPlaceholder()}
                onChange={(e) => onChange(e.target.value)}
                style={commonStyle}
            />
        );
    };

    const renderDatepicker = () => {
        return (
            <DatePicker
                value={value}
                onChange={onChange}
                allowClear={false}
                style={commonStyle}
            />
        );
    };

    const renderSearch = () => {
        if (filter.id === 'customerIdList') {
            return (
                <Select
                    value={value}
                    placeholder={t(`${filter.id}_placeholder`)}
                    onChange={(code) => onChange(code)}
                    style={commonStyle}
                    showSearch
                    options={childList?.map((child: Customer) => {
                        return {
                            label: `${child.customer_code} (${child.customer_name})`,
                            value: child.customer_id,
                        };
                    }) || []}
                    allowClear
                    notFoundContent={loading ? <Loader zIndex={5} /> : null}
                    optionFilterProp="label"
                />
            );
        }
        return (
            <Select
                value={value}
                placeholder={t(`${filter.id}_placeholder`)}
                onChange={(code) => onChange(code)}
                style={commonStyle}
                options={[]}
            />
        );
    };

    const renderSelect = () => {
        let options = [];
        if (Array.isArray(filter.options) && filter.options.length > 0) {
            options = filter.options.map((item: any) => {
                const toRet = {
                    ...item,
                };
                /* eslint-disable no-param-reassign */
                if (filter.id === 'isInternational' || filter.id === 'isCod') {
                    toRet.label = t(toRet.label);
                } else {
                    toRet.label = t(toRet.value);
                }
                return toRet;
            });
        }
        return (
            <Select
                value={value}
                // mode={filter.mode}
                allowClear
                onChange={onChange}
                options={options}
                placeholder={t(`${filter.id}_placeholder`)}
                dropdownMatchSelectWidth={false}
                style={commonStyle}
            />
        );
    };

    const commitSearch = async (query:any) => {
        let response = null;
        if (filter.id === 'destinationLocationCodeList') {
            response = await fetchDestinationLocationData({ queryStr: query });
            if (response.isSuccess) {
                setOptionsList(response?.data);
            }
        } else {
            response = await fetchPickupAddress({ searchString: query });
            if (response.isSuccess) {
                let filteredList = [];
                filteredList = response && response.data.filter((i: any) => i.locationId).map((item: any) => {
                    return ({
                        id: item.locationId,
                        code: item.locationId,
                    });
                });
                setOptionsList(filteredList);
            } else {
                setOptionsList([]);
            }
        }
    };

    const handleSearch = (query:any) => {
        if (searchTimeOut.current) {
            clearTimeout(searchTimeOut.current);
            searchTimeOut.current = undefined;
        }
        searchTimeOut.current = setTimeout(() => {
            commitSearch(query);
        }, 300);
    };


    const renderSelectSearch = () => {
        return (
            <Select
                showSearch
                allowClear
                placeholder={filter.placeholder}
                defaultActiveFirstOption={false}
                showArrow={false}
                value={filter.id === 'destinationLocationCodeList' ? value.selectDestinationLocationCode : value}
                onSearch={(val) => handleSearch(val)}
                onSelect={(val, options) => handleSelect(val, options)}
                notFoundContent={null}
            >
                { optionsList && optionsList.map((item:any) => {
                    return <Option key={item.id} value={item.code}>{item.code}</Option>;
                })}
            </Select>

        );
    };


    const renderRangePicker = () => {
        return (
            <DatePicker.RangePicker
                value={[
                    moment(value.startDate),
                    moment(value.endDate),
                ]}
                format="DD/MM/YYYY"
                onChange={handleDateSelect}
            />
        );
    };

    const renderSwitch = () => {
        return (
            <Switch
                checked={value}
                onChange={onChange}
            />
        );
    };

    const renderFilterInput = () => {
        switch (filter.type) {
            case FilterType.checkbox: return renderCheckbox();
            case FilterType.input: return renderInput();
            case FilterType.date: return renderDatepicker();
            case FilterType.search: return renderSearch();
            case FilterType.select: return renderSelect();
            case FilterType.selectSearch: return renderSelectSearch();
            case FilterType.rangeDate: return renderRangePicker();
            case FilterType.switch: return renderSwitch();
            default: return null;
        }
    };

    return (
        <div
            className={classes.main}
            key={key}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!newMoreFilter
                    ? (
                        <>
                            <span className={labelClass || classes.label}>
                                {filter.label === 'CN Reference Number' ? t('consignment_number') : getPlaceholder()}
                            </span>
                            <span className={classes.subLabel}>
                                {filter.subLabel}
                            </span>
                        </>
                    )
                    : null}
            </div>
            {renderFilterInput()}
        </div>
    );
};

const Filter = withStyles(styles)(Filters);
export default Filter;
