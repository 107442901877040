import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import GenericHoc from '../common/generic-hoc';
import {
    Button,
    Table,
    Tabs,
    Select,
    message,
    DatePicker,
    Tooltip,
} from 'antd';
import { NAVBAR_HEIGHT } from 'library/globals';
import {
    ConsignmentBucketKeys,
    PluginBucketKeys,
    ConsignmentColumn,
    Master,
} from 'types/master-data-types';
import {
    PluginOrdersBuckets,
    consignmentTypesKeys,
    dateColumnKeys,
    pluginErrorColumns,
    defaultPluginColumns,
} from './consignments.constants';
import { useTranslation } from 'react-i18next';
import Paginator from '../common/paginator';
import moment from 'moment';
import { Routes } from 'library/constants';
import { RouteChildrenProps } from 'react-router';
import {
    EditOutlined, ReloadOutlined,
} from '@ant-design/icons';
import Helper from 'library/Helper';
import { commonStyleSheet } from 'library/common-styles';
import { ReduxStore } from 'reducers/redux.types';
import { bindActionCreators } from 'redux';
import { setFilters, applyFilters, setPagination } from 'actions/generic-action';
import EllipsisIcon from 'assets/EllipsisIcon';
import {
    Buckets,
    NextOrPrev,
    Pagination,
} from 'library/Types';
import {
    createBulk,
    downloadPluginOrders,
} from 'network/consignments.api';
import { getDownloadsRoute } from 'routing/routing-helper';
import SingleConsignment from 'components/create-consignment/single-consignment';
import { loadPluginOrders } from 'actions/plugin-orders-actions';
import BulkConsignments from 'components/create-consignment/bulk-consignments';
import FilterBlue from '../../assets/filter-blue';
import PluginFilters from './plugin-filters';

const styles = (theme: ThemeType) => ({
    main: {
        '& .ant-table-tbody > tr > td': {
            padding: '14px 0px',
        },
        '& .ant-picker': {
            width: 130,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    tableRowColor: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
        '&& tbody > tr:hover > td': {
            background: '#E7F5FF',
        },
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    ellipsisIcon: {
        fontSize: 20,
    },
    popupdescription: {
        color: '#666',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
        marginBottom: '15px',
        marginRight: '180px',
    },
    popupdate: {
        color: '#666',
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: '10px',
    },
    popheader: {
        color: '#111',
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '16px',
        marginBottom: '8px',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 180,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    pageFilter: {
        display: 'flex',
        marginBottom: 8,
        borderBottom: '1px solid #D9D9D9',
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        flexDirection: 'row',
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    notesCellValue: {
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'center',
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        '&:hover': {
            color: theme.primaryColor,
            textDecoration: 'underline',
        },
    },
    modalContainer: {
        padding: '1rem 0',
        paddingBottom: '3rem',
        minWidth: '40%',
    },
    count: {
        height: 18,
        padding: 10,
        borderRadius: 9,
        backgroundColor: '#E9E9E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        color: '#333',
        fontWeight: 600,
    },
    carouselContainer: {
        padding: '1.5rem',
        maxWidth: '100%',
        '& span': {
            color: '#222021 !important',
        },
        '& span > svg': {
            width: '120%',
            height: '120%',
        },
    },
    tabsContainer: {
        '& .ant-tabs-bar': {
            margin: '0px',
        },
        '& .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab': {
            padding: 4,
        },
        '& .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav,': {
            margin: 0,
        },
        '& .ant-tabs-bottom > div > .ant-tabs-nav': {
            margin: 0,
        },
        '& .ant-tabs-tab': {
            color: '#666666',
            minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#F5F5F5',
            cursor: 'pointer',
        },
        '& .ant-tabs-dropdown-menu-item': {
            color: '#666666',
            minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            backgroundColor: '#F5F5F5',
            cursor: 'pointer',
        },
        '& .ant-tabs-tab.ant-tabs-tab-active': {
            color: '#111111',
            fontWeight: 'bold',
            backgroundColor: '#FFF',
        },
        '& .ant-tabs-tab-btn': {
            display: 'flex',
            width: '100%',
            padding: '0px 6px',
            justifyContent: 'space-between',
        },
        '& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab': {
            marginRight: 0,
        },
        '& .ant-tabs-nav-more span': {
            transform: 'rotate(90deg)',
        },
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    moreFilter: {
        fontFamily: 'Open Sans',
        marginLeft: 1,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
        fontFamily: 'Open Sans',
    },
    countSelected: {
        backgroundColor: theme.primaryColor,
        color: '#FFF',
        fontWeight: 'bold',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor} `,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
    },
    actionButton: {
        marginRight: 8,
    },
    popupheader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '24px',
    },
    popupbooking: {
        borderRadius: 4,
        border: '1px solid #F7F7F7',
        boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.10)',
        padding: '0px !important',
        position: 'absolute',
        right: 40,
        top: 40,
        '& .ant-modal-body': {
            height: '177px !important',
            padding: '18px 20px 10px 20px',

        },
    },
    popupbulkbooking: {
        borderRadius: 4,
        border: '1px solid #F7F7F7',
        boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.10)',
        padding: '0px !important',
        position: 'absolute',
        right: 40,
        top: 40,
        '& .ant-modal-body': {
            height: '201px !important',
            padding: '24px 0px 0px 0px',

        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 34,
    },
    bookingbutton: {
        fontSize: '12px !important',
        fontWeight: 600,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        height: '40px',
        width: '100%',
        backgroundColor: '#FFF3EE',
        display: 'flex',
    },
});
interface PluginOrdersProps extends StylesProps<ReturnType<typeof styles>>, RouteChildrenProps {
    loading: boolean;
    master: Master;
    filters: Record<string, any>;
    consignments: any;
    setfilters: (bucketId: Buckets.PLUGIN, filters: any) => void;
    applyfilters: (bucketId: Buckets.PLUGIN) => void;
    pagination: Pagination;
    summary: any;
    loadOrders: () => void;
    setpagination: (bucketId: Buckets.PLUGIN, newPagination: Partial<Pagination>) => void;
    uiTheme: ThemeType;
    defaultFilters: Record<string, any>;
}


const PluginOrders = (props: PluginOrdersProps) => {
    const {
        classes,
        loading,
        master,
        filters,
        history,
        consignments,
        pagination,
        setpagination,
        setfilters,
        applyfilters,
        summary,
        loadOrders,
        uiTheme,
        defaultFilters,
    } = props;
    const { t } = useTranslation();

    const { bucketSelected } = filters;
    const { useEffect, useState } = React;

    const [filterHeight, setFilterHeight] = React.useState(0);
    const [consignmentType, setConsignmentType] = React.useState(filters?.ConsignmentType
        ? filters?.ConsignmentType : consignmentTypesKeys[0]);
    const [consignmentsSelectedData, setConsignmentSelectedData] = useState<any[]>([]);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const showBucketCount = master?.config?.customer_portal_config?.fetch_customer_portal_bucket_count;
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [moreFilterVisible, setMoreFiltersVisible] = useState<boolean>(false);

    const bannerHeight = master?.config?.is_using_new_customer_portal ? 0 : 50;
    const filtersRef = React.useRef<HTMLDivElement>(null);
    const [editFormVisible, setFormVisible] = React.useState<boolean>(false);
    const [bulkEditFormVisible, setBulkEditFormVisible] = React.useState<boolean>(false);
    const [editData, setEditData] = React.useState<any>(null);
    const maximumOrderSelected = 200;


    React.useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    useEffect(() => {
        if (filtersRef?.current?.clientHeight !== filterHeight) {
            setFilterHeight(filtersRef?.current?.clientHeight || 0);
        }
    }, [dimensions]);

    useEffect(() => {
        loadOrders();
    }, [filters, toggle]);

    const getWidth = (id: string) => {
        switch (id) {
            case 'actions': return 90;
            case 'status':
            case 'reference_number':
            case 'destination_name':
            case 'service_type':
            case 'customer_reference_number': return 150;
            case 'movement_type':
            default: return 120;
        }
    };

    const renderText = (text: string) => {
        return (
            <div
                className={classes.cellValue}
                onClick={() => {
                    history.push(`/${Routes.PLUGIN_ORDERS}`);
                }}
            >
                {text}
            </div>
        );
    };

    const renderEditIcon = (row: any) => {
        return (
            <EditOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    setEditData(row);
                    setFormVisible(true);
                }}
                style={{ marginRight: 8 }}
            />
        );
    };

    const mappingForFormItemTOSteps: any = {
        service_type: 4,
    };

    const renderservices = (row: any, columnId: any) => {
        return (
            <div
                className={classes.notesCellValue}
                onClick={() => {
                    const updatedRow = {
                        ...row,
                        softdata_body: {
                            ...row.softdata_body,
                            stepToOpen: mappingForFormItemTOSteps[columnId],
                        },
                    };
                    setEditData(updatedRow);
                    setFormVisible(true);
                }}
            >
                {row?.softdata_body?.service_type}
                <EditOutlined style={{ marginLeft: 8 }} />
            </div>
        );
    };

    const renderColumn = (text: any, row: any, column: ConsignmentColumn) => {
        if (dateColumnKeys.includes(column.column_id) && text) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString);
        }
        if (column.column_id === 'service_type') {
            if (row?.is_international) return renderText(row?.softdata_body?.service_type);
            return renderservices(row, column.column_id);
        }
        if (column.column_id === 'creation_date') {
            const dateString = moment(row?.created_at).format('DD MMM, YYYY');
            return renderText(dateString);
        }
        if (column.column_id === 'last_updated_at') {
            const dateString = moment(row?.updated_at).format('DD MMM, YYYY');
            return renderText(dateString);
        }
        if (column.column_id === 'booking_attempts') {
            let bookingErrors = [];
            try {
                bookingErrors = JSON.parse(row?.booking_error || '[]');
            } catch (e) {
                bookingErrors = row?.booking_error || [];
            }
            const attemptFrequency = bookingErrors?.length;
            return renderText(attemptFrequency);
        }
        if (column.column_id === 'booking_error') {
            let bookingErrors = [];
            try {
                bookingErrors = JSON.parse(row?.booking_error || '[]');
            } catch (e) {
                bookingErrors = row?.booking_error || [];
            }
            const lastErrorMessage = bookingErrors?.[bookingErrors.length - 1]?.message;
            return renderText(lastErrorMessage);
        }
        if (column.column_id === 'actions') {
            return (
                <>
                    {renderEditIcon(row)}
                </>
            );
        }
        if (column.column_id === 'customer_reference_number_2') {
            return renderText(row?.customer_reference_number);
        }
        if (column.column_id === 'consignee_company_name') {
            return renderText(row?.softdata_body?.consignee_company_name);
        }
        if (column.column_id === 'consignment_type') {
            return renderText(row?.softdata_body?.is_international ? 'International' : 'Domestic');
        }
        if (column.column_id === 'payment_mode') {
            return renderText(row?.softdata_body?.is_cod ? 'COD' : 'PREPAID');
        }
        if (column.column_id === 'source') {
            return renderText(row?.source === 'EXTERNAL CUSTOMER SHOPIFY INTEGRATION API' ? 'Shopify' : '');
        }
        if (column.column_id === 'app_name') {
            return renderText(row?.softdata_body?.app_name);
        }

        if (!text || text === null) {
            return <div className={classes.cellNa}>-NA-</div>;
        }
        // please don't change this default object check
        if (typeof text === 'object') {
            return <div className={classes.cellValue}>{JSON.stringify(text)}</div>;
        }
        return renderText(text);
    };

    const handleBucketChange = (bucket: PluginBucketKeys) => {
        if (bucket === filters.bucketSelected) {
            return;
        }
        const newFilters = {
            ...filters,
            bucketSelected: bucket,
        };
        setfilters(Buckets.PLUGIN, newFilters);
        applyfilters(Buckets.PLUGIN);
    };

    const renderConsignmentType = (() => {
        return (
            <Select
                onChange={(value) => {
                    const newFilters = {
                        ...filters,
                    };
                    newFilters.ConsignmentType = value;
                    setfilters(Buckets.PLUGIN, newFilters);
                    applyfilters(Buckets.PLUGIN);
                    setConsignmentType(value);
                }}
                value={consignmentType}
                dropdownMatchSelectWidth={false}
            >
                {consignmentTypesKeys.map((item) => {
                    return (
                        <Select.Option
                            key={item}
                            value={item}
                        >
                            {t(item)}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    });

    const disabledDate = (current: any) => {
        return current && current > moment().endOf('day');
    };

    const renderRangePicker = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Date
                </span>
                <DatePicker.RangePicker
                    value={[moment(filters.startDate), moment(filters.endDate)]}
                    allowClear={false}
                    allowEmpty={[false, false]}
                    disabledDate={disabledDate}
                    placeholder={['Select Start Date', 'Select End Date']}
                    onChange={(value) => {
                        if (!(value && value[0] && value[1])) return;
                        const newFilters = {
                            ...filters,
                        };
                        if (value[1].isSame(filters.endDate)) {
                            if (moment(filters.endDate).diff(value[0], 'days') > 30) {
                                const endDate = moment(value[0]).add(30, 'd');
                                newFilters.endDate = endDate.unix() * 1000;
                                newFilters.startDate = moment(value[0]).unix() * 1000;
                            } else {
                                newFilters.endDate = moment(value[1]).unix() * 1000;
                                newFilters.startDate = moment(value[0]).unix() * 1000;
                            }
                        } else if (value[0].isSame(filters.startDate)) {
                            if (moment(value[1]).diff(value[0], 'days') > 30) {
                                const startDate = moment(value[1]).subtract(30, 'd');
                                newFilters.startDate = startDate.unix() * 1000;
                                newFilters.endDate = moment(value[1]).unix() * 1000;
                            } else {
                                newFilters.endDate = moment(value[1]).unix() * 1000;
                                newFilters.startDate = moment(value[0]).unix() * 1000;
                            }
                        }
                        setfilters(Buckets.PLUGIN, newFilters);
                        applyfilters(Buckets.PLUGIN);
                    }}
                />
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.PLUGIN, newPagination);
        setToggle(!toggle);
    };


    const renderRefresh = () => {
        return (
            <Tooltip title={t('reload')}>
                <Button
                    size="small"
                    type="ghost"
                    loading={loading}
                    style={{
                        color: uiTheme.primaryColor,
                        fontSize: 14,
                        margin: 10,
                    }}
                    onClick={() => {
                        handlePagination('first');
                        loadPluginOrders();
                    }}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };

    const isMoreFilterAdded = () => {
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (['endDate', 'startDate'].includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const renderMoreFilters = () => {
        if (!moreFilterVisible) {
            return null;
        }
        return (
            <PluginFilters
                resetVisible={isMoreFilterAdded()}
                onClose={() => setMoreFiltersVisible(false)}
            />
        );
    };

    const renderMoreFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        return (
            <div
                className={classes.moreFilter}
                onClick={() => setMoreFiltersVisible(true)}
                style={{
                    color: filterApplied ? uiTheme.primaryColor : '#111111',
                    fontWeight: filterApplied ? 600 : 500,
                }}
            >
                <FilterBlue style={{ marginRight: 2 }} />
                <span>{t('more_filters')}</span>
            </div>
        );
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setConsignmentType(consignmentTypesKeys[0]);
                    setfilters(Buckets.PLUGIN, {
                        ...defaultFilters,
                        fromDate: filters.fromDate,
                        toDate: filters.toDate,
                    });
                    applyfilters(Buckets.PLUGIN);
                }}
            >
                {t('reset_filters')}
            </div>
        );
    };

    const renderFilter = () => {
        return (
            <div className={classes.row}>
                {renderMoreFiltersOption()}
                {renderResetFiltersOption()}
            </div>
        );
    };


    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {/* {renderDateType()} */}
                {renderConsignmentType()}
                {renderRangePicker()}
                {renderFilter()}
            </div>
        );
    };

    const handleDownload = async () => {
        const dateFormat = 'YYYY-MM-DD';
        setDownloading(true);
        const response = await downloadPluginOrders({
            customerIdList: [],
            dumpType: 'PLUGIN_ORDER_DOWNLOAD',
            orderCategory: filters.ConsignmentType === 'International' ? 'INTERNATIONAL' : 'DOMESTIC',
            startDate: moment(filters.startDate).format(dateFormat),
            endDate: moment(filters.endDate).format(dateFormat),
            customerReferenceNumber: filters.customerReferenceNumber,
            source: filters.source,
            serviceType: filters.serviceType,
            storeName: filters.storeName,
            salesOrderNumberList: consignmentsSelectedData.map(
                (consignment) => consignment.sales_order_number,
            ),
        });
        if (response.isSuccess) {
            message.success('Requested Successfully');
            history.push(getDownloadsRoute());
        } else {
            message.error(response.errorMessage);
        }
        setDownloading(false);
    };

    const renderBulkEdit = () => {
        if (!bulkEditFormVisible) {
            return null;
        }
        return (
            <BulkConsignments
                config={{
                    name: t('edit_orders'),
                    uploadName: t('plugin_orders'),
                }}
                onClose={() => setBulkEditFormVisible(false)}
                allowInternational
                showServiceTypeOption
                setSuccess={() => {
                    message.success('Consignment added successfully');
                    setBulkEditFormVisible(false);
                }}
                loadconsignments={loadOrders}
                source="plugin"
            />
        );
    };

    const MappingForBulkSoftdata = (fullData: any) => {
        const consignment = fullData?.softdata_body;
        if (!consignment) return {};
        return {
            referenceNumber: consignment.reference_number,
            shopifyFulfillmentId: consignment.shopify_fulfillment_id,
            shopifyOrderId: consignment.shopify_order_id,
            customerReferenceNumber: fullData.customer_reference_number,
            salesOrderNumber: consignment.sales_order_number,
            // akNumber: consignment.ak_number,
            // status: consignment.status,
            isCOD: consignment.is_cod,
            codAmount: consignment.cod_amount,
            codCollectionMode: consignment.cod_collection_mode,
            codFavorOf: consignment.cod_favor_of,
            consignmentCategory: consignment?.is_international ? 'international' : 'domestic',
            isInternational: consignment.is_international,
            declaredCurrency: consignment.declared_currency,
            serviceType: consignment.service_type,
            declaredPrice: consignment.declared_value,
            dstAddressname: consignment.destination_name,
            dstAddressaddressLine1: consignment.destination_address_line_1,
            dstAddressaddressLine2: consignment.destination_address_line_2,
            dstAddressaddressLine3: consignment.destination_address_line_3,
            dstAddressaddressLine4: consignment.destination_address_line_4,
            dstAddresscityName: consignment.destination_city,
            dstAddressstateName: consignment.destination_state,
            dstAddresscountryName: consignment.destination_country,
            dstAddressphone: consignment.destination_phone,
            dstAddressalternatePhone: consignment.destination_phone,
            dstAddresspincode: consignment.destination_pincode,
            dstAddressemail: consignment.destination_email,
            srcAddressname: consignment.sender_name,
            srcAddressaddressLine1: consignment.sender_address_line_1,
            srcAddressaddressLine2: consignment.sender_address_line_2,
            srcAddressaddressLine3: consignment.sender_address_line_3,
            srcAddressaddressLine4: consignment.sender_address_line_4,
            srcAddresscityName: consignment.sender_city,
            srcAddressstateName: consignment.sender_state,
            srcAddresscountryName: consignment.sender_country,
            srcAddressphone: consignment.sender_phone,
            srcAddressalternatePhone: consignment.sender_phone,
            srcAddresspincode: consignment.sender_pincode,
            srcAddressemail: consignment.sender_email,
            dimensionsheight: consignment.height,
            dimensionslength: consignment.length,
            dimensionswidth: consignment.width,
            description: consignment?.description,
            numberOfPieces: Number(consignment?.num_pieces || consignment?.no_of_pieces),
            courierType: consignment?.courier_type,
            weight: consignment?.weight,
            isRiskSurchargeApplicable: consignment?.is_risk_surcharge_applicable,
            consignmentType: consignment?.consignment_type || 'forward',
            // commodity ID, how it will work in case cascader
            commodityId: consignment.commodity_name,
            currency: consignment?.declared_currency,
            shipmentPurpose: consignment?.shipment_purpose,
            clientCode: consignment?.child_client?.code,
            incoTerms: consignment.inco_terms,
            typeOfDelivery: consignment?.type_of_delivery,
            movementType: consignment?.movement_type,
            uniqueId: consignment?.unique_id,
            riskSurchargeType: consignment.risk_surcharge_type ? consignment.risk_surcharge_type : undefined,
            alternateDelAddress1addressLine1: consignment.alternate_del_address1?.addressLine1,
            alternateDelAddress1addressLine2: consignment.alternate_del_address1?.addressLine2,
            alternateDelAddress1alternatePhone: consignment.alternate_del_address1?.alternatePhone,
            alternateDelAddress1cityName: consignment.alternate_del_address1?.cityName,
            alternateDelAddress1countryName: consignment.alternate_del_address1?.countryName,
            alternateDelAddress1email: consignment.alternate_del_address1?.email,
            alternateDelAddress1name: consignment.alternate_del_address1?.name,
            alternateDelAddress1phone: consignment.alternate_del_address1?.phone,
            alternateDelAddress1pincode: consignment.alternate_del_address1?.pincode,
            alternateDelAddress1stateName: consignment.alternate_del_address1?.stateName,
        };
    };

    const getSelectedItems = () => {
        const consignmentArray = consignmentsSelectedData.map(
            (consignment: any) => MappingForBulkSoftdata(consignment),
        );

        const piecesArray: any[] = [];
        consignmentsSelectedData.forEach(
            (consignment: any) => {
                consignment.softdata_body.pieces?.forEach((piece: any) => {
                    piecesArray.push({
                        uniqueId: consignment.unique_id,
                        customerReferenceNumber: consignment.customer_reference_number,
                        volumetricWeight: piece.volumetric_weight,
                        dimensionsUnit: 'cm',
                        weightUnit: 'kg',
                        description: piece.description,
                        quantity: piece.quantity || piece.item_quantity || piece.number_of_items,
                        length: piece.length,
                        width: piece.width,
                        height: piece.height,
                        weight: piece.weight,
                        declaredValue: piece.declared_value,
                        unit: piece.item_unit,
                        numberOfItems: piece.quantity || piece.item_quantity || piece.number_of_items,
                    });
                });
            },
        );


        return { consignmentArray, piecesArray };
    };

    const handleBookSoftdata = async () => {
        setIsLoading(true);
        const { consignmentArray, piecesArray } = getSelectedItems();
        const response = await createBulk({
            consignmentArray,
            piecesArray,
            isBookedUsingV2: true,
            isPluginOrder: true,
        });
        if (response.isSuccess) {
            message.success(t('excel_upload_success'));
            loadPluginOrders();
        } else {
            message.error(response.errorMessage);
        }
        setIsLoading(false);
    };

    const renderActionsButton = () => {
        return (
            <>
                <Button
                    type="primary"
                    className={classes.actionButton}
                    onClick={(e) => {
                        Helper.stops(e as any);
                        setBulkEditFormVisible(true);
                    }}
                >
                    Edit
                </Button>
                <Button
                    type="primary"
                    className={classes.actionButton}
                    disabled={downloading}
                    onClick={handleDownload}
                >
                    Download
                </Button>
                {consignmentType === 'Domestic' && (
                    <Button
                        type="primary"
                        className={classes.actionButton}
                        loading={isLoading}
                        disabled={consignmentsSelectedData.length === 0}
                        onClick={() => {
                            handleBookSoftdata();
                        }}
                    >
                        Book Softdata
                    </Button>
                )}
            </>
        );
    };

    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination.currentPageNumber}
                isNextPresent={pagination.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderActionsButton()}
                {renderRefresh()}
                {renderPaginator()}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const getTabName = (bucket: ConsignmentBucketKeys) => {
        let countClass = classes.count;
        if (bucket === bucketSelected) {
            countClass = [classes.count, classes.countSelected].join(' ');
        }

        return (
            <>
                <span style={{ color: bucket === bucketSelected ? '#111' : '#666' }}>
                    {t(bucket)}
                </span>
                {
                    showBucketCount
                        ? (<span className={countClass}>{summary[bucket] || 0}</span>)
                        : null
                }
            </>
        );
    };
    const renderBucket = (bucket: ConsignmentBucketKeys) => {
        return (
            <Tabs.TabPane
                tab={getTabName(bucket)}
                key={bucket}
            />
        );
    };

    const renderBuckets = () => {
        return (
            <div className={classes.tabsContainer}>
                <Tabs
                    type="card"
                    // className={classes.bucketRow}
                    activeKey={bucketSelected}
                    onChange={(bucket) => handleBucketChange(bucket as PluginBucketKeys)}
                    moreIcon={<EllipsisIcon className={classes.ellipsisIcon} />}
                >
                    {Object.keys(PluginOrdersBuckets).map((bucket) => renderBucket(bucket as ConsignmentBucketKeys))}
                </Tabs>
            </div>
        );
    };


    const getColumns = (): any[] => {
        // const { consignment_column_list } = config || {};

        const actionColumn = {
            column_id: 'actions',
            is_sortable: false,
            pretty_name: t('actions'),
        };

        const columnLables = [];
        columnLables.push(...defaultPluginColumns);
        if (bucketSelected === 'customer_portal_attempted_plugin_orders') {
            columnLables.push(...pluginErrorColumns);
        }
        columnLables.push(actionColumn);

        const columns: any = columnLables.map((column: ConsignmentColumn) => {
            return {
                key: column.column_id,
                title: t(column.column_id),
                dataIndex: column.column_id,
                width: getWidth(column.column_id),
                ellipsis: column.column_id !== 'actions',
                fixed: column.column_id === 'actions' ? 'right' : undefined,
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters} ref={filtersRef}>
                {renderBuckets()}
                {renderExtraFilters()}
            </div>
        );
    };

    const renderSingleConsignment = () => {
        if (!editFormVisible) {
            return null;
        }
        return (
            <SingleConsignment
                config={master}
                onClose={() => {
                    setFormVisible(false);
                    loadOrders();
                }}
                setSuccess={() => {
                    message.success('Consignment updated successfully');
                    setFormVisible(false);
                    loadOrders();
                }}
                loadconsignments={loadOrders}
                editData={{
                    ...editData,
                    source: 'plugin',
                }}
                source="plugin"
            />
        );
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading || isLoading}
                className={classes.tableRowColor}
                rowKey={(row) => row.id}
                columns={getColumns()}
                scroll={{
                    y: `calc(
                        (((100vh - ${NAVBAR_HEIGHT}px) - 60px) - ${bannerHeight}px) 
                        - ${filterHeight}px)`,
                }}
                rowSelection={{
                    onSelect: (row, selected: any) => {
                        let newArrayData = [...consignmentsSelectedData];
                        if (selected && !newArrayData.some(
                            (data) => data.id === row.id,
                        )) {
                            if (newArrayData.length === maximumOrderSelected) {
                                message.error(`You can select maximum ${maximumOrderSelected} consignments`);
                            } else {
                                newArrayData.push(row);
                            }
                        } else {
                            newArrayData = newArrayData.filter((item) => (
                                item?.id !== row.id
                            ));
                        }
                        setConsignmentSelectedData(newArrayData);
                    },
                    onSelectAll: (selected) => {
                        let newArrayData = [...consignmentsSelectedData];
                        if (selected) {
                            consignments.forEach((item: any) => {
                                if (newArrayData.length < maximumOrderSelected
                                    && !newArrayData.some(
                                        (data) => data.id === item.id,
                                    )) {
                                    newArrayData.push(item);
                                }
                            });
                        } else {
                            consignments.forEach((item: any) => {
                                newArrayData = newArrayData.filter((row) => (
                                    row?.id !== item?.id
                                ));
                            });
                        }
                        setConsignmentSelectedData(newArrayData);
                    },
                    selectedRowKeys: consignmentsSelectedData.map((item) => item?.id),
                }}
                locale={{
                    emptyText: <div className={classes.cellNa}>—NA—</div>,
                }}
                dataSource={consignments || []}
            />
        );
    };

    // if (creating) {
    //     return <Loader zIndex={10} />;
    // }
    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {renderMoreFilters()}
            {renderSingleConsignment()}
            {renderBulkEdit()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master, generic, uiTheme } = state;
    const { PLUGIN } = generic;
    const {
        data,
        summary,
        pagination,
        appliedFilters,
        defaultFilters,
        loading,
    } = PLUGIN;
    return {
        master,
        consignments: data,
        filters: appliedFilters,
        defaultFilters,
        pagination,
        summary,
        uiTheme,
        loading,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadOrders: loadPluginOrders,
        setfilters: setFilters,
        applyfilters: applyFilters,
        setpagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(PluginOrders);
