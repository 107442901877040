import { ZIndex } from 'library/constants';
import { NAVBAR_HEIGHT } from 'library/globals';
import { ThemeType } from 'theme/jss-types';

export const sideMenuStyles = (theme: ThemeType) => ({
    main: {
        height: '100vh',
        padding: `calc(${NAVBAR_HEIGHT}px + 8px) 8px 8px 8px`,
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#F7F7F7 !important',
        '& .ant-menu, .ant-menu-inline-collapsed': {
            backgroundColor: '#F7F7F7 !important',
            fontSize: 12,
            // height: 32,
            fontWeight: 600,
            border: 'none',
        },
        '& .menu-inline-collapsed > & .ant-menu, .ant-menu-inline-collapsed': {
            width: 40,
        },
        '& .ant-menu-inline-collapsed .ant-menu-item': {
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
            width: 40,
        },
        '& .ant-menu-inline .ant-menu-item': {
            border: 'none',
        },
        '& .ant-menu-inline-collapsed .ant-menu-item-selected .anticon': {
            height: '32px !important',
            width: 40,
        },
        '& .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
            backgroundColor: theme.secondryColor,
            color: '#FFF',
            fontSize: 12,
            height: 32,
            fontWeight: 600,
            letterSpacing: 0,
        },
        '& .ant-menu-inline .ant-menu-item::after': {
            borderRight: 'none',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            borderRadius: 16,
            border: '1px solid #999999',
        },
        '& .ant-menu-item': {
            marginTop: '0px !important',
            marginBottom: '0px !important',
            '@media (max-height: 650px)': {
                marginBottom: '0px !important',
                marginTop: '0px !important',
            },
        },
        '& .ant-menu-submenu-title': {
            height: '32px !important',
        },
        '& .ant-menu-submenu-selected .ant-menu-submenu-title': {
            backgroundColor: theme.secondryColor,
            color: '#FFF',
            letterSpacing: 0,
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 4,
        },
        '& .ant-submenu-item': {
            fontSize: 12,
            borderRadius: 6,
            color: '#111111',
            borderRight: 'none',
            transition: 'none',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            height: 32,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: ZIndex.sideMenu,
    },
    dummyClassForSpace: {
        // to compensate for the space taken by the side menu static position
        width: 60,
    },
    collapseButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '4px !important',
        // color: '#FFFFFF',
        backgroundColor: `${theme.primaryColor} !important`,
        color: '#FFFFFF !important',
        border: '1px solid #F2EFE8 !important',
        boxShadow: '0px 1px 2px rgba(89, 89, 89, 0.24) !important',
        zIndex: 501,
        '&:hover': {
            backgroundColor: '#FFFFFF !important',
            color: 'black !important',
        },
    },
    divider: {
        width: '100%',
        margin: 0,
        marginTop: 10,
    },
    sideMenu: {
        height: '85vh',
        width: '100%',
        '& .ant-menu-item': {
            fontSize: 12,
            borderRadius: 6,
            color: '#111111',
            borderRight: 'none',
            transition: 'none',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            height: 32,
        },
        '& .ant-menu-item .ant-menu-item-icon': {
            transition: 'none',
        },
        '& .ant-menu, .ant-menu-inline-collapsed': {
            margin: 0,
        },
    },
    sideMenuBottom: {
        alignSelf: 'end',
        width: '100%',
        '& .ant-menu-item': {
            width: '90%',
            margin: 8,
            height: 32,
            opacity: '0.7',
            borderRadius: 16,
            borderRight: 'none',
            transition: 'none',
            backgroundColor: '#FFFFFF',
        },
    },
    bottomText: {
        width: 200,
        color: theme.primaryColor,
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        letterSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0 20px 0',
        paddingLeft: '15%',
        cursor: 'pointer',
    },
    buttonIcon: {
        fontSize: 14,
    },
    selectedLanguageIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 500,
        width: '32px !important',
        height: '32px !important',
        borderRadius: '16px !important',
        background: '#FFFFFF',
        border: '1px solid #F2EFE8 !important',
        textTransform: 'capitalize',
        textAlign: 'center',
        marginLeft: 10,
    },
    childDropdown: {
        width: '80%',
        '& .ant-select-selection--single': {
            fontWeight: 600,
            fontSize: 12,
        },
        '& .ant-select': {
            fontWeight: 600,
            fontSize: 12,
        },
    },
    customerSwitch: {
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        justifyContent: 'space-between',
        color: '#111111',
    },
    customerLogo: {
        width: 40,
        height: 28,
        border: '1.17px solid #DBDBDB',
        borderRadius: 1.17,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    customerLogoCollapsed: {
        color: `${theme.primaryColor} !important`,
        fontFamily: 'Open Sans',
        boxSizing: 'border-box',
        width: 40,
        height: 28,
        border: '1px solid #FCFCFC',
        borderRadius: '6px !important',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        fontSize: 12,
        fontWeight: 600,
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 8,
    },
    addNewButton: {
        color: `${theme.primaryColor} !important`,
        fontFamily: 'Open Sans',
        boxSizing: 'border-box',
        height: 36,
        border: '1px solid #FCFCFC',
        borderRadius: '6px !important',
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.24)',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:hover': {
            border: '1px solid #FCFCFC',
        },
        paddingLeft: 12,
        fontSize: 12,
        fontWeight: 600,
        justifyContent: 'flex-start',
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 8,
    },
    supportButton: {
        width: '100%',
        color: '#111111 !important',
        fontFamily: 'Open Sans',
        boxSizing: 'border-box',
        height: 36,
        border: '1px solid #DBDBDB !important',
        borderRadius: '6px !important',
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.24)',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        paddingLeft: 12,
        fontSize: 12,
        fontWeight: 600,
        justifyContent: 'flex-start',
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 8,
    },
    tourButton: {
        width: '100%',
        color: '#111111 !important',
        fontFamily: 'Open Sans',
        boxSizing: 'border-box',
        height: 36,
        border: '1px solid #DBDBDB !important',
        borderRadius: '6px !important',
        backgroundColor: '#FDF9EF !important',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 12,
        fontSize: 12,
        fontWeight: 600,
        justifyContent: 'flex-start',
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 8,
    },
    SubMenu: {
        '& .ant-menu-item': {
            backgroundColor: 'transparent !important',
            color: '#111111 !important',
        },
        '& .ant-menu-inline-collapsed .ant-menu-item-selected .anticon': {
            color: 'red !important',
            height: '36px !important',
        },
        '& .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
            backgroundColor: theme.secondryColor,
            color: `${theme.secondryColor} !important`,
            fontSize: 12,
            letterSpacing: 0,
            padding: '2px 8px',
        },
        '& .ant-menu-submenu .ant-menu-sub': {
            backgroundColor: '#f3eee6',
        },
        '& .ant-menu-title-content': {
            // marginLeft: 30,
        },
    },
});
